import { useEffect, useState } from 'react'
import { useHistory } from "react-router"
import { useToasts } from "react-toast-notifications"
import { Col, Container, FormGroup, Row } from "reactstrap"

// assets
// import contactBg from "../assets/contact.jpg";

// components
// import Hero from "../../components/Hero"
import Loading from "../../components/Loading"
import firebase, { firebaseConfig } from "../../firebase"
import { getParameterByName } from "../../utils/helpers"

const AuthActions = () => {
  const history = useHistory()
  // use toast
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState("")
  const [confPassword, setConfPassword] = useState("")

  const actionCode = getParameterByName('oobCode')

  useEffect(() => {
    console.log(actionCode, window.location.search)
    if (!actionCode) {
      addToast('Your link has expired!, please resend email again', {
        appearance: "error",
      })
      // history.push('/')
    } else if (firebaseConfig.apiKey !== getParameterByName('apiKey')) {
      addToast('Your link has malformed!, please resend email again', {
        appearance: "error",
      })
      // history.push('/')
    } else {
      // Verify the password reset code is valid.
      //   firebase.auth().verifyPasswordResetCode(actionCode).then((email) => {
      //   }).catch((error) => {
      //     console.log(error)
      //     let message = error.message
      //     if (error.code === "auth/expired-action-code") {
      //       message = 'Your link has expired!, please resend email again'
      //     } else if (error.code === "auth/invalid-action-code") {
      //       message = 'Your password allready has been reseted with this link'
      //     }
      //     addToast(message, {
      //       appearance: "error",
      //     })
      //     // history.push("/")
      //   })
    }
    // eslint-disable-next-line
  }, [actionCode])

  const handleSumbit = async (error) => {
    error.preventDefault()

    const isEmpty =
      !newPassword ||
      !confPassword

    if (isEmpty) {
      addToast("Please provide password", {
        appearance: "error",
      })
      return
    }
    if (newPassword !== confPassword) {
      alert("Password and Confirm Password does not match.")
      // addToast("Password and Confirm Password does not match.", {
      //   appearance: "error",
      // })
      return
    }
    setLoading(true)
    await firebase
      .auth()
      .confirmPasswordReset(actionCode, newPassword)
      .then(function (data) {
        addToast("Password updated successfully.", {
          appearance: "success",
        })
        setTimeout(() => {
          history.push('/')
        }, 300)
      }).catch(error => {
        console.log(error)
        addToast(error.message, {
          appearance: "error",
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      {/* <Hero
        title='Reset Password'
        breadName='Reset Password'
        // image={contactBg}
        singleProduct={false}
      /> */}
      <section className='ftco-section contact-section bg-light'>
        {loading && <Loading fixed={true} />}
        <Container>
          <Row className='d-flex mb-1 contact-info justify-content-center'>
            <div className="text-center mb-3">
              <h2>Reset Password</h2>
              <div className="text-muted">Set your New Password</div>
              {/* {email &&
                <small>You are resetting newPassword for <b>{email}</b></small>
              } */}
            </div>
          </Row>
          <Row className="block-9 justify-content-center">
            <Col lg="6">
              <form action='#' className='bg-white p-5'>
                <FormGroup>
                  <input
                    type='password'
                    className='form-control'
                    placeholder='New Password'
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <input
                    type='password'
                    className='form-control'
                    placeholder='Confirm Password'
                    onChange={(e) => setConfPassword(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <input
                    type='submit'
                    value='Reset'
                    className='btn btn-primary py-3 px-5 btn-block'
                    onClick={handleSumbit}
                  />
                </FormGroup>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default AuthActions
