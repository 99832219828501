const mealCategories = [
  { value: "breakfast", label: "Breakfast", main: true },
  { value: "lunch", label: "Lunch", main: true },
  { value: "dinner", label: "Dinner", main: true },
  { value: "side-dish", label: "Side Dish", main: false },
  { value: "appetizer", label: "Appetizer", main: false },
  { value: "dessert", label: "Dessert", main: false },
  // { value: "brunch", label: "Brunch", main: false },
  // { value: "lunch-dinner", label: "Lunch/Dinner", main: false },
]

const categories = [
  { value: "vegetarian", label: "Vegetarian" },
  { value: "vegan", label: "Vegan" },
  { value: "keto", label: "Keto" },
  { value: "pescatarian", label: "Pescatarian" },
  { value: "mediterranean", label: "Mediterranean" },
  { value: "kids", label: "Kids" },
  { value: "healthy", label: "Healthy" }
]

const days = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" }
]

const weeks = [
  { value: "weekOne", label: "Week One" },
  { value: "weekTwo", label: "Week Two" },
]

const quantitySuffix = [
  { value: "", label: "" },
  { value: "Bar", label: "Bar" },
  { value: "Bite", label: "Bite" },
  { value: "Block", label: "Block" },
  { value: "Bowl", label: "Bowl" },
  { value: "Bucket", label: "Bucket" },
  { value: "Cube", label: "Cube" },
  { value: "Cup", label: "Cup" },
  { value: "dash", label: "dash" },
  { value: "Dozen", label: "Dozen" },
  { value: "Drop", label: "Drop" },
  { value: "gallon", label: "gallon" },
  { value: "gram", label: "gram" },
  { value: "kg", label: "kg" },
  { value: "ltr", label: "ltr" },
  { value: "Lump", label: "Lump" },
  { value: "mg", label: "mg" },
  { value: "ounce", label: "ounce" },
  { value: "Pack", label: "Pack" },
  { value: "Piece", label: "Piece" },
  { value: "pinch", label: "pinch" },
  { value: "pint", label: "pint" },
  // { value: "pints", label: "pints" },
  { value: "pound", label: "pound" },
  { value: "quart", label: "quart" },
  // { value: "quarts", label: "quarts" },
  { value: "Scoop", label: "Scoop" },
  { value: "Stick", label: "Stick" },
  { value: "Tablespoon", label: "Tablespoon" },
  { value: "Teaspoon", label: "Teaspoon" }
]
//   .sort((a, b) => {
//   if (String(a.value).toLowerCase() > String(b.value).toLowerCase()) {
//     return 1
//   }
//   if (String(a.value).toLowerCase() < String(b.value).toLowerCase()) {
//     return -1
//   }
//   return 0
// })
// localStorage.setItem('suffix', JSON.stringify(quantitySuffix))
// console.log(quantitySuffix)

export {
  categories,
  mealCategories,
  quantitySuffix,
  days,
  weeks
}
