// libraries
import { useEffect } from "react"
import { Link } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem } from "reactstrap"
// import hero from "../assets/hero.jpg";
import { banners } from "../firebase/storage"
import { setPageView } from "../seo"

const Hero = ({ image, breadName, title, singleProduct, isSamll = true }) => {

  const bg = image ? image : banners.hero

  useEffect(() => {
    setPageView(title, bg)
  }, [title, bg])


  const heroSmallClass = isSamll ? '-small' : ''
  return (
    <div
      className={`hero-wrap hero-bread${heroSmallClass}`}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
      }}
    >
      <div className='container'>
        <div className='row no-gutters slider-text align-items-center justify-content-center'>
          <div className='col-md-9 text-center'>
            <Breadcrumb className='hero-breadcrumb'>
              <BreadcrumbItem>
                <Link to='/' className='breadcrumb-item'>
                  Home
                </Link>
              </BreadcrumbItem>
              {singleProduct ? (
                <>
                  <BreadcrumbItem tag='span'>
                    <Link to='/recipes' className='breadcrumb-item'>
                      Recipes
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active tag='span'>
                    {breadName}
                  </BreadcrumbItem>
                </>
              ) : (
                <BreadcrumbItem active tag='span'>
                  {breadName}
                </BreadcrumbItem>
              )}
            </Breadcrumb>
            <h1 className='mb-0 bread'>{title}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
