import { FaTrash } from "react-icons/fa"
// icons
// import { IoIosArrowDown } from "react-icons/io";
import { quantitySuffix } from '../../data/data'

const IngredientField = ({
  index,
  handleChange,
  handleDelete,
  ingredientList,
}) => {
  // console.log("ingredientList", ingredientList)

  return (
    <div className='row pr-2'>
      <div className='col-7'>
        <label className="required" htmlFor={`ingredients-${index}`}>Ingredient Name</label>
        <input
          defaultValue={ingredientList[index].name}
          type='text'
          className='form-control'
          placeholder='Ingredient Name'
          name='name'
          id={`ingredients-${index}`}
          onChange={(e) => handleChange(e, index, 'name')}
        />
        {ingredientList[index].name === "" && (
          <div className="mb-1">
            <input
              type="hidden"
              className="form-control is-invalid"
            />
            <div className="invalid-feedback">
              Ingrediant Name is required
            </div>
          </div>
        )}
      </div>
      <div className='col-4'>
        <label className="required" htmlFor={`qty-${index}`}>Quantity</label>
        <div className="input-group">
          <input
            defaultValue={ingredientList[index].qty}
            type='text'
            className='form-control'
            name='qty'
            id={`qty-${index}`}
            style={{ width: "40%", textAlign: "right" }}
            onChange={(e) => handleChange(e, index, 'qty')}
          />
          <div className="input-group-append"
            style={{ width: "60%" }}
          >
            <select
              name="qtySuffix"
              className="form-control"
              defaultValue={ingredientList[index].qtySuffix}
              onChange={(e) => handleChange(e, index, 'qtySuffix')}
            >
              {quantitySuffix.map((option, i) => (
                <option value={option.value} key={i}>{option.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mb-1">
          <div style={{ minWidth: "40%", paddingLeft: '1em' }}>
            {ingredientList[index].qty === "" && (
              <>
                <input
                  type="hidden"
                  className="form-control is-invalid"
                />
                <div className="invalid-feedback">
                  Quntity is required
                </div>
              </>
            )}
          </div>
          <div style={{ width: "60%", paddingLeft: '.5em' }}>
            {ingredientList[index].qtySuffix === "" && (
              <>
                <input
                  type="hidden"
                  className="form-control is-invalid"
                />
                <div className="invalid-feedback">
                  Measurement is required
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='col-1'>
        <label></label>
        <button
          disabled={ingredientList.length < 2 ? true : false}
          onClick={() => handleDelete(index)}
          className='ingredient-field-trash-icon d-block mt-3'
          type="button"
        >
          <FaTrash />
        </button>
      </div>
    </div>
  )
}

export default IngredientField
