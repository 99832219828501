import { useHistory } from "react-router"
import { getParameterByName } from "../../utils/helpers"

import ResetPassword from "./RestePassword"
const AuthActions = () => {
  const history = useHistory()

  const mode = getParameterByName('mode')
  if (!mode) {
    history.push("/")
  }

  return (
    <ResetPassword />
  )
}

export default AuthActions
