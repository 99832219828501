// libraries
import { useContext, useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import RecipesContext from "../../context/recipes"
import firebase from "../../firebase"
import { Modal, message } from 'antd'

// helpers
import { getSingleRecipe, plural, timeToMins } from "../../utils/helpers"

// icons
import { IoLogoInstagram, IoLogoYoutube } from "react-icons/io"
import { IoArrowBackCircle } from "react-icons/io5"
import { GoPrimitiveDot } from "react-icons/go"
import { FaBan, FaPencilAlt, FaRegDotCircle, FaTrash } from "react-icons/fa"
import { GoCheck } from "react-icons/go"

// components
import Loading from "../../components/Loading"

const storage = firebase.storage()
const recipesDocument = firebase.firestore().collection("recipes")

const AdminRecipeDetails = () => {
  // use params
  const { id } = useParams()

  // use state
  const [comploading, setCompLoading] = useState(false)
  const [switchOn, setSwitchOn] = useState(false)

  // use history
  const history = useHistory()

  // use context
  const { allRecipes, setLoading } = useContext(RecipesContext)
  var singleRecipe = getSingleRecipe(allRecipes, id)

  const { uuid } = singleRecipe

  useEffect(() => {
    recipesDocument
      .doc(uuid)
      .get()
      .then((snap) => {
        setSwitchOn(snap.data().recommended)
      })
  }, [uuid])

  const handleSwitch = (e) => {
    if (e.stopPropagation) e.stopPropagation()
    setCompLoading(true)
    setSwitchOn(!switchOn)
    recipesDocument
      .doc(singleRecipe.uuid)
      .update({
        recommended: switchOn ? false : true,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        message.success("Recipe recommended status changed!")
        setCompLoading(false)
      })
      .catch(() => {
        setCompLoading(false)
        message.error("An error has occurred! Please try again")
      })
  }

  const approveConfirm = (e, item) => {
    if (e.stopPropagation) e.stopPropagation()
    Modal.confirm({
      title: "Are you sure?",
      icon: <span className="anticon check"><GoCheck size={22} /></span>,
      content: "You want to approve this item?",
      centered: true,
      // onCancel: handleCancle,
      cancelText: "No",
      onOk: () => approveItem(item),
      okText: "Yes",
      // cancelButtonProps: {
      //   danger: true
      // },
      // okButtonProps: {
      //   danger: true
      // }
    })
  }

  // handle approve click
  const approveItem = (item) => {
    setCompLoading(true)
    recipesDocument
      .doc(item.uuid)
      .update({
        status: "approved",
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        message.success("Recipe status changed successfully!")
        setCompLoading(false)
        history.push("/admin")
      })
      .catch(() => {
        setCompLoading(false)
        message.error("An error has occurred! Please try again")
      })
  }

  const rejectConfirm = (e, item) => {
    if (e.stopPropagation) e.stopPropagation()
    Modal.confirm({
      title: "Are you sure?",
      icon: <span className="anticon trash"><FaBan size={22} /></span>,
      content: "You want to reject this item?",
      centered: true,
      // onCancel: handleCancle,
      cancelText: "No",
      onOk: () => rejectItem(item),
      okText: "Yes",
      cancelButtonProps: {
        danger: true
      },
      okButtonProps: {
        danger: true
      }
    })
  }

  // handle reject click
  const rejectItem = (item) => {
    setLoading(true)
    recipesDocument
      .doc(item.uuid)
      .update({
        status: "rejected",
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        message.success("Recipe status changed successfully!")
        setLoading(false)
        history.push("/admin")
      })
      .catch((e) => {
        setLoading(false)
        message.error("An error has occurred! Please try again")
      })
  }

  const deleteConfirm = (e, item) => {
    if (e.stopPropagation) e.stopPropagation()
    Modal.confirm({
      title: "Are you sure?",
      icon: <span className="anticon trash"><FaTrash size={22} /></span>,
      content: <>
        <div>You want to delete this item?</div>
        <small>After delete, Data can't be recovered.</small>
      </>,
      centered: true,
      // onCancel: handleCancle,
      cancelText: "No",
      onOk: () => deleteItem(item),
      okText: "Yes",
      cancelButtonProps: {
        danger: true
      },
      okButtonProps: {
        danger: true
      }
    })
  }

  // handle reject click
  const deleteItem = (item) => {
    // console.log("Deleted", data)
    // Points to 'images'
    // var imagesRef = storageRef.child(data.image);
    // Get metadata properties

    // Create a reference from a Google Cloud Storage URI
    // var gsReference = storage.refFromURL('gs://bucket/images/stars.jpg');

    // Create a reference from an HTTPS URL
    // Note that in the URL, characters are URL escaped!
    // console.log(data.image.search('firebasestorage'), data.image)
    if (item.image && item.image.search('firebasestorage') >= 0) {
      var httpsImageReference = storage.refFromURL(item.image)
      // httpsImageReference.getDownloadURL().then(res => {
      //   console.log(res);
      // });
      // httpsImageReference.getMetadata()
      //   .then((metadata) => {
      //     // Metadata now contains the metadata for 'images/forest.jpg'
      //     console.log(metadata)
      //   })
      //   .catch((error) => {
      //     // Uh-oh, an error occurred!
      //     console.log(error)
      //   });
    }

    setLoading(true)
    recipesDocument.doc(item.uuid).delete()
      .then(() => {
        message.success("Recipe deleted successfully!")
        if (item.image && item.image.search('firebasestorage') >= 0) {
          httpsImageReference.delete()
        }
        setLoading(false)
        history.push("/admin")
      })
      .catch((e) => {
        setLoading(false)
        message.error("An error has occurred! Please try again")
      })
  }

  const editItem = (e, data) => {
    if (e.stopPropagation) e.stopPropagation()
    history.push(`/admin/edit/${data.category}/${data.uuid}`)
  }

  const {
    recipe,
    preptime,
    cooktime,
    category,
    image,
    ingredients,
    ingredientsNumber,
    instagram,
    instructions,
    meal,
    youtube,
    status,
    peoples
    // uuid,
  } = singleRecipe
  // console.log(ingredients);
  return (
    <div style={{ height: "calc(100vh - 65px)", overflowY: "scroll" }}>
      {comploading && <Loading fixed={true} />}
      <section className='ftco-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 mb-5'>
              <IoArrowBackCircle
                size={40}
                className='back-nav'
                onClick={() => history.goBack()}
              />
              <img style={{ width: "100%" }} src={image} alt={recipe} />
            </div>
            <div className='col-lg-6 product-details pl-md-5'>
              <h3
                style={{
                  color: "#d63230",
                  fontSize: "2rem",
                  fontWeight: "bold",
                }}
              >
                {recipe}
              </h3>
              <div className="row">
                <div className="col-md-4">
                  <p>
                    Prep Time
                    <span className='pl-1 d-inline text-primary'>
                      {preptime ? timeToMins(preptime) : 'NA'}
                    </span>
                  </p>
                </div>
                <div className="col-md-4">
                  <p>
                    Cook Time
                    <span className='pl-1 d-inline text-primary'>
                      {cooktime ? timeToMins(cooktime) : 'NA'}
                    </span>
                  </p>
                </div>
                <div className="col-md-4">
                  <p>
                    Category:
                    <span
                      className='mr-2 text-capitalize'
                      style={{ color: "#000" }}
                    >
                      <span style={{ color: "#bbb" }}> {category}</span>
                    </span>
                  </p>
                </div>
                <div className="col-md-12">
                  <p>
                    <span className='mr-2' style={{ color: "#000" }}>
                      For{" "}
                      <span style={{ color: "#bbb", textTransform: "capitalize" }}>
                        {meal}
                      </span>
                    </span>
                    {peoples &&
                      <span className='mr-2' style={{ color: "#000" }}>
                        Feed{" "}
                        <span style={{ color: "#d63230", textTransform: "capitalize" }}>
                          {plural(peoples, "person", "peoples")}
                        </span>
                      </span>
                    }
                  </p>
                </div>
              </div>
              {/*
                <div className='rating d-flex'>
                  <p className='text-left mr-4'>
                    Prep Time
                    <span className='mr-2 d-inline text-primary'>
                      {" "}
                      {preptime}
                    </span>
                  </p>
                  <p className='text-left mr-4'>
                    Cook Time
                    <span className='mr-2 d-inline text-primary'>
                      {" "}
                      {cooktime}
                    </span>
                  </p>
                  <p className='text-left mr-4'>
                    <span
                      className='mr-2 text-capitalize'
                      style={{ color: "#000" }}
                    >
                      <span style={{ color: "#bbb" }}> {category}</span>
                    </span>
                  </p>
                  <p className='text-left'>
                    <span className='mr-2' style={{ color: "#000" }}>
                      For{" "}
                      <span
                        style={{ color: "#bbb", textTransform: "capitalize" }}
                      >
                        {meal}
                      </span>
                    </span>
                  </p>
                </div> */}
              <div
                className='d-flex align-items-center'
                style={{ flex: "1" }}
              >
                <label className='m-0 mr-2'>Recommended</label>
                <div
                  onClick={(e) => handleSwitch(e)}
                  className='ToggleSwitch'
                >
                  <div className={switchOn ? "knob switch-active" : "knob"} />
                </div>
              </div>
              <div className='my-3'>
                Actions
                <div className="item-action pt-2">
                  <div
                    className='icon-wrapper edit'
                    title='Edit'
                    onClick={(e) => editItem(e, singleRecipe)}
                  >
                    <FaPencilAlt />
                  </div>

                  {status === "approved" ? (
                    <div
                      className='icon-wrapper trash'
                      title='Reject'
                      onClick={(e) => rejectConfirm(e, singleRecipe)}
                    >
                      <FaBan />
                    </div>
                  ) : status === "pending" ? (
                    <>
                      <div
                        className='icon-wrapper check'
                        title='Approve'
                        onClick={(e) => approveConfirm(e, singleRecipe)}
                      >
                        <GoCheck size={20} />
                      </div>
                      <div
                        className='icon-wrapper trash'
                        title='Reject'
                        onClick={(e) => rejectConfirm(e, singleRecipe)}
                      >
                        <FaBan />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className='icon-wrapper check'
                        title='Approve'
                        onClick={(e) => approveConfirm(e, singleRecipe)}
                      >
                        <GoCheck size={20} />
                      </div>
                      <div
                        className='icon-wrapper trash'
                        title='Delete'
                        onClick={(e) => deleteConfirm(e, singleRecipe)}
                      >
                        <FaTrash />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <p className='price'>
                <span
                  style={{
                    textTransform: "capitalize",
                    fontSize: "1.6rem",
                    fontWeight: "600",
                  }}
                >
                  {ingredientsNumber} Ingredients
                </span>
              </p>
              <p style={{ marginTop: "-15px", color: "#000" }}>
                {ingredients.sort((a, b) => b.qty - a.qty).map((ingredient, i) => (
                  <span
                    key={i}
                    style={{
                      color: "#666",
                      textTransform: "capitalize",
                      display: "block",
                    }}
                  >
                    {ingredient.value ? (
                      <>
                        <GoPrimitiveDot size={12} color='#d63230' /> <span>{ingredient.value}</span>
                      </>
                    ) : (
                      <>
                        <FaRegDotCircle size={12} color='#d63230' />
                        <span className="pl-2">{ingredient.qty}</span>
                        <span className="pl-1">{ingredient.qtySuffix}</span>
                        <span className="pl-2">{ingredient.name}</span>
                      </>
                    )}
                  </span>
                ))}
              </p>
              <div className='row mt-4'>
                <div className='col-md-6'>
                  <div className='form-group d-flex'>
                    <div className='select-wrap'>
                      <div className='icon'>
                        <span className='ion-ios-arrow-down'></span>
                      </div>
                      {/* <select name='' id='' className='form-control'>
                      <option value=''>Small</option>
                      <option value=''>Medium</option>
                      <option value=''>Large</option>
                      <option value=''>Extra Large</option>
                    </select> */}
                      {youtube !== "" && (
                        <a
                          href={youtube}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='mx-3'
                        >
                          <IoLogoYoutube size={40} />
                        </a>
                      )}
                      {instagram !== "" && (
                        <a
                          href={instagram}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='mx-3'
                        >
                          <IoLogoInstagram size={40} />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='my-5'>
            <h3>Cooking Instructions</h3>
            <div dangerouslySetInnerHTML={{ __html: instructions }} />
          </div>
        </div>
      </section>
    </div>
  )
}

export default AdminRecipeDetails
