// libraries
import { useContext } from "react";

// context
import RecipesContext from "../../context/recipes";

// components
import Loading from "../Loading";
import RecipesItem from "./RecipesItem";

const NewRecipes = () => {
  const { loading, newRecipes } = useContext(RecipesContext);

  return (
    <section className="">
      <div className="container">
        <div className="row justify-content-center mb-3 pb-3">
          <div className="col-md-12 heading-section text-center">
            <h2 className="mb-4">New this week</h2>
            {/* <p>These recipes are added to our users weekly</p> */}
          </div>
        </div>
      </div>
      {loading ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <div className="container">
          <div className="row">
            {newRecipes.length > 0 ? (
              newRecipes.map((item, index) => {
                return (
                  <RecipesItem
                    key={index}
                    title={item.recipe}
                    id={item.uuid}
                    meal={item.meal}
                    category={item.category}
                    image={item.image}
                    user={item.user}
                    item={item}
                  />
                );
              })
            ) : (
              <div className="recipes-empty">
                <h5>No recipes to show!</h5>
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default NewRecipes;
