import React, { useState, useContext, createContext } from 'react'

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { useToasts } from "react-toast-notifications"


import firebase from "../firebase"
// context
import UserContext from "../context/user"
import LoaderContext from "../context/loader"
import { days, mealCategories, weeks } from '../data/data'


//Create Context
const MealPlanContext = createContext()

export const MealPlanProvider = ({ children }) => {
  const { user, userData } = useContext(UserContext)
  const { addToast } = useToasts()
  const { setLoading } = useContext(LoaderContext)
  const [day, setDay] = useState(0)
  const [week, setWeek] = useState("weekOne")
  const [meal, setMeal] = useState()
  const [item, setItem] = useState({})
  const [confirm, setConfirm] = useState({
    show: false,
    // type: 'danger',
    // title: 'Error'
  })

  const getConfirmation = (confirm) => {
    // console.log("item", item);
    if (!user) {
      console.log("Please Log in First")
      addToast("Please Log in First", {
        appearance: "error",
      })
      return
    } else {
      let weekOnePlan = userData?.weekOne?.breakfast?.length > 0
      let weekTwoplan = userData?.weekTwo?.breakfast?.length > 0
      let hasMealPlan = weekOnePlan || weekTwoplan
      if (hasMealPlan) {

      } else {
        addToast("Generate Meal Plan First", {
          appearance: "error",
        })
        return
      }
    }

    setConfirm({
      show: true,
      type: confirm.type || 'danger',
      title: confirm.title || 'Warning',
      message: confirm.message || 'Are you sure, You want add this to meal plan?',
      description: confirm.description || 'After delete, Data can\'t be recover.',
      item: confirm.item,
      action: confirm.action || 'confirm'
    })
    setItem(confirm?.item)
    setMeal(confirm?.item?.meal)
    if (confirm.defaultButton === 'primary') {
      setTimeout(() => {
        var el = document.getElementById('confirm-primary')
        el && el.focus()
      }, 500)
    }
  }

  const confirmed = () => {
    uploadMealPlanToFS()
  }

  const cancaled = (e) => {
    setConfirm({ show: false })
  }

  const modalToggle = () => {
    setConfirm({ show: false })
  }

  const uploadMealPlanToFS = () => {
    if (user !== null) {
      const msg = week === 'weekOne' ? '' : 'second week'
      if (userData[week]?.breakfast?.length === 0) {
        setConfirm({ show: false })
        addToast(`Generate ${msg} meal plan first`, {
          appearance: "error",
        })
        return
      }
      setLoading(true)
      let payload = {}
      if (meal === "breakfast") {
        payload[week] = {
          // ...payload,
          breakfast: userData[week][meal]?.map((oldItem, i) => (i === day ? item : oldItem))
        } //[day][item]
      } else if (meal === "lunch") {
        payload[week] = {
          lunch: userData[week][meal]?.map((oldItem, i) => (i === day ? item : oldItem))
        } //[day][item]
      } else if (meal === "dinner") {
        payload[week] = {
          dinner: userData[week][meal].map((oldItem, i) => (i === day ? item : oldItem))
        } //[day][item]
      }
      setConfirm({ show: false })
      // console.log("payload", week, meal, day, confirm.item)
      // console.log("payload", payload)
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .set(
          payload,
          { merge: true }
        )
        .then(() => {
          // console.log("meal plan added");
          addToast(`${item.recipe} added to ${msg} meal plan`, {
            appearance: "success",
          })
        })
        .catch((e) => {
          setLoading(false)
          addToast(e.message, {
            appearance: "error",
          })
          console.log("meal plan error", e.message)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      console.log("Please Log in First")
      addToast("Please Log in First", {
        appearance: "warning",
      })
    }
  }
  // console.log('meal plan context')

  return (
    <MealPlanContext.Provider
      value={{
        getConfirmation
      }}>
      {children}
      <Modal
        isOpen={confirm.show}
        onClose={() => setConfirm({ show: false })}
        // modalTransition={{ timeout: 700 }}
        // backdropTransition={{ timeout: 1300 }}
        centered
        // keyboard={false}
        toggle={modalToggle}
      >
        <ModalHeader toggle={modalToggle}
          className={`btn-${confirm.type}`}
        >
          {confirm.title}
        </ModalHeader>
        <ModalBody>
          <h4>{confirm.message}</h4>
          <div className="form-group">
            <label htmlFor="week">Week</label>
            <div className="select-wrap">
              <select
                name="week"
                className="form-control"
                onChange={(e) => setWeek(e.target.value)}
              >
                {/* <option value="select">Select</option> */}
                {weeks.map((option, i) => (
                  <option value={option.value} key={i} disabled={userData[option.value]?.breakfast?.length === 0}>{option.label}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="day">Day</label>
            <div className="select-wrap">
              <select
                name="day"
                className="form-control"
                onChange={(e) => setDay(parseInt(e.target.value))}
              >
                {/* <option value="select">Select</option> */}
                {days.map((option, i) => (
                  <option value={option.value} key={i}>{option.label}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="meal">Meal</label>
            <div className="select-wrap">
              <select
                name="meal"
                className="form-control"
                defaultValue={meal}
                onChange={(e) => setMeal(e.target.value)}
              >
                {/* <option value="select">Select</option> */}
                {mealCategories.map((option, i) => (
                  option.main && <option value={option.value} key={i} disabled={!option.main}>{option.label}</option>
                ))}
              </select>
            </div>
          </div>
          <p>{item?.recipe} Will added</p>
        </ModalBody>
        <ModalFooter>
          <Button id="confirm-primary" color={confirm.type} onClick={confirmed} autoFocus={true}>Yes</Button>
          <Button color="secondary" onClick={cancaled}>No</Button>
        </ModalFooter>
      </Modal>
    </MealPlanContext.Provider>
  )
}

export default MealPlanContext
