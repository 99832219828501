import { AiOutlinePlusSquare } from "react-icons/ai"
import { FiClock } from "react-icons/fi"
import { timeToMins } from '../../utils/helpers'

const MealDrawerItem = ({ data, getRecipe }) => {
  return (
    <div className="drawer-item">
      <img src={data.image} alt="recipe" className="drawer-item-img" />
      <div className="drawer-item-body">
        <p>{data.name}</p>
        <div className="drawer-item-footer">
          <FiClock className="drawer-item-clock" />
          <span className="drawer-item-time">{timeToMins(data.cooktime)}</span>
          <span className="drawer-item-add">
            <AiOutlinePlusSquare onClick={() => getRecipe(data)} />
          </span>
        </div>
      </div>
    </div>
  )
}

export default MealDrawerItem
