// libraries
import { useState, useContext } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import classnames from "classnames"
import { useForm } from "react-hook-form"
import { useToasts } from "react-toast-notifications"
import firebase from "../firebase"

// styles
import "../styles/ExtraStyling.scss"

// context
import UserContext from "../context/user"
import { list } from "../utils/mailgun"
import { PasswordEye } from "../components/PasswordEye"
// components
import Loading from "./Loading"
import { InvalidFeedbackV7, preventSubmit, validEmailRegx } from "../utils/validation"

const LoginModal = (props) => {
  // use context
  const { loading, setLoading, forgotPassword } = useContext(UserContext)

  // use states
  const [password, setPassword] = useState("")
  const [newsLatter, setNewsLatter] = useState(true)
  const [forgot, setForgot] = useState(false)
  const { buttonLabel, className, buttonClassName } = props

  const [modal, setModal] = useState(false)

  const [activeTab, setActiveTab] = useState("login")
  const userDocument = firebase.firestore().collection("users")

  // use toast
  const { addToast } = useToasts()

  const { register, handleSubmit, formState: { errors }, formState, clearErrors } = useForm({})

  const handleSignupSumbit = async (data) => {
    const { signupEmail: email, signupPassword: password, fullname, username } = data
    setLoading(true)
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(function (currentUser) {
          currentUser.user.updateProfile({
            displayName: fullname,
          })
          const id = currentUser.user.uid
          userDocument
            .doc(id)
            .set({
              id,
              email: email,
              username: username,
              displayName: fullname
            })
            .then(() => {
              addToast("Signed up successfully.", {
                appearance: "success",
              })
              try {
                list.members().create({
                  subscribed: true,
                  address: email,
                  name: fullname
                })
              } catch (err) {
                console.log(err)
              }
              setLoading(false)
            })
            .catch((e) => {
              setLoading(false)
              console.log(e)
              addToast(e.message, {
                appearance: "error",
              })
            })
        })
    } catch (e) {
      setLoading(false)
      console.log(e)
      addToast(e.message, {
        appearance: "error",
      })
    }
  }

  const handleLoginSumbit = async (data) => {
    const { loginEmail: email, loginPassword: password } = data

    setLoading(true)
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          setLoading(false)
          addToast("Login Success", {
            appearance: "success",
          })
        })
    } catch (e) {
      setLoading(false)
      console.log(e)
      addToast(e.message, {
        appearance: "error",
      })
    }
  }

  const handleResetPassword = (data) => {
    const { resetEmail: email } = data
    if (forgotPassword(email)) {
      addToast(`Email is being sent to ${email}`, {
        appearance: "success",
      })
      setModal(false)
      setForgot(false)
    } else {
      addToast(`Someting went wrong`, {
        appearance: "error",
      })
    }
  }

  const modalToggle = () => {
    setModal(!modal)
    if (!modal) {
      setForgot(false)
    }
    clearErrors()
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
    clearErrors()
  }

  const loginButton = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
    if (forgot) setForgot(false)
    clearErrors()
  }

  if (loading) {
    return <Loading fixed={true} />
  }

  const onError = err => console.log(err)

  return (
    <div>
      <div className={buttonClassName} onClick={modalToggle}>
        {buttonLabel}
      </div>
      <Modal
        isOpen={modal}
        centered
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={modalToggle}
        className={className}
      >
        <ModalHeader toggle={modalToggle}>
          <Nav tabs>
            <NavItem className="cursor-pointer">
              <NavLink
                className={classnames({ active: activeTab === "login" })}
                onClick={() => {
                  loginButton("login")
                }}
              >
                Log in
              </NavLink>
            </NavItem>
            <NavItem className="cursor-pointer">
              <NavLink
                className={classnames({ active: activeTab === "signup" })}
                onClick={() => {
                  toggle("signup")
                }}
              >
                Signup
              </NavLink>
            </NavItem>
          </Nav>
        </ModalHeader>
        <ModalBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="login">
              {activeTab === "login" &&
                <Row>
                  <Col sm="12">
                    {!forgot ? (
                      <Form onSubmit={handleSubmit(handleLoginSumbit, onError)}>
                        <div className="text-right">
                          <span
                            className="forget_pass"
                            onClick={() => {
                              setForgot(true)
                              clearErrors()
                            }}
                          >
                            Forgot Password
                          </span>
                        </div>
                        <FormGroup>
                          <Label for="loginEmail">Email</Label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("loginEmail", {
                              required: "Email is required.",
                              validate: {
                                email: email => email.match(validEmailRegx) || "Please enter valid Email"
                              }
                            })}
                          />
                          <InvalidFeedbackV7 name="loginEmail" errors={errors} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="loginPassword">Password</Label>
                          <input
                            type="password"
                            className="form-control"
                            id="loginPassword"
                            {...register("loginPassword", { required: "Password is required." })}
                          />
                          <PasswordEye target="loginPassword" />
                          <InvalidFeedbackV7 name="loginPassword" errors={errors} />
                        </FormGroup>
                        <div className="text-right">
                          <Button
                            className="btn btn-primary py-3 px-5"
                            type="submit"
                            disabled={preventSubmit(formState)}
                          >
                            Log in
                          </Button>
                        </div>
                      </Form>
                    ) : (
                      <Form onSubmit={handleSubmit(handleResetPassword, onError)}>
                        <div className="text-right">
                          <span
                            className="forget_pass"
                            onClick={() => {
                              setForgot(false)
                              clearErrors()
                            }}
                          >
                            Back to Log in
                          </span>
                        </div>
                        <FormGroup>
                          <Label for="resetEmail">Email</Label>
                          <input
                            type="email"
                            className="form-control"
                            {...register("resetEmail", {
                              required: "Email is required.",
                              validate: {
                                email: email => email.match(validEmailRegx) || "Please enter valid Email"
                              }
                            })}
                          />
                          <InvalidFeedbackV7 name="resetEmail" errors={errors} />
                        </FormGroup>
                        <div className="text-right">
                          <Button
                            type="submit"
                            className="btn btn-primary py-3 px-5 btn-block"
                            disabled={preventSubmit(formState)}
                          >
                            Reset Password
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Col>
                </Row>
              }
            </TabPane>
            <TabPane tabId="signup">
              {activeTab === "signup" &&
                <Row>
                  <Col sm="12">
                    <Form onSubmit={handleSubmit(handleSignupSumbit, onError)}>
                      <FormGroup>
                        <Label for="username">Username</Label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("username", { required: "Username is required." })}
                        />
                        <InvalidFeedbackV7 name="username" errors={errors} />
                      </FormGroup>
                      <FormGroup>
                        <Label for="fullname">Full Name</Label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("fullname", { required: "Full Name is required." })}
                        />
                        <InvalidFeedbackV7 name="fullname" errors={errors} />
                      </FormGroup>
                      <FormGroup>
                        <Label for="signupEmail">Email</Label>
                        <input
                          type="email"
                          className="form-control"
                          {...register("signupEmail", {
                            required: "Email is required.",
                            validate: {
                              email: email => email.match(validEmailRegx) || "Please enter valid Email"
                            }
                          })} />
                        <InvalidFeedbackV7 name="signupEmail" errors={errors} />
                      </FormGroup>
                      <FormGroup>
                        <Label for="signupPassword">Password</Label>
                        <input
                          type="password"
                          className="form-control"
                          id="signupPassword"
                          {...register("signupPassword", {
                            required: "Password is required.",
                            onChange: (e) => setPassword(e.target.value)
                          })}
                        />
                        <PasswordEye target="signupPassword" />
                        <InvalidFeedbackV7 name="signupPassword" errors={errors} />
                      </FormGroup>
                      <FormGroup>
                        <Label for="confirmPassword">Confirm Password</Label>
                        <input
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          {...register("confirmPassword", {
                            required: " Confirm Password is required.",
                            validate: {
                              confPass: v => v === password || "Password and Confirm pasword does not match!"
                            }
                          })}
                        />
                        <PasswordEye target="confirmPassword" />
                        <InvalidFeedbackV7 name="confirmPassword" errors={errors} />
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" checked={newsLatter} onChange={(e) => setNewsLatter(e.target.checked)} />{' '}
                          Uncheck this box if you do not want to receive marketing
                        </Label>
                      </FormGroup>
                      <div className="text-right mt-3">
                        <Button
                          className="btn btn-primary py-3 px-5"
                          type="submit"
                          disabled={preventSubmit(formState)}
                        >
                          Signup
                        </Button>
                        {/* )} */}
                      </div>
                    </Form>
                  </Col>
                </Row>
              }
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default LoginModal
