import { useContext, useRef, useState } from 'react';

// icons
import {
  IoLogoFacebook, IoLogoInstagram,
  // IoLogoYoutube
} from "react-icons/io";
import { GoPrimitiveDot } from "react-icons/go";
import { RiAddCircleLine, RiFlagFill, RiFlagLine, RiHeart3Fill, RiHeart3Line } from "react-icons/ri";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { useReactToPrint } from 'react-to-print';
import classnames from 'classnames';
import { FaRegDotCircle } from 'react-icons/fa';
import Review from '../Review';
import {
  // customPrint,
  plural, timeToMins
} from '../../utils/helpers';
import MealPlanContext from '../../context/mealplan';
import RecipeDetailsPrintComponent from './RecipeDetailsPrintComponent';

const RecipeDetailsComponent = ({
  product,
  isFav,
  addToFavorites,
  deleteFavorite,
  isFlagged,
  flagRecipe,
  unflagRecipe
}) => {
  const { getConfirmation } = useContext(MealPlanContext);

  const {
    recipe,
    cooktime,
    preptime,
    category,
    image,
    ingredients,
    ingredientsNumber,
    instagram,
    instructions,
    meal,
    nutrition,
    // youtube,
    facebook,
    user,
    peoples
  } = product;

  const componentRef = useRef();
  const [activeTab, setActiveTab] = useState('1');

  const tabChange = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const handleMealPlan = (e, item) => {
    if (e.stopPropagation) e.stopPropagation();
    getConfirmation({
      title: 'Add To Meal Plan?',
      type: 'info',
      message: ' ',
      description: 'Are you sure, You want add this recipe to meal plan?',
      defaultButton: 'primary',
      item: item,
      action: 'approve'
    })
  }

  // const handlePrint = () => {
  //   var originalContents = document.body.innerHTML;
  //   var printContents = componentRef.current.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // }

  // const handlePrint = () => {
  //   customPrint(componentRef.current, `Plan Shop Cook - ${recipe}`)
  // }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // pageStyle: "background: #000"
    // copyStyles: false,
    documentTitle: `Plan Shop Cook - ${recipe}`,
  });

  return (
    <section className='ftco-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 mb-5'>
            <img style={{ width: "100%" }} src={image} alt={recipe} />
          </div>
          <div className='col-lg-6 product-details pl-md-5'>
            <div
              className='d-flex justify-content-between align-items-start'
              style={{ fontSize: "2rem" }}
            >
              <h3
                style={{ color: "#d63230", fontSize: "2rem", fontWeight: "bold", maxWidth: '70%', overflow: "hidden" }}>
                {recipe}
              </h3>
              <span>
                <RiAddCircleLine
                  color='#000'
                  style={{ cursor: "pointer", marginRight: ".25em" }}
                  onClick={(e) => handleMealPlan(e, product)}
                />
                {isFav ? (
                  <RiHeart3Fill
                    color='#d63230'
                    style={{ cursor: "pointer" }}
                    onClick={deleteFavorite}
                    title="remove from favorite"
                  />
                ) : (
                  <RiHeart3Line
                    color='#d63230'
                    style={{ cursor: "pointer" }}
                    onClick={addToFavorites}
                    title="add to favorite"
                  />
                )}
                {isFlagged ? (
                  <RiFlagFill
                    color='#888'
                    style={{ cursor: "pointer", marginLeft: ".25em" }}
                    onClick={unflagRecipe}
                    title="remove from flagged"
                  />
                ) : (
                  <RiFlagLine
                    color='#888'
                    style={{ cursor: "pointer", marginLeft: ".25em" }}
                    onClick={flagRecipe}
                    title="mark recipe to flagged/report"
                  />
                )}
              </span>
            </div>
            <p>
              Submited by: <span style={{ color: "#d63230" }}>{user}</span>
            </p>
            <div className="row">
              <div className="col-md-6">
                <p>
                  Prepration Time
                  <span className='pl-1 d-inline text-primary'>
                    {preptime ? timeToMins(preptime) : 'NA'}
                  </span>
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  Cook Time
                  <span className='pl-1 d-inline text-primary'>
                    {cooktime ? timeToMins(cooktime) : 'NA'}
                  </span>
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  Category:
                  <span
                    className='mr-2 text-capitalize'
                    style={{ color: "#000" }}
                  >
                    <span style={{ color: "#bbb" }}> {category}</span>
                  </span>
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <span className='mr-2' style={{ color: "#000" }}>
                    For{" "}
                    <span style={{ color: "#bbb", textTransform: "capitalize" }}>
                      {meal}
                    </span>
                  </span>
                  {peoples &&
                    <span className='mr-2' style={{ color: "#000" }}>
                      Feed{" "}
                      <span style={{ color: "#d63230", textTransform: "capitalize" }}>
                        {plural(peoples, "person", "peoples")}
                      </span>
                    </span>
                  }
                </p>
              </div>
            </div>
            {/* <div className='rating d-md-flex d-block'>
              <p className='text-left mr-4'>
                Prep Time
                <span className='pl-1 mr-2 d-inline text-primary'>
                  {preptime ? timeToMins(preptime) : 'NA'}
                </span>
              </p>
              <p className='text-left mr-4'>
                Cook Time
                <span className='pl-1 mr-2 d-inline text-primary'>
                  {cooktime ? timeToMins(cooktime) : 'NA'}
                </span>
              </p>
              <p className='text-left mr-4'>
                <span
                  className='mr-2 text-capitalize'
                  style={{ color: "#000" }}
                >
                  <span style={{ color: "#bbb" }}> {category}</span>
                </span>
              </p>
              <p className='text-left'>
                <span className='mr-2' style={{ color: "#000" }}>
                  For{" "}
                  <span style={{ color: "#bbb", textTransform: "capitalize" }}>
                    {meal}
                  </span>
                </span>
              </p>
            </div> */}
            <div>
              <p className='price'>
                <span
                  style={{
                    textTransform: "capitalize",
                    fontSize: "1.6rem",
                    fontWeight: "600",
                  }}
                >
                  {ingredientsNumber} Ingredients
                </span>
              </p>
              <p style={{ marginTop: "-15px", color: "#000" }}>
                {ingredients.sort((a, b) => b.qty - a.qty).map((ingredient, i) => (
                  <span
                    key={i}
                    style={{
                      color: "#666",
                      textTransform: "capitalize",
                      display: "block",
                    }}
                  >
                    {ingredient.value ? (
                      <>
                        <GoPrimitiveDot size={12} color='#d63230' /> <span>{ingredient.value}</span>
                      </>
                    ) : (
                      <>
                        {/* <GoPrimitiveDot size={12} color='#d63230' /> */}
                        <FaRegDotCircle size={12} color='#d63230' />
                        <span className="pl-2">{ingredient.qty}</span>
                        <span className="pl-1">{ingredient.qtySuffix}</span>
                        <span className="pl-2">{ingredient.name}</span>
                      </>
                    )}
                    {/* <GoPrimitiveDot size={12} color='#d63230' /> {ing.value} */}
                  </span>
                ))}
              </p>
            </div>
            <div className='row mt-4'>
              <div className='col-md-6'>
                <div className='form-group d-flex'>
                  <div className='select-wrap'>
                    <div className='icon'>
                      <span className='ion-ios-arrow-down'></span>
                    </div>
                    {facebook !== "" && (
                      <a
                        href={facebook}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='mx-3'
                      >
                        <IoLogoFacebook size={40} color='#4267B2' />
                      </a>
                    )}
                    {instagram !== "" && (
                      <a
                        href={instagram}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='mx-3'
                      >
                        <IoLogoInstagram size={40} />
                      </a>
                    )}
                    {/* {youtube !== "" && (
                      <a
                        href={youtube}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='mx-3'
                      >
                        <IoLogoYoutube size={40} />
                      </a>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <p>
              <button
                className='btn btn-primary py-3 px-5'
                onClick={() => handlePrint()}
              >Export Ingredients (Print)</button>
            </p>
            <p>
              <span className='btn btn-secondary py-3 px-5 disabled mr-2'>Shop Ingredients (Coming Soon)</span>
            </p>
          </div>
        </div>
        <div className='my-5'>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { tabChange('1'); }}
              >
                Cooking Instructions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { tabChange('2'); }}
              >
                Nutrition Facts
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => { tabChange('3'); }}
              >
                Ratings
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="pt-4">
            <TabPane tabId="1">
              <h3>Cooking Instructions</h3>
              <div dangerouslySetInnerHTML={{ __html: instructions }} />
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <h3>Nutrition Facts</h3>
                  {nutrition ? nutrition : 'No Facts Provided'}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Review />
            </TabPane>
          </TabContent>
        </div>
      </div>
      <div className="print-container" ref={componentRef}>
        {/* <h1>Hello</h1> */}
        <RecipeDetailsPrintComponent product={product} />
      </div>
    </section>
  )
}

export default RecipeDetailsComponent;
