// libs
import { BrowserRouter } from "react-router-dom"
import { ToastProvider } from "react-toast-notifications"

// styles
import "./App.css"
import "./styles/index.scss"

// routes
import Routes from "./routes/Routes"

// context
import { UserProvider } from "./context/user"
import { RecipesProvider } from "./context/recipes"
import { AdminProvider } from "./context/admin"
import { MealPlanProvider } from "./context/mealplan"
import { LoaderProvider } from "./context/loader"

function App() {
  return (
    <LoaderProvider>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={3000}
        placement='top-right'
      >
        <UserProvider>
          <RecipesProvider>
            <MealPlanProvider>
              <AdminProvider>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </AdminProvider>
            </MealPlanProvider>
          </RecipesProvider>
        </UserProvider>
      </ToastProvider>
    </LoaderProvider>
  )
}

export default App
