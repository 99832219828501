// libraries
import { useContext, useEffect, useState } from "react"
import { Link, useHistory, Redirect } from "react-router-dom"
import { useToasts } from "react-toast-notifications"

// style
import "../styles/Recipes.scss"

// context
import UserContext from "../context/user"
import RecipesContext from "../context/recipes"

// helpers
import { setToLocalStorage } from "../utils/helpers"

// components
import Hero from "../components/Hero"
import Loading from "../components/Loading"
import Newsletter from "../components/Newsletter"

const Recipes = ({ fromHome }) => {
  // use context
  const { user } = useContext(UserContext)
  const { loading } = useContext(RecipesContext)
  const { addToast } = useToasts()

  // use params
  const history = useHistory()

  // use state
  const [category, setCategory] = useState()
  // const [comingSoon, showComingSoon] = useState("")
  const [dietPlan, setDietPlan] = useState(null)
  // const [dietConfirm, setDietConfirm] = useState(false);

  const handleCategory = (value) => {
    setCategory(value)
    setToLocalStorage("dietPlan", value)
  }

  const handlePlan = (plan) => {
    setDietPlan(plan)
    if (plan === 'no') {
      handleCategory("all")
    }
  }

  useEffect(() => {
    handleCategory("")
  }, [])

  if (!user) {
    addToast("Please log in first!", {
      appearance: "error",
    })
    return <Redirect to='/recipes' />
  }

  return (
    <>
      <Hero
        // image={}
        title={"Generate Meal Plan"}
        breadName={"Meal Plan"}
        singleProduct={false}
      />
      <section className='py-5'>
        <div className='container'>
          {loading ? (
            <div className='loading-wrapper'>
              <Loading />
            </div>
          ) : (
            <>
              <div className='row justify-content-center'>
                <div className='col-md-10 text-center'>
                  {/* <div className='d-flex align-items-center justify-content-center mb-5'>
                    <button
                      className='btn btn-primary'
                      onClick={() => setDietConfirm(true)}
                    >
                      Generate Meal Plan
                    </button>
                  </div> */}
                  {true && (
                    <div className='mt-4'>
                      <h3>Do you follow a certain diet?</h3>
                      <div className='d-flex align-items-center justify-content-center'>
                        <button
                          className='btn btn-primary mr-2'
                          onClick={() => handlePlan('yes')}
                        >
                          Yes
                        </button>
                        <button
                          className='btn btn-secondary'
                          onClick={() => handlePlan('no')}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  )}
                  {dietPlan === 'yes' && (
                    <div className="mt-4">
                      <h4>Select a diet plan</h4>
                      {/* <select
                        className="form-control mb-4"
                        onChange={(e) => handleCategory(e.target.value)}
                      >
                        <option value="">Select diet plan</option>
                        <option value="all">All</option>
                        {categories.map(day => (
                          <option value={day.value} key={day.value}>{day.label}</option>
                        ))}
                      </select> */}
                      <ul className='product-category'>
                        <li>
                          <span
                            onClick={() => handleCategory("all")}
                            className={`${category === "all" && "active"}`}
                          >
                            All
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => handleCategory("vegetarian")}
                            className={`${category === "vegetarian" && "active"}`}
                          >
                            Vegetarian
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => handleCategory("vegan")}
                            className={`${category === "vegan" && "active"}`}
                          >
                            Vegan
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => handleCategory("pescatarian")}
                            className={`${category === "pescatarian" && "active"}`}
                          >
                            Pescatarian
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => handleCategory("keto")}
                            className={`${category === "keto" && "active"}`}
                          >
                            Keto
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => handleCategory("mediterranean")}
                            className={`${category === "mediterranean" && "active"}`}
                          >
                            Mediterranean
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => handleCategory("kids")}
                            className={`${category === "kids" && "active"}`}
                          >
                            Kids
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => handleCategory("healthy")}
                            className={`${category === "healthy" && "active"}`}
                          >
                            Healthy
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {category && (
            <div className='row d-flex justify-content-center mt-4'>
              <Link to='/meal-plan/1' className='btn btn-primary mx-3 w-25'>
                Generate 1 Week Meal Plan
              </Link>
              <Link to='/meal-plan/2' className='btn btn-primary mx-3 w-25'>
                Generate 2 Week Meal Plan
              </Link>
              <Link to='/meal-plan/4' className='btn btn-primary mx-3 w-25'>
                Generate One Month Meal Plan
              </Link>
            </div>
          )}
          {/* {category && (
            <div className='row d-flex justify-content-center mt-4'>
              <Link to='/meal-plan/1' className='btn btn-primary mx-3 w-25'>
                Generate 1 Week Meal Plan
              </Link>
              <button onClick={() => showComingSoon("2")} className='btn btn-secondary mx-3 w-25'>
                Generate 2 Week Meal Plan
              </button>
              <button onClick={() => showComingSoon("4")} className='btn btn-secondary mx-3 w-25'>
                Generate One Month Meal Plan
              </button>
            </div>
          )} */}
          <div className='row mt-4'>
            <div className='col-12 text-center'>
              {/* {comingSoon && (
                <h4 className="my-5">This Functionality Coming Soon</h4>
              )} */}
              <span
                className='text-muted'
                style={{ cursor: "pointer" }}
                onClick={() => history.goBack()}
              >
                Back
              </span>
            </div>
          </div>

        </div>
      </section>
      <Newsletter />
    </>
  )
}

export default Recipes
