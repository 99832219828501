import firebase from "../firebase"

const imagesRef = firebase.storage().ref('/images').toString().replace("gs://", "https://storage.googleapis.com/")
const categoriesRef = firebase.storage().ref('/categories').toString().replace("gs://", "https://storage.googleapis.com/")
const assetsRef = firebase.storage().ref('/assets').toString().replace("gs://", "https://storage.googleapis.com/")

const banners = {
  "hero": `${categoriesRef}/hero.jpg`,
  "favorite": `${categoriesRef}/favorite-bg.jpg`,
  "submitRecipe": `${categoriesRef}/submit-recipe.jpg`,
}

const categories = {
  "category": `${categoriesRef}/categories.webp`,
  "vegetarian": `${categoriesRef}/vegetarian.jpg`,
  "pescatarian": `${categoriesRef}/pescatarian.jpg`,
  "vegan": `${categoriesRef}/vegan.jpg`,
  "keto": `${categoriesRef}/keto.jpg`,
  "mediterranean": `${categoriesRef}/mediterranean.jpg`,
  "kids": `${categoriesRef}/kids.jpg`,
  "healthy": `${categoriesRef}/healthy.jpg`,
}

export {
  categoriesRef as categoriesUrl,
  categories,
  imagesRef,
  assetsRef,
  banners
}
