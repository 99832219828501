const apiKey = process.env.REACT_APP_MAILGUN_API_KEY
const DOMAIN = process.env.REACT_APP_MAILGUN_DOMAIN
const mailgun = require('mailgun-js')({ apiKey: apiKey, domain: DOMAIN })

const toMail = process.env.REACT_APP_TO_EMAIL

const list = mailgun.lists(`newsletter@${DOMAIN}`)

export {
  toMail,
  DOMAIN as domain,
  mailgun,
  list
}