// import logo from '../../assets/logo.png'
import { plural, timeToMins } from '../../utils/helpers'

const RecipeDetailsPrintComponent = ({ product }) => {
  const {
    recipe,
    cooktime,
    preptime,
    category,
    image,
    ingredients,
    ingredientsNumber,
    instructions,
    meal,
    nutrition,
    peoples
  } = product

  return (
    <section>
      {/* <div className="text-right">
        <img src={logo} style={{width: "100pt"}} alt="logo" />
      </div> */}
      <div className='mt-3'>
        <div
          className='d-flex justify-content-between align-items-start'
          style={{ fontSize: "32pt" }}
        >
          <h1
            style={{ color: "#d63230", fontSize: "40pt" }}>
            {recipe}
          </h1>
        </div>
        <div>
          {/* <p>
            Submited by: <span style={{ color: "#d63230" }}>{user}</span>
          </p> */}
          <div className='d-flex' style={{ fontSize: "16pt" }}  >
            <p className='text-left mr-4'>
              Prep Time
              <span className='pl-1 mr-2 d-inline text-primary'>
                {preptime ? timeToMins(preptime) : 'NA'}
              </span>
            </p>
            <p className='text-left mr-4'>
              Cook Time
              <span className='pl-1 mr-2 d-inline text-primary'>
                {cooktime ? timeToMins(cooktime) : 'NA'}
              </span>
            </p>
            <p className='text-left mr-4'>
              Category
              <span
                className='mr-2 text-capitalize'
                style={{ color: "#000" }}
              >
                <span style={{ color: "#d63230" }}> {category}</span>
              </span>
            </p>
            <p className='text-left mr-4'>
              <span className='mr-2' style={{ color: "#000" }}>
                For{" "}
                <span style={{ color: "#d63230", textTransform: "capitalize" }}>
                  {meal}
                </span>
              </span>
            </p>
          </div>
          <div className='d-flex' style={{ fontSize: "16pt" }}  >
            <p className='text-left mr-4'>
              {peoples &&
                <span className='mr-2' style={{ color: "#000" }}>
                  Feed{" "}
                  <span style={{ color: "#d63230", textTransform: "capitalize" }}>
                    {plural(peoples, "person", "peoples")}
                  </span>
                </span>
              }
            </p>
          </div>
          <div className='mt-3'>
            <img className="recipe-thumb" src={image} alt={recipe} />
          </div>
          <div className="page-break mt-5">
            <h2>{ingredientsNumber} Ingredients</h2>
            {/* <h2>Ingredients</h2> */}
            <ol
              className="mt-3"
            // style={{ marginTop: "16pt", marginLeft: "16pt" }}
            >
              {ingredients.map((ingredient, i) => (
                <li key={i}>
                  {ingredient.value ? (
                    <span>{ingredient.value}</span>
                  ) : (
                    <>
                      <span className="pl-2">{ingredient.qty}</span>
                      <span className="pl-1">{ingredient.qtySuffix}</span>
                      <span className="pl-2">{ingredient.name}</span>
                    </>
                  )}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
      <div className='mt-5'>
        <h2>Cooking Instructions</h2>
        <div dangerouslySetInnerHTML={{ __html: instructions }} />
      </div>
      <div className='mt-5'>
        <h2>Nutrition Facts</h2>
        <p className='ml-4'>{nutrition ? nutrition : 'No Facts Provided'}</p>
      </div>
    </section>
  )
}

export default RecipeDetailsPrintComponent
