// libraries
import { useState } from "react"
import { useHistory } from "react-router-dom"
import firebase from "../../firebase"
import { Modal, message } from 'antd'

// icons
import { FaTrash, FaPencilAlt, FaBan } from "react-icons/fa"
import { GoCheck } from "react-icons/go"

// components
import Loading from "../Loading"

const recipesDocument = firebase.firestore().collection("recipes")
// Points to the root reference
const storage = firebase.storage()
// var storageRef = firebase.storage().ref();

const AdminStatusItem = ({ data, status }) => {
  // const modalRef = useRef();
  // use state
  const [loading, setLoading] = useState(false)

  // use history
  const history = useHistory()

  const handleSwitch = (e, switchState) => {
    if (e.stopPropagation) e.stopPropagation()
    setLoading(true)
    recipesDocument
      .doc(data.uuid)
      .update({
        recommended: switchState ? false : true,
        // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        message.success("Recipe recommended status changed!")
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        message.error("An error has occurred! Please try again")
      })
  }

  const approveConfirm = (e, item) => {
    if (e.stopPropagation) e.stopPropagation()
    Modal.confirm({
      title: "Are you sure?",
      icon: <span className="anticon check"><GoCheck size={22} /></span>,
      content: "You want to approve this item?",
      centered: true,
      // onCancel: handleCancle,
      cancelText: "No",
      onOk: () => approveItem(item),
      okText: "Yes",
      // cancelButtonProps: {
      //   danger: true
      // },
      // okButtonProps: {
      //   danger: true
      // }
    })
  }

  // handle approve click
  const approveItem = (item) => {
    setLoading(true)

    recipesDocument
      .doc(item.uuid)
      .update({
        status: "approved",
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        message.success("Recipe status changed successfully!")
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        message.error("An error has occurred! Please try again")
      })
  }

  const rejectConfirm = (e, item) => {
    if (e.stopPropagation) e.stopPropagation()
    Modal.confirm({
      title: "Are you sure?",
      icon: <span className="anticon trash"><FaBan size={22} /></span>,
      content: "You want to reject this item?",
      centered: true,
      // onCancel: handleCancle,
      cancelText: "No",
      onOk: () => rejectItem(item),
      okText: "Yes",
      cancelButtonProps: {
        danger: true
      },
      okButtonProps: {
        danger: true
      }
    })
  }

  // handle reject click
  const rejectItem = (item) => {
    setLoading(true)
    recipesDocument
      .doc(item.uuid)
      .update({
        status: "rejected",
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        message.success("Recipe rejected!")
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        message.error("An error has occurred! Please try again")
      })
  }

  const deleteConfirm = (e, item) => {
    if (e.stopPropagation) e.stopPropagation()
    Modal.confirm({
      title: "Are you sure?",
      icon: <span className="anticon trash"><FaTrash size={22} /></span>,
      content: <>
        <div>You want to delete this item?</div>
        <small>After delete, Data can't be recovered.</small>
      </>,
      centered: true,
      // onCancel: handleCancle,
      cancelText: "No",
      onOk: () => deleteItem(item),
      okText: "Yes",
      cancelButtonProps: {
        danger: true
      },
      okButtonProps: {
        danger: true
      }
    })
  }

  // handle reject click
  const deleteItem = (item) => {
    // console.log("Deleted", data)
    // Points to 'images'
    // var imagesRef = storageRef.child(data.image);
    // Get metadata properties

    // Create a reference from a Google Cloud Storage URI
    // var gsReference = storage.refFromURL('gs://bucket/images/stars.jpg');

    // Create a reference from an HTTPS URL
    // Note that in the URL, characters are URL escaped!
    // console.log(data.image.search('firebasestorage'), data.image)
    if (item.image && item.image.search('firebasestorage') >= 0) {
      var httpsImageReference = storage.refFromURL(item.image)
      // httpsImageReference.getDownloadURL().then(res => {
      //   console.log(res);
      // });
      // httpsImageReference.getMetadata()
      //   .then((metadata) => {
      //     // Metadata now contains the metadata for 'images/forest.jpg'
      //     console.log(metadata)
      //   })
      //   .catch((error) => {
      //     // Uh-oh, an error occurred!
      //     console.log(error)
      //   });
    }

    setLoading(true)
    recipesDocument.doc(item.uuid).delete()
      .then(() => {
        message.success("Recipe deleted successfully!")
        if (item.image && item.image.search('firebasestorage') >= 0) {
          httpsImageReference.delete()
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        message.error("An error has occurred! Please try again")
      })
  }

  const editItem = (e, data) => {
    if (e.stopPropagation) e.stopPropagation()
    history.push(`/admin/edit/${data.category}/${data.uuid}`)
  }

  const viewItem = (e, data) => {
    history.push(`/admin/${data.category}/${data.uuid}`)
  }

  return (
    <div
      onClick={(e) => viewItem(e, data)}
      className='admin-status-item'
    >
      {loading && <Loading fixed={true} />}
      <div className='d-flex align-items-center admin-status-item-img-container'>
        <img src={data.image} alt={data.recipe} />
        <p>{data.recipe}</p>
      </div>
      <div
        className='d-flex align-items-center justify-content-center'
        style={{ flex: "1" }}
      >
        <label className='m-0 mr-2'>Recommended</label>
        <div onClick={(e) => handleSwitch(e, data.recommended)} className='ToggleSwitch'>
          <div className={data.recommended ? "knob switch-active" : "knob"} />
        </div>
      </div>
      <div className="item-action">
        {/* <div
            className='icon-wrapper edit'
            title='View'
            onClick={(e) => viewItem(e, data)}
          >
            <FaEye />
          </div> */}
        <div
          className='icon-wrapper edit'
          title='Edit'
          onClick={(e) => editItem(e, data)}
        >
          <FaPencilAlt />
        </div>

        {status === "approved" ? (
          <div
            className='icon-wrapper trash'
            title='Reject'
            onClick={(e) => rejectConfirm(e, data)}
          >
            <FaBan />
          </div>
        ) : status === "pending" ? (
          <>
            <div
              className='icon-wrapper check'
              title='Approve'
              onClick={(e) => approveConfirm(e, data)}
            >
              <GoCheck size={20} />
            </div>
            <div
              className='icon-wrapper trash'
              title='Reject'
              onClick={(e) => rejectConfirm(e, data)}
            >
              <FaBan />
            </div>
          </>
        ) : (
          <>
            <div
              className='icon-wrapper check'
              title='Approve'
              onClick={(e) => approveConfirm(e, data)}
            >
              <GoCheck size={20} />
            </div>
            <div
              className='icon-wrapper trash'
              title='Delete'
              onClick={(e) => deleteConfirm(e, data)}
            >
              <FaTrash />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AdminStatusItem
