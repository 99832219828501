import { useState } from "react"

const usePagination = (limit = 15) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pages, setPages] = useState([1])
  const [offset, setOffset] = useState(0)

  const calculatePages = (length) => {
    let tmpPages = []
    for (let i = 1; i <= Math.ceil(length / limit); i++) {
      tmpPages.push(i)
    }
    setPages(tmpPages)
  }

  const handlePage = (current) => {
    setCurrentPage(current)
    setOffset((current * limit) - limit)
  }

  return {
    offset,
    pages,
    currentPage,
    setCurrentPage,
    calculatePages,
    handlePage
  }
}

export default usePagination
