// libraries
import { useContext } from "react";
import { useHistory } from "react-router-dom";

// styles
import "../../styles/Admin.scss";

// context
import AdminContext from "../../context/admin";

// icons
import { FaSignOutAlt } from "react-icons/fa";

// components
import AdminSidebar from "../../components/Admin/AdminSidebar";

const Admin = ({ children }) => {
  // use context
  const { adminStatus, setAdminStatus } = useContext(AdminContext);

  // use history
  const history = useHistory();

  if (adminStatus == null) {
    history.push("/admin-login");
  }
  return (
    <div className='admin row m-0'>
      <AdminSidebar />
      <div className='admin-content'>
        <div className='admin-nav'>
          <h5>Admin Panel</h5>
          <FaSignOutAlt
            onClick={() => {
              localStorage.removeItem("admin");
              history.push("/admin-login");
              setAdminStatus(null);
            }}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Admin;
