import { useHistory } from "react-router-dom"

// icons
import {
  // BsArrowsMove,
  // BsPlusCircle,
  BsDashCircleFill,
  BsPlusCircleFill
} from "react-icons/bs"
import { timeToMins } from '../../utils/helpers'
import { FiClock } from 'react-icons/fi'
// import {
//   RiAddBoxFill, RiAddBoxLine,
//   RiAddCircleFill,
//   RiDragMove2Fill,
//   RiHeart3Fill, RiHeart3Line
// } from 'react-icons/ri';
import {
  // IoIosMenu,
  // IoMdRemoveCircle,
  IoMdMove
} from "react-icons/io"
import cx from 'classnames'
import { assetsRef } from '../../firebase/storage'

const MealPlanItem = ({ data, setShowDrawer, index, setDrawer, mealPlan, deleteConfirm, moveConfirm, dndMove }) => {
  // use history
  const history = useHistory()
  // const [draggable, setDragable] = useState(false)
  const image = data?.image === 'empty' ? `${assetsRef}/empty-dish.jpg` : data?.image

  const onDragStart = (ev) => {
    let card = ev.target?.closest('.meal-plan-card')
    let selectedItem = {
      uuid: card?.id,
      mealPlan: card?.getAttribute("data-mealplan"),
      day: card?.getAttribute("data-day")
    }
    // console.log("dragStart");
    // console.log("selectedItem", selectedItem)

    var dti = ev.dataTransfer.items
    if (dti === undefined || dti == null) {
      console.log("Browser does not support DataTransferItem interface")
      return
    }
    // console.log("dragSupported");
    // Change the source element's background color to signify drag has started
    // card?.classList.add("drag-selected")
    // Add the id of the drag source element to the drag data payload so
    // it is available when the drop event is fired
    ev.dataTransfer.setData("application/text", JSON.stringify(selectedItem))
    // Tell the browser both copy and move are possible
    // ev.dataTransfer.dropEffect = "move";
    ev.dataTransfer.effectAllowed = "move"
    // console.log(ev)
  }

  const onDragEnter = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    let image = ev.target?.closest('.meal-plan-card-image')
    // let card = image?.closest('.meal-plan-card')
    // let selectedItem = ev.dataTransfer.getData("application/text")
    // let destinationItem = {
    //   uuid: card?.id,
    //   mealPlan: card?.getAttribute("data-mealplan"),
    //   day: card?.getAttribute("data-day")
    // }

    // console.log("onDragEnter")
    // console.log("selectedItem", selectedItem)
    // console.log("destinationItem", destinationItem)
    // if (selectedItem.mealPlan === destinationItem.mealPlan) {
    image?.classList?.add("drag-entered")
    // }
    // console.log(ev.target.classList)
    // if (target?.classList?.contains('drag-selected')) return;
    // target?.classList?.add("drag-entered")
    // console.log(ev)
  }

  const onDragLeave = (ev) => {
    let image = ev.target?.closest('.meal-plan-card-image')
    // let card = image?.closest('.meal-plan-card')
    // let selectedItem = ev.dataTransfer.getData("application/text")
    // let destinationItem = {
    //   uuid: card?.id,
    //   mealPlan: card?.getAttribute("data-mealplan"),
    //   day: card?.getAttribute("data-day")
    // }

    // console.log("onDragLeave")
    // console.log("selectedItem", selectedItem)
    // console.log("destinationItem", destinationItem)
    // console.log("onDragLeave", dragged, target?.id, destination);
    image?.classList?.remove("drag-entered")
    // console.log(ev)
  }

  const onDragEnd = (ev) => {
    let image = ev.target?.closest('.meal-plan-card-image')
    // let card = image?.closest('.meal-plan-card')
    // let selectedItem = ev.dataTransfer.getData("application/text")
    // let destinationItem = {
    //   uuid: card?.id,
    //   mealPlan: card?.getAttribute("data-mealplan"),
    //   day: card?.getAttribute("data-day")
    // }

    // console.log("onDragEnd")
    // console.log("selectedItem", selectedItem)
    // console.log("destinationItem", destinationItem)

    image?.classList?.remove("drag-selected")
    // console.log(ev)
  }

  const onDrop = (ev) => {
    let image = ev.target?.closest('.meal-plan-card-image')
    let card = image?.closest('.meal-plan-card')
    let selectedItem = JSON.parse(ev.dataTransfer.getData("application/text"))
    let destinationItem = {
      uuid: card?.id,
      mealPlan: card?.getAttribute("data-mealplan"),
      day: card?.getAttribute("data-day")
    }

    // console.log("onDrop")
    // console.log("selectedItem", selectedItem)
    // console.log("destinationItem", destinationItem)
    image?.classList?.remove("drag-entered")

    document.getElementById(selectedItem?.uuid)?.classList?.remove("drag-selected")
    if (!selectedItem?.uuid && !destinationItem?.uuid) {
      return
    }
    if (selectedItem?.uuid === destinationItem?.uuid) {
      ev.preventDefault()
      return
    }
    if (selectedItem?.mealPlan === destinationItem?.mealPlan) {
      dndMove(data, parseInt(selectedItem.day), parseInt(destinationItem.day))
    }
    // console.log(ev)
  }

  const onDragOver = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
  }

  return (
    <div
      data-mealplan={mealPlan}
      data-day={index}
      id={data.uuid}
      // draggable={true}
      draggable={!data.empty}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
      onDragOver={onDragOver}
      className={cx("d-flex flex-column meal-plan-card", { [mealPlan]: true, "pointer": !data?.empty, "not-allowed": data?.empty })}
      onClick={() => !data?.empty && history.push(`/recipes/${data.category}/${data.uuid}`)}
    >
      <BsPlusCircleFill
        className="meal-plan-replace"
        onClick={(e) => {
          if (e.stopPropagation) e.stopPropagation()
          setShowDrawer(true)
          console.log(mealPlan, data.uuid, index, data.category)
          setDrawer(mealPlan, data.uuid, index, data.category)
        }}
      />
      {!data?.empty && (
        <BsDashCircleFill
          className="meal-plan-remove"
          onClick={(e) => {
            if (e.stopPropagation) e.stopPropagation()
            deleteConfirm && deleteConfirm(data, index)
          }}
        />
      )}
      {!data?.empty && (
        <IoMdMove
          className="meal-plan-move"
          onClick={(e) => {
            if (e.stopPropagation) e.stopPropagation()
            moveConfirm && moveConfirm(data, index)
            // setDragable && setDragable(!draggable)
          }}
          onDragStart={onDragStart}
        />
      )}
      <div
        className={cx("meal-plan-card-image", { "cursore-move": !data?.empty, "not-allowed": data?.empty })}
        style={{
          backgroundImage: `url('${image}')`,
        }}
      >
        <div className="meal-plan-card-time d-flex align-items-center">
          <FiClock className="drawer-item-clock" />
          <span>
            {timeToMins(data.cooktime)}
          </span>
        </div>
      </div>
      <p className="meal-plan-card-title">{data.recipe}</p>
    </div>
  )
}

export default MealPlanItem
