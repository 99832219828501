import React, { useState } from "react"
import { AiOutlineClose } from "react-icons/ai"
import MealDrawerItem from "./MealDrawerItem"

const MealPlanDrawer = ({ drawerRecipes, setShowDrawer, getRecipe }) => {
  const filterMenu = (meal) => {
    return drawerRecipes.recipes.filter((recipe) => recipe.meal === meal)
  }

  const filterCategory = (meal) => {
    return filterMenu(meal).filter(
      (recipe) => recipe.category === drawerRecipes.category
    )
  }

  const [tabIndex, setTabIndex] = useState(
    drawerRecipes.type === "breakfast"
      ? 1
      : drawerRecipes.type === "lunch"
        ? 2
        : drawerRecipes.type === "dinner"
          ? 3
          : "all"
  )

  const [recipes, setRecipes] = useState(filterCategory(drawerRecipes.type))

  const [mealType, setMealType] = useState(drawerRecipes.type)

  const toggleTab = (index) => {
    setTabIndex(index)
    const _mealType =
      index === 1
        ? "breakfast"
        : index === 2
          ? "lunch"
          : index === 3
            ? "dinner"
            : "all"
    setMealType(_mealType)
    setRecipes(
      _mealType !== "all" ? filterCategory(_mealType) : drawerRecipes.recipes
    )
  }

  return (
    <div className="drawer">
      <AiOutlineClose
        className="drawer-close"
        onClick={() => setShowDrawer(false)}
      />
      <div className="drawer-content">
        <div className="drawer-header">
          <h3>{drawerRecipes.category} Recipes</h3>
          <div className="drawer-nav">
            <div
              className="drawer-nav-item"
              onClick={() => toggleTab(1)}
              style={{
                color: tabIndex === 1 ? "#d63230" : "#6c757d",
                borderBottom: tabIndex === 1 ? "1px solid #d63230" : "none",
              }}
            >
              Breakfast
            </div>
            <div
              className="drawer-nav-item"
              onClick={() => toggleTab(2)}
              style={{
                color: tabIndex === 2 ? "#d63230" : "#6c757d",
                borderBottom: tabIndex === 2 ? "1px solid #d63230" : "none",
              }}
            >
              Lunch
            </div>
            <div
              className="drawer-nav-item"
              onClick={() => toggleTab(3)}
              style={{
                color: tabIndex === 3 ? "#d63230" : "#6c757d",
                borderBottom: tabIndex === 3 ? "1px solid #d63230" : "none",
              }}
            >
              Dinner
            </div>
            <div
              className="drawer-nav-item"
              onClick={() => toggleTab(4)}
              style={{
                color: tabIndex === 4 ? "#d63230" : "#6c757d",
                borderBottom: tabIndex === 4 ? "1px solid #d63230" : "none",
              }}
            >
              All
            </div>
          </div>
        </div>
        <div className="drawer-body row">
          {recipes.length > 0 ? (
            recipes.map((recipe, i) => (
              <div key={i} className="col-12 col-sm-6">
                <MealDrawerItem data={recipe} getRecipe={getRecipe} />
              </div>
            ))
          ) : (
            <div className="drawer-label">
              <span>
                No <strong>{mealType}</strong> Recipes Found For{" "}
                <strong>{drawerRecipes.category}</strong>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MealPlanDrawer
