import { FiClock } from 'react-icons/fi'

import { assetsRef } from '../firebase/storage'
import { timeToMins } from '../utils/helpers'
// style
import "../styles/Print.scss"

const MealPlanItem = ({ data }) => {
  // use history
  const image = data?.image === 'empty' ? `${assetsRef}/empty-dish.jpg` : data?.image

  return (
    <div
      className="flex-column print-meal-plan-card"
    >
      <div
        className="print-meal-plan-card-image"
        style={{
          backgroundImage: `url('${image}')`,
        }}
      >
        <div className="print-meal-plan-card-time d-flex align-items-center">
          <FiClock className="drawer-item-clock" />
          <span>
            {timeToMins(data.cooktime)}
          </span>
        </div>
      </div>
      <p className="print-meal-plan-card-title">{data.recipe}</p>
    </div>
  )
}

const MealPlanPrint = ({ mealPlan, weekTab }) => {

  return (
    <div className="print-meal-plan">
      <div className="print-meal-plan-side">
        <div className="print-meal-plan-item"></div>
        <div className="print-meal-plan-item">BreakFast</div>
        <div className="print-meal-plan-item">Lunch</div>
        <div className="print-meal-plan-item">Dinner</div>
      </div>
      <div className="print-meal-plan-main">
        <div className="print-meal-plan-main-row print-meal-plan-days">
          <div className="print-meal-row-heading">Sunday</div>
          <div className="print-meal-row-heading">Monday</div>
          <div className="print-meal-row-heading">Tuesday</div>
          <div className="print-meal-row-heading">Wednesday</div>
          <div className="print-meal-row-heading">Thursday</div>
          <div className="print-meal-row-heading">Friday</div>
          <div className="print-meal-row-heading">Saturday</div>
        </div>
        <div className="print-meal-plan-main-row">
          {mealPlan[weekTab]?.breakfast?.map((recipe, i) => (
            <div key={i} className="print-meal-row-item my-1">
              <MealPlanItem
                data={recipe}
              />
            </div>
          ))}
        </div>
        <div className="print-meal-plan-main-row">
          {mealPlan[weekTab]?.lunch?.map((recipe, i) => (
            <div key={i} className="print-meal-row-item my-1">
              <MealPlanItem
                data={recipe}
              />
            </div>
          ))}
        </div>
        <div className="print-meal-plan-main-row">
          {mealPlan[weekTab]?.dinner?.map((recipe, i) => (
            <div key={i} className="print-meal-row-item my-1">
              <MealPlanItem
                data={recipe}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MealPlanPrint
