// libraries
import { useState, useEffect, useContext, useRef, useReducer } from "react"
import { Link, Redirect } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Modal } from 'antd'
import cx from "classnames"
import { useReactToPrint } from "react-to-print"

import firebase from "../firebase"
// context
import RecipesContext from "../context/recipes"
import UserContext from "../context/user"
import LoaderContext from "../context/loader"

// // style
import "../styles/MealPlan.scss"

// components
import Hero from "../components/Hero"
// import MealPlanItem from "../components/MealPlan/MealPlanItem";
import MealPlanItem from "../components/MealPlan/MealPlanItem-DnD"
import MealPlanDrawer from "../components/MealPlan/MealPlanDrawer"
import MealPlanPrint from './MealPlanPrint'

// assests
import Loading from "../assets/hour_glass.gif"
import { days } from '../data/data'
// import { customPrint } from "../utils/helpers"
import { defaulMealPlan, planSize, reducer } from "../data/mealPlanTemplate"
import { weekLabel } from "../utils/labels"

const SavedMealPlan = () => {
  // use context
  const { recipes } = useContext(RecipesContext)
  const { loading, user, savedMealPlan } = useContext(UserContext)
  const { setLoading } = useContext(LoaderContext)
  const { addToast } = useToasts()
  const [mealPlan, dispatch] = useReducer(reducer, defaulMealPlan)

  // state
  const [shouldUpdate, setUpdate] = useState("")
  const [showDrawer, setShowDrawer] = useState(false)
  const [drawerRecipes, setDrawerRecipes] = useState({})
  const [weekTab, setWeekTab] = useState("weekOne")
  const [loadingBlock, setLoadingBlock] = useState(true)
  const [img, setImage] = useState(true)
  const [alertMsg, setAlertMsg] = useState("Retriving Meal Plans......")

  // reference
  // let moveModal = null
  const moveModal = Modal
  const componentRef = useRef()

  useEffect(() => {
    if (user !== null) {
      // firebase
      // .firestore()
      // .collection("users")
      // .doc(user.uid)
      // .collection("mealPlan")
      // .onSnapshot((snapshot) => {
      //   let mealplanTmp = [];
      //   snapshot.forEach(doc => {
      //     mealplanTmp.push({ ...doc.data(), week: doc.id })
      //   });
      //   setMealPlan(mealplanTmp);
      //   console.log("mealplan", mealplanTmp)
      //   // console.log("favData", favData);
      // });
      if (savedMealPlan) {
        let weekOnePlan = savedMealPlan?.weekOne?.breakfast?.length > 0
        let weekTwoPlan = savedMealPlan?.weekTwo?.breakfast?.length > 0
        let weekThreePlan = savedMealPlan?.weekThree?.breakfast?.length > 0
        let weekFourPlan = savedMealPlan?.weekFour?.breakfast?.length > 0
        let hasMealPlan = weekOnePlan || weekTwoPlan
        if (hasMealPlan) {
          // setAlertMsg("Retriving Meal Plan");
          setImage(false)
          setLoadingBlock(false)

          if (weekOnePlan) {
            dispatch({
              type: "weekOne",
              payload: savedMealPlan?.weekOne
            })
          }
          if (weekTwoPlan) {
            dispatch({
              type: "weekTwo",
              payload: savedMealPlan?.weekTwo
            })
          }
          if (weekThreePlan) {
            dispatch({
              type: "weekThree",
              payload: savedMealPlan?.weekThree
            })
          }
          if (weekFourPlan) {
            dispatch({
              type: "weekFour",
              payload: savedMealPlan?.weekFour
            })
          }
        }
      }
    } else {
      setAlertMsg("No Meal Plan found")
    }
    // console.log("savedMealPlan", savedMealPlan)
  }, [user, savedMealPlan])

  useEffect(() => {
    if (!loading) {
      if (!savedMealPlan?.weekOne?.breakfast?.length) {
        setAlertMsg("No Meal Plan found")
        setImage(false)
        setLoadingBlock(true)
      }
    }
  }, [loading, savedMealPlan])

  console.log(savedMealPlan)
  const setDrawerRecipesList = (meal, uuid, index, catg) => {
    let drawerContent = {}
    drawerContent.recipes = recipes
    drawerContent.type = meal
    drawerContent.uuid = uuid
    drawerContent.index = index
    drawerContent.category = catg
    setDrawerRecipes(drawerContent)
  }

  const getDrawerRecipe = (data) => {
    let type = drawerRecipes.type
    let payload = {
      ...mealPlan[weekTab],
      [type]: mealPlan[weekTab][type].map((item, i) =>
        drawerRecipes.uuid === item.uuid ? data : item
      ),
    }
    dispatch({
      type: weekTab,
      payload
    })
    setUpdate(weekTab)
    setShowDrawer(false)
  }

  const uploadMealPlanToFS = (weekName) => {
    if (user !== null) {
      // let payload = mealPlan
      // let payload = { [weekName]: mealPlan[weekName] }
      setLoading(true)
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("mealPlan")
        .doc(weekName)
        .set(mealPlan[weekName], { merge: true })
        // .set(payload, { merge: true })
        // .set(
        //   {
        //     weekOne: {
        //       breakfast: FirstWeek.breakfast,
        //       lunch: FirstWeek.lunch,
        //       dinner: FirstWeek.dinner,
        //     },
        //     weekTwo: {
        //       breakfast: SecondWeek.breakfast,
        //       lunch: SecondWeek.lunch,
        //       dinner: SecondWeek.dinner,
        //     },
        //   },
        //   { merge: true }
        // )
        .then(() => {
          console.log("meal plan updated")
          addToast("Meal plan updated", {
            appearance: "success",
          })
          setUpdate("")
          setAlertMsg("Retriving Meal Plan......")
        })
        .catch((e) => {
          setAlertMsg(e.message)
          addToast(e.message, {
            appearance: "error",
          })
          console.log("meal plan error", e.message)
        })
        .finally(() => {
          setLoading(false)
          // setLoadingBlock(false);
        })
    } else {
      console.log("Please Log in First")
      addToast("Please Log in First", {
        appearance: "warning",
      })
    }
  }

  const deleteMealPlanConfirm = (weekName) => {
    Modal.confirm({
      title: "Are you sure?",
      content: "You want to remove the meal plan?",
      centered: true,
      // onCancel: handleCancle,
      cancelText: "No",
      onOk: () => removeMealPlanToFS(weekName),
      okText: "Yes",
      cancelButtonProps: {
        danger: true
      },
      okButtonProps: {
        danger: true
      }
    })
  }


  const removeMealPlanToFS = (weekName) => {
    if (user !== null) {
      // let payload = mealPlan
      setLoading(true)
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("mealPlan")
        .doc(weekName)
        .delete()
        .then(() => {
          console.log("meal plan removed")
          addToast("Meal plan removed", {
            appearance: "success",
          })
          dispatch({
            type: weekName,
            payload: {
              breakfast: [],
              lunch: [],
              dinner: [],
            }
          })
          setWeekTab("weekOne")
          setUpdate("")
          setAlertMsg("Retriving Meal Plan......")
        })
        .catch((e) => {
          setAlertMsg(e.message)
          addToast(e.message, {
            appearance: "error",
          })
          console.log("meal plan error", e.message)
        })
        .finally(() => {
          setLoading(false)
          // setLoadingBlock(false);
        })
    } else {
      console.log("Please Log in First")
      addToast("Please Log in First", {
        appearance: "warning",
      })
    }
  }

  const deleteConfirm = (item, index) => {
    Modal.confirm({
      title: "Are you sure?",
      content: "You want to remove the item from meal plan?",
      centered: true,
      // onCancel: handleCancle,
      cancelText: "No",
      onOk: () => confirmDelete(item, index),
      okText: "Yes",
      cancelButtonProps: {
        danger: true
      },
      okButtonProps: {
        danger: true
      }
    })
  }

  const confirmDelete = (item, index) => {
    const newMeal = mealPlan[weekTab][item.meal].map((i, ind) => ind === index ? {
      recipe: "Empty",
      image: 'empty',
      cooktime: "00:00",
      preptime: "00:00",
      category: item.category,
      meal: item.meal,
      uuid: `${weekTab}-${index}-${item.meal}-${item.category}-0000-0000-0000`,
      empty: true
    } : i)
    // console.log("newMeal", newMeal)
    let payload = {
      ...mealPlan[weekTab],
      [item.meal]: newMeal
    }
    // console.log("payload", payload)
    dispatch({
      type: weekTab,
      payload
    })
    setUpdate(weekTab)
    // console.log("handleOk", item)
  }

  const moveConfirm = (item, index) => {
    const totalMeals = mealPlan[weekTab][item.meal]?.length
    moveModal.confirm({
      title: "Select Day to move item",
      icon: null,
      content: <>
        <select
          className="custom-select"
          // onChange={(e) => setNewDay(parseInt(e.target.value)) }
          onChange={(e) => confirmMove(item, index, parseInt(e.target.value))}
        >
          <option value="">Select Day</option>
          {days.map((day, i) => (i < totalMeals &&
            <option value={day.value} key={day.value} disabled={day.value === index}>{day.label}</option>
          ))}
        </select>
      </>,
      centered: true,
      okButtonProps: {
        style: {
          display: "none",
        },
      },
      cancelButtonProps: {
        style: {
          display: "none",
        },
      },
      closable: true
    })
  }

  const confirmMove = (sourceItem, day, newDay) => {
    moveModal.destroyAll()
    // console.log(newDay)
    const destinationItem = mealPlan[weekTab][sourceItem.meal].filter((item, i) => newDay === i)[0]
    const updatedMeal = mealPlan[weekTab][sourceItem.meal].map((item, i) => newDay === i ? sourceItem : item)
    const newMeal = updatedMeal.map((i, ind) => day === ind ? destinationItem : i)
    // console.log("movedMeal", newMeal)
    let payload = {
      ...mealPlan[weekTab],
      [sourceItem.meal]: newMeal
    }
    // console.log("payload", payload)
    dispatch({
      type: weekTab,
      payload
    })
    setUpdate(weekTab)
    // console.log("handleOk", item)
  }

  const dndMove = (destinationItem, day, newDay) => {
    console.log(destinationItem, day, newDay)
    const sourceItem = mealPlan[weekTab][destinationItem.meal].filter((item, i) => day === i)[0]
    const updatedMeal = mealPlan[weekTab][destinationItem.meal].map((item, i) => day === i ? destinationItem : item)
    const newMeal = updatedMeal.map((item, i) => newDay === i ? sourceItem : item)

    console.log("movedMeal", newMeal)
    let payload = {
      ...mealPlan[weekTab],
      [destinationItem.meal]: newMeal
    }
    // console.log("payload", payload)
    dispatch({
      type: weekTab,
      payload
    })
    setUpdate(weekTab)
  }

  // const handleCancle = () => {
  //   console.log("handleCancle")
  // }

  // const handlePrint = () => {
  //   customPrint(componentRef.current, "Plan Shop Cook Meal Plan")
  // }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Plan Shop Cook Meal Plan",
  })

  if (!user) {
    addToast("Please log in first!", {
      appearance: "error",
    })
    return <Redirect to='/' />
  }

  return (
    <>
      <Hero
        // image={bg}
        title={"Saved Meal Plans"}
        breadName="Saved Meal Plans"
        singleProduct={false}
      />
      {loadingBlock ? (
        <div className="meal-plan-loading">
          {img && <img src={Loading} alt="loading" />}
          <h4>{alertMsg}</h4>
        </div>
      ) : (
        <>
          {planSize(mealPlan) > 1 && (
            <div className="meal-plan-tabs">
              {mealPlan["weekOne"]?.breakfast.length > 0 && (
                <div
                  className={cx("tab", { active: weekTab === "weekOne" })}
                  onClick={() => {
                    setWeekTab("weekOne")
                  }}
                >
                  First Week
                </div>
              )}
              {mealPlan["weekTwo"]?.breakfast.length > 0 && (
                <>
                  <hr />
                  <div
                    className={cx("tab", { active: weekTab === "weekTwo" })}
                    onClick={() => {
                      setWeekTab("weekTwo")
                      if (mealPlan["weekTwo"]?.breakfast?.length === 0) {
                        setLoadingBlock(true)
                      }
                    }}
                  >
                    Second Week
                  </div>
                </>
              )}
              {mealPlan["weekThree"]?.breakfast.length > 0 && (
                <>
                  <hr />
                  <div
                    className={cx("tab", { active: weekTab === "weekThree" })}
                    onClick={() => {
                      setWeekTab("weekThree")
                      if (mealPlan["weekThree"]?.breakfast?.length === 0) {
                        setLoadingBlock(true)
                      }
                    }}
                  >
                    Third Week
                  </div>
                </>
              )}
              {mealPlan["weekFour"]?.breakfast.length > 0 && (
                <>
                  <hr />
                  <div
                    className={cx("tab", { active: weekTab === "weekFour" })}
                    onClick={() => {
                      setWeekTab("weekFour")
                      if (mealPlan["weekFour"]?.breakfast?.length === 0) {
                        setLoadingBlock(true)
                      }
                    }}
                  >
                    Fourth Week
                  </div>
                </>
              )}
            </div>
          )}
          <section className="meal-wrapper my-5">
            <div className="mx-3">
              <div className="mb-2">
                {planSize(mealPlan) > 1 ? (
                  <>
                    {shouldUpdate === weekTab && (
                      <button
                        className="btn btn-primary mr-2"
                        onClick={() => {
                          setAlertMsg("Saving Meal Plan")
                          uploadMealPlanToFS(weekTab)
                        }}
                      >
                        {`Update ${weekLabel(weekTab)} Meal Plan`}
                      </button>
                    )}
                    {weekTab !== "weekOne" && (
                      <button
                        className="btn btn-warning mr-2"
                        onClick={() => {
                          setAlertMsg("Removing Meal Plan")
                          // removeMealPlanToFS(weekTab)
                          deleteMealPlanConfirm(weekTab)
                        }}
                      >
                        {`Remove ${weekLabel(weekTab)} Meal Plan`}
                      </button>
                    )}
                    <button
                      className="btn btn-secondary mr-2"
                      onClick={() => handlePrint(weekTab)}
                    >
                      {`Print ${weekLabel(weekTab)} Meal Plan`}
                    </button>
                  </>
                ) : (
                  <>
                    {shouldUpdate === "weekOne" && (
                      <button
                        className="btn btn-primary mr-2"
                        onClick={() => {
                          setAlertMsg("Saving Meal Plan")
                          uploadMealPlanToFS("weekOne")
                        }}
                      >
                        Update Meal Plan
                      </button>
                    )}
                    {/* <button
                        className="btn btn-warning mr-2"
                        onClick={() => {
                          setAlertMsg("Removing Meal Plan")
                          // removeMealPlanToFS("weekOne")
                          deleteMealPlanConfirm("weekOne")
                        }}
                      >
                        Remove Meal Plan
                      </button> */}
                    <button
                      className="btn btn-secondary mr-2"
                      onClick={() => handlePrint()}
                    >
                      Print Meal Plan
                    </button>
                  </>
                )}
                {planSize(mealPlan) !== 4 && (
                  <Link to='/meal-plan' className='btn btn-primary'>
                    Generate More Meal Plan
                  </Link>
                )}
              </div>
              <div>
                <div className="meal-plan">
                  <div className="meal-plan-side">
                    <div className="meal-plan-item"></div>
                    <div className="meal-plan-item">BreakFast</div>
                    <div className="meal-plan-item">Lunch</div>
                    <div className="meal-plan-item">Dinner</div>
                  </div>
                  <div className="meal-plan-main">
                    <div className="meal-plan-main-row meal-plan-days">
                      <div className="meal-row-heading">Sunday</div>
                      <div className="meal-row-heading">Monday</div>
                      <div className="meal-row-heading">Tuesday</div>
                      <div className="meal-row-heading">Wednesday</div>
                      <div className="meal-row-heading">Thursday</div>
                      <div className="meal-row-heading">Friday</div>
                      <div className="meal-row-heading">Saturday</div>
                    </div>
                    <div className="meal-plan-main-row">
                      {mealPlan[weekTab]?.breakfast?.map((recipe, i) => (
                        <div key={i} className="meal-row-item my-1">
                          <MealPlanItem
                            data={recipe}
                            setShowDrawer={setShowDrawer}
                            index={i}
                            setDrawer={setDrawerRecipesList}
                            moveConfirm={moveConfirm}
                            dndMove={dndMove}
                            deleteConfirm={deleteConfirm}
                            mealPlan="breakfast"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="meal-plan-main-row">
                      {mealPlan[weekTab]?.lunch?.map((recipe, i) => (
                        <div key={i} className="meal-row-item my-1">
                          <MealPlanItem
                            data={recipe}
                            setShowDrawer={setShowDrawer}
                            index={i}
                            setDrawer={setDrawerRecipesList}
                            moveConfirm={moveConfirm}
                            dndMove={dndMove}
                            deleteConfirm={deleteConfirm}
                            mealPlan="lunch"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="meal-plan-main-row">
                      {mealPlan[weekTab]?.dinner?.map((recipe, i) => (
                        <div key={i} className="meal-row-item my-1">
                          <MealPlanItem
                            data={recipe}
                            setShowDrawer={setShowDrawer}
                            index={i}
                            setDrawer={setDrawerRecipesList}
                            moveConfirm={moveConfirm}
                            dndMove={dndMove}
                            deleteConfirm={deleteConfirm}
                            mealPlan="dinner"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="print-container" ref={componentRef}>
                  <MealPlanPrint mealPlan={mealPlan} weekTab={weekTab} />
                </div>

              </div>
            </div>
            {showDrawer && (
              <MealPlanDrawer
                drawerRecipes={drawerRecipes}
                setShowDrawer={setShowDrawer}
                getRecipe={getDrawerRecipe}
              />
            )}
          </section>
        </>
      )}
    </>
  )
}

export default SavedMealPlan
