const weekLabel = (week) => {
  switch (week) {
    case "weekOne":
      return "First Week"
    case "weekTwo":
      return "Second Week"
    case "weekThree":
      return "Third Week"
    case "weekFour":
      return "Forth Week"
    default:
      return ""
  }
}

export {
  weekLabel
}