// libraries
import { Link } from "react-router-dom";

import { categories } from "../../firebase/storage";

const Categories = () => {
  return (
    <section className='ftco-section ftco-category ftco-no-pt cat-section mt-5' >
      <div className='container'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='row'>
              <div
                className='col-md-6 order-md-last align-items-center my-3 my-md-0 d-flex px-0  mx-3 mx-md-0  category'
                style={{
                  backgroundImage: `url(${categories.category})`,
                  backgroundColor: 'rgba(0,0,0, 0.18)'
                }}
              >
                <div className='category-wrap-2 img align-items-center d-flex h-100'>
                  <div className='text text-center my-3 my-md-0'>
                    <h2>Categories</h2>
                    <p>Enjoy planning and cooking based on your diet</p>
                    <Link to='/recipes' className='btn btn-primary'>
                      Recipes
                    </Link>
                    <h5 className='m-0 my-2 categories-or-text'>OR</h5>
                    <Link to='/meal-plan' className='btn btn-primary'>
                      Generate Meal Plan
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <Link
                  to='/recipes'
                  onClick={() => localStorage.setItem("category", "vegetarian")}
                  params={{ categoryName: "Vegetarian" }}
                  className='category-wrap img mb-4 d-flex align-items-end'
                  style={{
                    backgroundImage: `url(${categories.vegetarian})`,
                  }}
                >
                  <div className='text px-3 py-1 categories-title'>
                    <h2 className='mb-0'>
                      <p className='text-white home-category-name'>
                        Vegetarian
                      </p>
                    </h2>
                  </div>
                </Link>
                <Link
                  to='/recipes'
                  onClick={() =>
                    localStorage.setItem("category", "pescatarian")
                  }
                  className='category-wrap my-4 my-md-0 img d-flex align-items-end'
                  style={{
                    backgroundImage: `url(${categories.pescatarian})`,
                  }}
                >
                  <div className='text px-3 py-1 categories-title'>
                    <h2 className='mb-0'>
                      <p className='text-white home-category-name'>
                        Pescatarian
                      </p>
                    </h2>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <Link
              to='/recipes'
              onClick={() => localStorage.setItem("category", "vegan")}
              className='category-wrap img mb-4 d-flex align-items-end'
              style={{
                backgroundImage: `url(${categories.vegan})`,
              }}
            >
              <div className='text px-3 py-1 categories-title'>
                <h2 className='mb-0'>
                  <p className='text-white home-category-name'>Vegan</p>
                </h2>
              </div>
            </Link>
            <Link
              to='/recipes'
              onClick={() => localStorage.setItem("category", "keto")}
              className='category-wrap img mb-4 d-flex align-items-end'
              style={{
                backgroundImage: `url(${categories.keto})`,
              }}
            >
              <div className='text px-3 py-1 categories-title'>
                <h2 className='mb-0'>
                  <p className='text-white home-category-name'>Keto</p>
                </h2>
              </div>
            </Link>
          </div>
          <div className='col-md-4'>
            <Link
              to='/recipes'
              onClick={() => localStorage.setItem("category", "mediterranean")}
              className='category-wrap img mb-4 d-flex align-items-end'
              style={{
                backgroundImage: `url(${categories.mediterranean})`,
              }}
            >
              <div className='text px-3 py-1 categories-title'>
                <h2 className='mb-0'>
                  <p className='text-white home-category-name'>Mediterranean</p>
                </h2>
              </div>
            </Link>
          </div>
          <div className='col-md-4 p-md-0'>
            <Link
              to='/recipes'
              onClick={() => localStorage.setItem("category", "kids")}
              className='category-wrap img mb-4 d-flex align-items-end'
              style={{
                backgroundImage: `url(${categories.kids})`,
              }}
            >
              <div className='text px-3 py-1 categories-title'>
                <h2 className='mb-0'>
                  <p className='text-white home-category-name'>Kids</p>
                </h2>
              </div>
            </Link>
          </div>
          <div className='col-md-4'>
            <Link
              to='/recipes'
              onClick={() => localStorage.setItem("category", "healthy")}
              className='category-wrap img mb-4 d-flex align-items-end '
              style={{
                backgroundImage: `url(${categories.healthy})`,
              }}
            >
              <div className='text px-3 py-1 categories-title'>
                <h2 className='mb-0'>
                  <p className='text-white home-category-name'>Healthy</p>
                </h2>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Categories;
