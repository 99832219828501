// libraries
import ReactDOM from "react-dom"

// styles
import "./index.css"

// components
import App from "./App"

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById("root"),
)
