//Import Libraires
import React, { createContext, useState, useEffect } from "react";

//Import Firebase
import firebase from "../firebase";

//Create Context
const UserContext = createContext();
export const useAuth = () => UserContext

// firestore
const userDocument = firebase.firestore().collection("users");
const flaggedDocument = firebase.firestore().collection("flagged");
// const myReviews = firebase.firestore().collectionGroup("reviews");
// const userFavorite = firebase.firestore().collectionGroup("favorites");

export const UserProvider = ({ children }) => {
  //User State Values
  const [user, setUser] = useState(null);
  const [favorites, setFavorite] = useState([]);
  const [flagged, setFlagged] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [savedMealPlan, setSavedMealPlans] = useState({});

  // Initializing FireStore User Document
  useEffect(() => {
    const id = firebase.auth().currentUser
      ? firebase.auth().currentUser.uid
      : null;
    if (id) {
      setLoading(true)
      userDocument.doc(id)
        .onSnapshot((documentSnapshot) => {
          const userData = documentSnapshot.data();
          setUserData(userData);
          setLoading(false);
        });

      userDocument.doc(id)
        .collection("favorites")
        .onSnapshot((snapshot) => {
          let favData = [];
          snapshot.forEach(doc => {
            favData.push(doc.id)
          });
          setFavorite(favData);
          // console.log("favData", favData);
        });

      userDocument.doc(id)
        .collection("mealPlan")
        .onSnapshot((snapshot) => {
          let mealplanTmp = [];
          snapshot.forEach(doc => {
            mealplanTmp[doc.id] = doc.data()
            // mealplanTmp.push({ ...doc.data(), week: doc.id })
          });
          setSavedMealPlans(mealplanTmp);
          // console.log("mealplan", mealplanTmp)
          // console.log("favData", favData);
        });

      flaggedDocument
        .where("uid", "==", id)
        .onSnapshot((snapshot) => {
          let flaggedData = [];
          snapshot.forEach(doc => {
            flaggedData.push(doc.data().recipeid)
            // flaggedData.push(doc.id)
          });
          setFlagged(flaggedData);
          // console.log("flaggedData", flaggedData);
        });

      // userDocument.doc(id)
      //   .collection("flagged")
      //   .onSnapshot((snapshot) => {
      //     let flaggedData = [];
      //     snapshot.forEach(doc => {
      //       // flaggedData.push(doc.data().recipeid)
      //       flaggedData.push(doc.id)
      //     });
      //     setFlagged(flaggedData);
      //     console.log("flaggedData", flaggedData);
      //   });

      // myReviews.where("uid", "==", id)
      //   .onSnapshot((snapshot) => {
      //   let reviewData = [];
      //   snapshot.forEach(doc => {
      //     reviewData.push(doc.data().recipeid)
      //     // reviewData.push(doc.id)
      //   });
      //   setFlagged(reviewData);
      //   console.log("reviewData", reviewData);
      // })
    }
    // console.log("user", user)
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        savedMealPlan,
        userData,
        user,
        setUser,
        loading,
        setLoading,
        favorites,
        setFavorite,
        flagged,
        setFlagged,

        // Forgot Password Method
        forgotPassword: async (Email) => {
          setLoading(true);
          firebase
            .auth()
            .sendPasswordResetEmail(Email)
            .then(function (user) {
              setLoading(false);
              return true;
            })
            .catch((err) => {
              return false;
            });
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
