export function approvedRecipes(data) {
  return data.filter((item) => {
    return item.status === "approved"
  })
}

export function filterNewRecipes(data, limit = 16) {
  return data.filter((item) => {
    return item.status === "approved" && item.createdAt
  })
    .reverse()
    .slice(0, limit)
}

export function recommendedRecipes(data) {
  return data.filter((item) => {
    return item.recommended === true
  })
}

export function categoryRecipes(data, name) {
  if (name === "all") {
    return data
  } else {
    return data.filter((item) => {
      return item.category === name
    })
  }
}

export function mealRecipes(data, name) {
  if (name === "all") {
    return data
  } else {
    return data.filter((item) => {
      return item.meal === name
    })
  }
}

export function statusRecipes(data, name) {
  return data.filter((item) => {
    return item.status === name
  })
}

export function getSingleRecipe(data, id) {
  return data.find((item) => item.uuid === id)
}

export function getFavoriteRecipe(data, ids = []) {
  return data.filter((item) => ids.includes(item.uuid))
}

export function getCategoryFromLocalStorage() {
  return localStorage.getItem("category")
    ? localStorage.getItem("category")
    : "all"
}

export function getFromLocalStorage(name, value = "") {
  return localStorage.getItem(name)
    ? localStorage.getItem(name)
    : value
}

export function setToLocalStorage(name, value = "") {
  return localStorage.setItem(name, value)
}

export function timeToMins(time = '00:00') {
  const [hh, mm] = time.split(":")
  if (time === '00:00') {
    return time
  }
  let mins = ''
  if (parseInt(hh) > 0) {
    mins = `${hh}h `
  }
  if (parseInt(mm) > 0) {
    mins += `${mm} min `
  }
  return mins
}

export function plural(count, singular, plural) {
  if (count === 1 || count === '1') {
    return `${count} ${singular}`
  } else if (count > 1) {
    return `${count} ${plural}`
  }
  return count
}

export const customPrint = (content, title = '') => {
  // const content = componentRef.current //document.getElementById(elementId)
  const id = `Print-document-${Math.random() * 100}`
  const iframe = document.createElement('iframe')
  // iframe.setAttribute('title', "Plan Shop Cook")
  iframe.setAttribute('id', id)
  const styles = document.getElementsByTagName("style")
  const cStyles = document.createElement("style")
  for (var i = 0; i < styles.length; i++) {
    cStyles.innerHTML += styles[i].innerHTML
  }
  iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')

  document.body.appendChild(iframe)
  const printContainer = iframe.contentWindow
  printContainer.document.open()
  printContainer.document.write(content.innerHTML)
  printContainer.document.getElementsByTagName("head")[0].append(cStyles)
  printContainer.document.title = title
  printContainer.focus()
  printContainer.print()
  printContainer.document.close()
  // document.getElementById(id).remove()
}

// export const handlePrint = (title = '', uniqueIframeId = '') => {
//   if (uniqueIframeId === '') {

//   }
//   const content = componentRef.current //document.getElementById(elementId)
//   let pri
//   if (document.getElementById(uniqueIframeId)) {
//     pri = document.getElementById(uniqueIframeId).contentWindow
//   } else {
//     const iframe = document.createElement('iframe')
//     // iframe.setAttribute('title', "Plan Shop Cook")
//     // iframe.setAttribute('id', "psc")
//     iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
//     document.body.appendChild(iframe)
//     pri = iframe.contentWindow
//   }
//   pri.document.open()
//   pri.document.write(content.innerHTML)
//   pri.document.title = "Plan Shop Cook Title"
//   pri.focus()
//   pri.print()
//   pri.document.close()
// }


export const getParameterByName = (name, url = window.location.href) => {
  // eslint-disable-next-line
  name = String(name).replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
