import RateElement from "./RateElement"

const ReviewBlock = ({ review }) => {
  // console.log(review)
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <b className="pr-3">{review?.title}</b>
          <small className="text-right text-muted text-sm">By {review?.author}</small>
        </div>
        <RateElement
          defaultValue={review?.rating}
          readonly
          size={14}
        />
      </div>
      <p>{review?.content}</p>
      <hr />
    </>
  )
}

export default ReviewBlock
