const UserLoading = ({ fixed }) => {
  return (
    <div id='ftco-loader' className={`show ${fixed && "ftco-loader-absolute"}`}>
      <svg className='circular' width='48px' height='48px'>
        <circle
          className='path-bg'
          cx='24'
          cy='24'
          r='22'
          fill='none'
          strokeWidth='4'
          stroke='#eeeeee'
        />
        <circle
          className='path'
          cx='24'
          cy='24'
          r='22'
          fill='none'
          strokeWidth='4'
          strokeMiterlimit='10'
          stroke='#d63230'
        />
      </svg>
    </div>
  );
};

export default UserLoading;
