// styles
import "../styles/Home.scss"

// components
import Hero from "../components/Home/Hero"
// import Coaches from "../components/Home/Coaches";
import Categories from "../components/Home/Categories"
import NewRecipes from "../components/Home/NewRecipes"
// import RecomendedRecipes from "../components/Home/RecomendedRecipes"
import Newsletter from "../components/Newsletter"
import { useEffect } from "react"
import { setPageView } from "../seo"
// import HomeAd from "../components/Home/HomeAd";

const Home = () => {

  useEffect(() => {
    setPageView("Home")
  }, [])

  return (
    <div>
      <Hero />
      {/* <Coaches /> */}
      <Categories />
      <NewRecipes />
      {/* <RecomendedRecipes /> */}
      {/* <HomeAd /> */}
      <Newsletter />
    </div>
  )
}

export default Home
