import { Rate } from "antd"
import React from 'react'

const RateElement = ({
  defaultValue,
  onChange,
  readonly = false,
  size = 20
}) => {
  return (
    <div>
      <Rate
        allowHalf
        // defaultValue={defaultValue}
        value={defaultValue}
        onChange={onChange}
        disabled={readonly}
        style={{ fontSize: size }}
      />
    </div>
  )
}

export default RateElement
