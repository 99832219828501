import React, { createContext, useState } from "react"

const LoaderContext = createContext()

export const LoaderProvider = ({ children }) => {

  const [loading, setLoading] = useState(false)
  const fixed = true

  return (
    <LoaderContext.Provider
      value={{
        loading,
        setLoading
      }}>
      {loading && (
        <div id='ftco-loader' className={`show ${fixed && "ftco-loader-absolute"}`}>
          <svg className='circular' width='48px' height='48px'>
            <circle
              className='path-bg'
              cx='24'
              cy='24'
              r='22'
              fill='none'
              strokeWidth='4'
              stroke='#eeeeee'
            />
            <circle
              className='path'
              cx='24'
              cy='24'
              r='22'
              fill='none'
              strokeWidth='4'
              strokeMiterlimit='10'
              stroke='#d63230'
            />
          </svg>
        </div>
      )}
      {children}
    </LoaderContext.Provider>
  )
}

export default LoaderContext
