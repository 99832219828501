// libraries
import { useState, useContext, useEffect } from "react"
import { Redirect, useHistory } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import firebase from "../firebase"
import CKEditor from "ckeditor4-react"
import { v4 as uuidv4 } from "uuid"

import ImgCrop from "antd-img-crop"
import { TimePicker, Upload } from "antd"
import "antd/dist/antd.css"

// styles
import "../styles/SubmitRecipe.scss"

// icons
import { IoIosArrowDown } from "react-icons/io"

import { Controller, useForm } from "react-hook-form"

// context
import UserContext from "../context/user"

import { banners } from "../firebase/storage"

// import imgPlaceholder from "../assets/placeholder-img.png";

// components
import Hero from "../components/Hero"
import Newsletter from "../components/Newsletter"
import IngredientField from "../components/SubmitRecipe/IngredientField"
import Loading from "../components/Loading"
// import TimePicker from "../components/SubmitRecipe/TimePicker"
import { categories, mealCategories } from '../data/data'
import { CustomInvalidFeedbackV7, InvalidFeedbackV7, preventSubmit } from '../utils/validation'

// firestore
const recipesCollection = firebase.firestore().collection("recipes")

const SubmitRecipe = () => {
  // use context
  const { user, userData, loading, setLoading } = useContext(UserContext)

  // use history
  const history = useHistory()

  // use toast
  const { addToast } = useToasts()

  // uuid
  const uuid = uuidv4()

  // use state
  const initialIngrediant = { name: "Ingredient", qty: 1, qtySuffix: 'kg' }
  const [ingredientList, setIngredientList] = useState([initialIngrediant])
  const [imageFile, setImageFile] = useState(null)
  const [fileList, setFileList] = useState([])

  const { register, handleSubmit, formState: { errors }, formState, reset, control, setValue } = useForm({})

  useEffect(() => {
    reset({
      username: userData?.username,
      // displayName: user?.displayName,
      ingredientsNumber: 1
    })
    // eslint-disable-next-line
  }, [userData])

  const onSubmit = data => {
    if (validateIngredient()) {
      addToast("Please provide ingrediant name.", {
        appearance: "error",
      })
      return
    } else if (imageFile === null) {
      addToast("Please upload recipe image.", {
        appearance: "error",
      })
      return
    } else {
      setLoading(true)
      uploadImageToFBStorage(data, postRecipeToDB)
    }
  }

  const validateIngredient = () => {
    ingredientList.forEach((item) => {
      if (item === "") return true
    })

    return false
  }

  const postRecipeToDB = async (data, imageUrl) => {
    let payload = {
      // ...data,
      recipe: data.recipe,
      category: data.category,
      meal: data.meal,
      nutrition: data.nutrition,
      ingredientsNumber: data.ingredientsNumber,
      ingredients: ingredientList,
      instructions: data.instructions,
      status: "pending",
      userToken: user?.uid,
      user: data.username,
      // displayName: user?.displayName,
      recommended: false,
      // youtube: youtube,
      instagram: data.instagram,
      facebook: data.facebook,
      // website: website,
      cooktime: data.cooktime,
      preptime: data.preptime,
      image: imageUrl,
      peoples: data.peoples,
      uuid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }

    try {
      // console.log(data, payload)
      await recipesCollection
        .doc(uuid)
        .set(payload)
        .then(() => {
          addToast("Thanks for submitting. Let us review it!", {
            appearance: "success",
          })
          setLoading(false)
          history.push("/")
        })
        .catch((e) => {
          setLoading(false)
          addToast("Couldnt't submit your recipe! Please try again.", {
            appearance: "error",
          })
        })
    } catch (e) {
      setLoading(false)
      addToast("Couldnt't submit your recipe! Please try again.", {
        appearance: "error",
      })
    }
  }

  // const handleImageSelect = (e) => {
  //   if (e.target.files && e.target.files[0]) {
  //     setImageFile(e.target.files[0]);
  //     const reader = new FileReader();
  //     reader.onloadend = function () {
  //       setImage(reader.result);
  //     };
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // };

  const handleChange = (e, index, key) => {
    const { value } = e.target
    const newList = [...ingredientList]
    newList[index][key] = value
    setIngredientList(newList)
  }

  const AddMoreIngredient = () => {
    setIngredientList([...ingredientList, { value: "" }])
    setIngredientList([...ingredientList,
      initialIngrediant // { name: "", qty: 1, qtySuffix: 'kg' }
    ])
    setValue("ingredientsNumber", (ingredientList.length + 1))
  }

  const handleDelete = (index) => {
    let newList = [...ingredientList]
    newList.splice(index, 1)
    setIngredientList(newList)
    setValue("ingredientsNumber", (ingredientList.length - 1))
  }

  const uploadImageToFBStorage = (data, callback) => {
    const uploadTask = firebase
      .storage()
      .ref(`/images/${imageFile.name}`)
      .put(imageFile)

    uploadTask.on("state_changed", () => console.log(),
      console.error,
      () => {
        firebase
          .storage()
          .ref("images")
          .child(imageFile.name)
          .getDownloadURL()
          .then((url) => {
            callback(data, url)
          })
      }
    )
  }

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
    setImageFile(newFileList[0]?.originFileObj ?? null)
  }

  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }

  const customRequest = (props) => {
    props.onSuccess()
  }

  if (!user) {
    addToast("Please login before submitting a recipe!", {
      appearance: "error",
    })
    return <Redirect to="/" />
  }

  const onError = err => console.log(err)

  return (
    <>
      <Hero
        image={banners.submitRecipe}
        title="Submit Recipe"
        breadName="Submit Recipe"
      />
      <section
        className="ftco-section"
      >
        {loading && <Loading fixed={true} />}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <form className='billing-form' onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="row align-items-end">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="required" htmlFor="username">User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        {...register("username", { required: "Username is required." })}
                      />
                      <InvalidFeedbackV7 name="username" errors={errors} />
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                    <div className="form-group">
                      <label className="required" htmlFor="displayName">Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="displayName"
                        {...register("displayName", { required: "Full Name is required." })}
                      />
                      <InvalidFeedbackV7 name="displayName" errors={errors} />
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="required" htmlFor="recipe">Recipe Name</label>
                      <input
                        type="text"
                        name="recipe"
                        id="recipe"
                        className="form-control"
                        {...register("recipe", { required: "Recipe Name is required." })}
                      />
                      <InvalidFeedbackV7 name="recipe" errors={errors} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="required" htmlFor="category">Category</label>
                      <div className="select-wrap">
                        <div className="icon">
                          <span className="ion-ios-arrow-down">
                            <IoIosArrowDown />
                          </span>
                        </div>
                        <select
                          className="form-control"
                          name="category"
                          id="category"
                          {...register("category", { required: "Category is required." })}
                        >
                          <option value="">Select</option>
                          {categories.map((option, i) => (
                            <option value={option.value} key={i}>{option.label}</option>
                          ))}
                        </select>
                        <InvalidFeedbackV7 name="category" errors={errors} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="required" htmlFor="meal">Meal</label>
                      <div className="select-wrap">
                        <div className="icon">
                          <span className="ion-ios-arrow-down">
                            <IoIosArrowDown />
                          </span>
                        </div>
                        <select
                          className="form-control"
                          name="meal"
                          id="meal"
                          {...register("meal", { required: "Meal is required." })}
                        >
                          <option value="">Select</option>
                          {mealCategories.map((option, i) => (
                            <option value={option.value} key={i}>{option.label}</option>
                          ))}
                        </select>
                        <InvalidFeedbackV7 name="meal" errors={errors} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="required" htmlFor="preptime">Peparation Time</label>
                      <Controller
                        control={control}
                        name="preptime"
                        rules={{ required: "Peparation Time is required." }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          // <TimePicker
                          //   defaultValue={value}
                          //   onChange={onChange}
                          //   onBlur={onBlur}
                          // />
                          <TimePicker
                            id="preptime"
                            className="form-control"
                            // defaultValue={value}
                            onChange={(value, time) => onChange(time)}
                            onBlur={onBlur}
                            format={'HH:mm'}
                            placeholder="HH:MM"
                            showNow={false}
                          />
                        )}
                      />
                      <CustomInvalidFeedbackV7 name="preptime" errors={errors} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="required" htmlFor="cooktime">Cook Time</label>
                      <Controller
                        control={control}
                        name="cooktime"
                        rules={{ required: "Cook Time is required." }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          // <TimePicker
                          //   defaultValue={value}
                          //   onChange={onChange}
                          //   onBlur={onBlur}
                          // />
                          <TimePicker
                            id="cooktime"
                            className="form-control"
                            // defaultValue={value}
                            onChange={(value, time) => onChange(time)}
                            onBlur={onBlur}
                            format={'HH:mm'}
                            placeholder="HH:MM"
                            showNow={false}
                            renderExtraFooter={() => { }}
                          />
                        )}
                      />
                      <CustomInvalidFeedbackV7 name="cooktime" errors={errors} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="required" htmlFor="ingredientsNumber">
                        No. of Ingredients
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ingredientsNumber"
                        onChange={(e) => setValue("ingredientsNumber", e.target.value)}
                        {...register("ingredientsNumber", { required: "No. of Ingrrediants is required." })}
                      />
                      <InvalidFeedbackV7 name="ingredientsNumber" errors={errors} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="required" htmlFor="peoples">
                        How many people does it feed?
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="peoples"
                        {...register("peoples", { required: "This field is required." })}
                      />
                      <InvalidFeedbackV7 name="peoples" errors={errors} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="nutrition">Nurtition Facts</label>
                      <textarea
                        rows="3"
                        className="form-control"
                        name="nutrition"
                        id="nutrition"
                        {...register("nutrition")}
                      />
                      <InvalidFeedbackV7 name="nutrition" errors={errors} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ingredients">
                      {ingredientList.map((item, i) => (
                        <div key={i}>
                          <IngredientField
                            index={i}
                            handleChange={handleChange}
                            handleDelete={handleDelete}
                            ingredientList={ingredientList}
                          />
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={AddMoreIngredient}
                        className="submit-recipe-add-more"
                      >
                        Add more
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="required" htmlFor="instructions">Cooking Instructions</label>
                      <Controller
                        control={control}
                        name="instructions"
                        id="instructions"
                        rules={{ required: "Cooking Instructions is required." }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CKEditor
                            data={value}
                            onChange={(evt) =>
                              onChange(evt.editor.getData())
                            }
                            onBlur={onBlur}
                          />
                        )}
                      />
                      <CustomInvalidFeedbackV7 name="instructions" errors={errors} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Facebook Url (Optional)"
                        className="form-control"
                        {...register("facebook")}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Instagram Url (Optional)"
                        className="form-control"
                        {...register("instagram")}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        {...register("youtube")}
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        {...register("website")}
                      />
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <br />
                    <div className="submit-recipe-img-upload">
                      <label className="required col-12 p-0">Select Recipe Image</label>
                      {/* <label className="required" htmlFor="submit-recipe-img-upload">
                        <img
                          src={image == null ? imgPlaceholder : image}
                          alt="submit-recipe"
                        />
                      </label>
                      <input
                        id="submit-recipe-img-upload"
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => handleImageSelect(e)}
                      /> */}
                      <ImgCrop rotate>
                        <Upload
                          listType="picture-card"
                          fileList={fileList}
                          onChange={onChange}
                          onPreview={onPreview}
                          customRequest={customRequest}
                          accept="image/jpeg, image/png,, image/gif"
                        >
                          {fileList.length < 1 && "+ Upload"}
                        </Upload>
                      </ImgCrop>
                      <div className="text-info small">Only jpeg, png or gif files supported</div>
                    </div>
                  </div>
                  <div className="col-md-12 row mt-4 justify-content-center">
                    <br />
                    <button
                      type="submit"
                      className="btn btn-primary py-3 px-4 submit-recipe-btn"
                      disabled={preventSubmit(formState)}
                    >
                      Submit Recipe
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Newsletter />
    </>
  )
}

export default SubmitRecipe
