
const defaulMealPlan = {
  weekOne: {
    breakfast: [],
    lunch: [],
    dinner: [],
  },
  weekTwo: {
    breakfast: [],
    lunch: [],
    dinner: [],
  },
  weekThree: {
    breakfast: [],
    lunch: [],
    dinner: [],
  },
  weekFour: {
    breakfast: [],
    lunch: [],
    dinner: [],
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'weekOne':
      return { ...state, weekOne: action.payload};
    case 'weekTwo':
      return { ...state, weekTwo: action.payload};
    case 'weekThree':
      return { ...state, weekThree: action.payload};
    case 'weekFour':
      return { ...state, weekFour: action.payload};
    default:
      return state;
      // throw new Error();
  }
}

const planSize = (obj) => {
  var size = 0,
    key
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      obj[key]?.breakfast?.length > 1 && size++
    }
  }
  return size
}

export {
  reducer,
  defaulMealPlan,
  planSize
}
