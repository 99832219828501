// libs
import { useState, useContext, useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import firebase from "../firebase"

// context
import UserContext from "../context/user"
import RecipesContext from "../context/recipes"

// pages
import Home from "../pages/Home"
import Recipes from "../pages/Recipes"
import SubmitRecipe from "../pages/SubmitRecipe"
import RecipeDetails from "../pages/RecipeDetails"
import Favorites from "../pages/Favorites"
import Contact from "../pages/Contact"
import Error from "../pages/Error"
import Admin from "../pages/Admin/Admin"
import AdminRecipeDetails from "../pages/Admin/AdminRecipeDetails"
import AdminRecipeDetailsEdit from "../pages/Admin/AdminRecipeDetailsEdit"
import AdminLogin from "../pages/Admin/AdminLogin"
import MealPlan from "../pages/MealPlan"
import MealPlanGenerate from "../pages/MealPlanGenerate"
import MealPlanSaved from "../pages/MealPlanSaved"
import AboutUs from "../pages/AboutUs"
import AuthActions from "../pages/Auth/AuthActions"

// components
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ScrollToTop from "../components/ScrollToTop"
import AdminApproved from "../components/Admin/AdminApproved"
import AdminPending from "../components/Admin/AdminPending"
import AdminRejected from "../components/Admin/AdminRejected"

const Routes = () => {
  // use context
  const { setUser } = useContext(UserContext)
  const { allRecipes, loading } = useContext(RecipesContext)

  //State Values
  const [initializing, setInitializing] = useState(true)

  const onAuthStateChanged = (user) => {
    setUser(user)
    if (initializing) setInitializing(false)
  }

  const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged)

  useEffect(() => {
    return subscriber // unsubscribe on unmount
  }, [subscriber])

  if (initializing) return null

  return (
    <>
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <Navbar />
            <Home />
            <Footer />
          </Route>
          <Route exact path="/recipes/:id?">
            <Navbar />
            <Recipes />
            <Footer />
          </Route>
          <Route path="/recipes/:category/:id">
            <Navbar />
            <RecipeDetails />
            <Footer />
          </Route>
          <Route path="/meal-plan/:week">
            <Navbar />
            <MealPlanGenerate />
            <Footer />
          </Route>
          <Route path="/meal-plan">
            <Navbar />
            <MealPlan />
            <Footer />
          </Route>
          <Route path="/saved-meal-plans">
            <Navbar />
            <MealPlanSaved />
            <Footer />
          </Route>
          <Route path="/submit-recipe">
            <Navbar />
            <SubmitRecipe />
            <Footer />
          </Route>
          <Route path="/favorites">
            <Navbar />
            <Favorites />
            <Footer />
          </Route>
          <Route path="/contact">
            <Navbar />
            <Contact />
            <Footer />
          </Route>
          <Route path="/about-us">
            <Navbar />
            <AboutUs />
            <Footer />
          </Route>
          <Route path="/auth">
            <Navbar />
            <AuthActions />
            <Footer />
          </Route>

          <Route path="/admin-login" component={AdminLogin} />
          <Route exact path="/admin">
            <Admin>
              <AdminApproved recipes={allRecipes} loading={loading} />
            </Admin>
          </Route>
          <Route exact path="/admin-pending">
            <Admin>
              <AdminPending recipes={allRecipes} loading={loading} />
            </Admin>
          </Route>
          <Route exact path="/admin-rejected">
            <Admin>
              <AdminRejected recipes={allRecipes} loading={loading} />
            </Admin>
          </Route>
          <Route exact path="/admin/:category/:id">
            <Admin>
              {allRecipes.length > 0 && <AdminRecipeDetails />}
            </Admin>
          </Route>
          <Route exact path="/admin/edit/:category/:id">
            <Admin>
              {allRecipes.length > 0 && <AdminRecipeDetailsEdit />}
            </Admin>
          </Route>
          <Route path="*">
            <Navbar />
            <Error />
            <Footer />
          </Route>
        </Switch>
      </ScrollToTop>
    </>
  )
}

export default Routes
