export function sanitizeTime(time = '00:00') {
  const [hh, mm] = time.split(":")
  if (time === '00:00') {
    return time
  }
  return `${isNaN(parseInt(hh)) ? "00" : hh}:${isNaN(parseInt(mm)) ? "00" : mm}`
}

export const sanitizeDate = (dateStr) => {
  return String(dateStr).replaceAll("/", "-")
}

export const isLeapYear = (str = '') => {
  // console.log('isLeapYear', str)
  let year = new Date(str).getFullYear()
  // console.log('isLeapYear', year, ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)
}

export const formatDate = (date, options = { dateStyle: 'full' }) => {
  if (date) {
    return new Intl.DateTimeFormat('en', options).format(date)
  }
}

export const prettyDate = (time) => {
  let timeStamp = ''
  if (typeof time === 'object') {
    timeStamp = time
  } else {
    timeStamp = new Date(time)
  }
  const diff = (((new Date()).getTime() - timeStamp.getTime()) / 1000)
  const dayDiff = Math.floor(diff / 86400)
  const year = timeStamp.getFullYear()
  const month = timeStamp.getMonth() + 1
  const day = timeStamp.getDate()

  if (isNaN(dayDiff) || dayDiff < 0) {
    return (`On ${year.toString()} - ${((month < 10) ? 0 + month.toString() : month.toString())} - ${((day < 10) ? 0 + day.toString() : day.toString())}`)
  }

  // console.log(dayDiff)
  return (
    (
      dayDiff === 0
      && (
        (diff < 60 && 'just now')
        || (diff < 120 && 'a minute ago')
        || (diff < 3600 && `${Math.floor(diff / 60)} minutes ago`)
        || (diff < 7200 && 'an hour ago')
        || (diff < 86400 && `${Math.floor(diff / 3600)} hours ago`)
      )
    )
    || (dayDiff === 1 && 'Yesterday')
    || (dayDiff < 7 && `${dayDiff} days ago`)
    || (dayDiff < 28 && `${Math.ceil(dayDiff / 7)} weeks ago`)
    || (dayDiff < 58 && 'last month')
    || (dayDiff < 365 && `${Math.ceil(dayDiff / 30)} months ago`)
    || (dayDiff > 365 && 'last year')
    || (dayDiff > 630 && `${Math.ceil(dayDiff / 365)} years ago`)
  )
}

export const formatValidity = (days = 30) => (days === 1 && '1 Day')
  || (days > 1 && days < 28 && `${days} Days`)
  || (days === 8 && '1 Week')
  || (days >= 28 && days <= 31 && '1 Month')
  || (days < 365 && `${Math.round(days / 30)} Months`)
  || (days === 365 && '1 Year')
  || (days > 365 && `${Math.round(days / 365)} Years`)




export function timeToMins(time = '00:00') {
  const [hh, mm] = time.split(":")
  if (time === '00:00') {
    return time
  }
  let mins = ''
  if (parseInt(hh) > 0) {
    mins = `${hh}h `
  }
  if (parseInt(mm) > 0) {
    mins += `${mm} min `
  }
  return mins
}
