import { useContext } from "react"
import { useHistory, Redirect } from "react-router-dom"
import { useForm } from "react-hook-form"

// styles
import "../../styles/AdminLogin.scss"

// context
import AdminContext from "../../context/admin"

// icons
import { FaUser, FaLock } from "react-icons/fa"
import logo from '../../assets/logo-w.png'
import UserLoading from "../../components/Loading"
import { message } from "antd"
import { InvalidFeedbackV7, preventSubmit, validEmailRegx } from "../../utils/validation"

const AdminLogin = () => {
  // use context
  const { loading, admin, adminStatus, setAdminStatus } = useContext(AdminContext)

  const { register, handleSubmit, formState: { errors }, formState } = useForm({})

  // use history
  const history = useHistory()

  const onSumbit = (data) => {
    if (data.email === admin.email && data.password === admin.password) {
      message.success("Log in successfully.")
      localStorage.setItem("admin", data.email)
      setAdminStatus(data.email)
      history.push("/admin")
    } else {
      message.error("Admin credentials do not match")
    }
  }

  if (adminStatus !== null) {
    <Redirect to='/admin' />
  }

  const onError = err => console.log(err)

  return (
    <div className='admin-container'>
      {loading && <UserLoading fixed={true} />}
      <div className='admin-form-box'>
        <div className='admin-header-form'>
          <div className="text-center">
            <img src={logo} style={{ height: "3em" }} alt="psc" />
            <h4>Admin Login</h4>
          </div>
        </div>
        <div className='admin-body-form'>
          <form onSubmit={handleSubmit(onSumbit, onError)}>
            <div className='input-group mb-3'>
              <div className='input-group-prepend'>
                <span className='input-group-text'>
                  <i className='fa fa-user'>
                    <FaUser />
                  </i>
                </span>
              </div>
              <input
                type='email'
                className='form-control'
                placeholder='Email'
                {...register("email", {
                  required: "Email is required.",
                  validate: {
                    email: email => email.match(validEmailRegx) || "Please enter valid Email"
                  }
                })}
              />
              <InvalidFeedbackV7 name="email" errors={errors} />
            </div>
            <div className='input-group mb-3'>
              <div className='input-group-prepend'>
                <span className='input-group-text'>
                  <i className='fa fa-lock'>
                    <FaLock />
                  </i>
                </span>
              </div>
              <input
                type='password'
                className='form-control'
                placeholder='Password'
                {...register("password", {
                  required: "Password is required.",
                  // onChange: (e) => setPassword(e.target.value)
                })}
              />
              <InvalidFeedbackV7 name="password" errors={errors} />
            </div>
            {/* <div className='admin-message'>
              <div>
                <input
                  type='checkbox'
                  value={remember}
                  onChange={(e) => setRemember(e.target.checked)}
                />
                Remember Me
              </div>
            </div> */}
            <button
              type="submit"
              className='btn btn-secondary btn-block admin-button'
              disabled={preventSubmit(formState)}
            >
              LOGIN
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default AdminLogin
