// libraries
import { useState, createContext, useEffect } from "react"
import firebase from "../firebase"

// create context
const AdminContext = createContext()

// firestore
const adminDocument = firebase.firestore().collection("admin").doc("admin")

const getAdminFromLocalStorage = () => {
  return localStorage.getItem("admin") ? localStorage.getItem("admin") : null
}

export const AdminProvider = ({ children }) => {
  // use state
  const [loading, setLoading] = useState(false)
  const [admin, setAdmin] = useState()
  const [adminStatus, setAdminStatus] = useState(getAdminFromLocalStorage())

  // Initializing FireStore User Document
  useEffect(() => {
    setLoading(true)
    adminDocument.onSnapshot((snapshot) => {
      setAdmin(snapshot.data())
      setLoading(false)
    })
  }, [])

  return (
    <AdminContext.Provider
      value={{
        admin,
        loading,
        adminStatus,
        setAdminStatus,
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}

export default AdminContext
