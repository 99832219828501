// libraries
import { Link } from "react-router-dom";
import { banners } from "../../firebase/storage";

// assets
// import hero from "../../assets/hero.jpg";

const Hero = () => {
  const bg = banners.hero

  const scroll = () => {
    const section = document.querySelector('.cat-section')
    section.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div className="carousel-item mb-5">
      <div
        className="carousel-item-sub"
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)),url("${bg}")`,
        }}
      >
        {/* <h1 className="carousel-heading text-center"><span style={{color: "#8fb40f"}}>Plan</span> <span style={{color: "#f6be68"}}>Shop</span> <span style={{color: "#d11d0c"}}>Cook</span></h1> */}
        <h1 className="carousel-heading text-center"><span>Plan</span> <span>Shop</span> <span>Cook</span></h1>
        <h2 className="text-center carousel-subtitle">
          Meal planning just got Easier
        </h2>
        <div className="d-md-flex text-center">
          <Link
            to="/meal-plan"
            className="mt-3 mt-md-5 px-5 py-3 btn btn-primary submit-recipe-btn mx-2"
          >
            Meal Plans
          </Link>
          <Link
            to="/#"
            onClick={scroll}
            className="mt-3 mt-md-5 px-5 py-3 btn btn-primary submit-recipe-btn mx-2"
          >
            Diets
          </Link>
          <Link
            to="/submit-recipe"
            className="mt-3 mt-md-5 px-5 py-3 btn btn-primary submit-recipe-btn mx-2"
          >
            Submit Recipe
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hero;
