// libraries
import { useState, useContext } from "react"
import { Link, NavLink } from "react-router-dom"
import {
  Collapse,
  Navbar as BootstrapNavbar,
  NavbarToggler,
  Nav,
  NavItem,
  // UncontrolledButtonDropdown,
  // DropdownMenu,
  // DropdownItem,
  // DropdownToggle,
} from "reactstrap"
import firebase from "../firebase"
import { useToasts } from "react-toast-notifications"

// styles
import "../styles/Navbar.scss"

// context
import UserContext from "../context/user"

// icons
import {
  FaBars,
  FaSignOutAlt,
  FaUser,
  // FaUserSlash
} from "react-icons/fa"
import { AiOutlineClose } from "react-icons/ai"
// import logo from '../assets/logo-white-green.png'
// import logo from '../assets/logo-1.jpg'
import logo from '../assets/logo-w.png'
// import logoMobile from '../assets/logo-mobile.png'

// components
import LoginModal from "./LoginModal"

const Navbar = () => {
  // contexts
  const { user, setLoading, setFavorite } = useContext(UserContext)

  // states
  const [isOpen, setIsOpen] = useState(false)

  // use toast
  const { addToast } = useToasts()

  // handle nav toggle
  const toggle = () => setIsOpen(!isOpen)

  //Firebase Logout Method
  const logout = async () => {
    setLoading(true)
    try {
      await firebase
        .auth()
        .signOut()
        .then(() => {
          setLoading(false)
          setFavorite([])
        })
    } catch (e) {
      setLoading(false)
      console.log(e)
      addToast(e.message, {
        appearance: "error",
      })
    }
  }

  return (
    <div className='navbar-wrapper'>
      {isOpen && <div onClick={() => setIsOpen(false)} className='nav-fade' />}
      <BootstrapNavbar expand='lg' className='navbar'>
        <div className='container'>
          <Link to='/' className='navbar-brand'>
            <img src={logo} style={{ height: "1.5em" }} className="d-lg-none ml-2" alt="" />
            <img src={logo} style={{ height: "2.75em" }} className="d-none d-lg-block" alt="" />
          </Link>
          <NavbarToggler
            onClick={toggle}
            color='primary'
            className='nav-toggler'
          >
            {!isOpen ? <FaBars /> : <AiOutlineClose />}
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className='ml-auto' navbar>
              <NavItem>
                <NavLink
                  onClick={() => setIsOpen(false)}
                  className='nav-link mx-2'
                  to='/'
                >
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => setIsOpen(false)}
                  className='nav-link mx-2'
                  to='/recipes'
                >
                  Recipes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => setIsOpen(false)}
                  className='nav-link mx-2'
                  to='/submit-recipe'
                >
                  Submit Recipe
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => setIsOpen(false)}
                  className='nav-link mx-2'
                  to='/favorites'
                >
                  Favorites
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => setIsOpen(false)}
                  className='nav-link mx-2'
                  to='/saved-meal-plans'
                >
                  Saved Meal Plans
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => setIsOpen(false)}
                  className='nav-link mx-2'
                  to='/contact'
                >
                  Contact
                </NavLink>
              </NavItem>

              <NavItem>
                {user ? (
                  <NavLink
                    onClick={() => { setIsOpen(false); logout() }}
                    className='nav-link mx-2'
                    to='/#'
                  >
                    Logout <FaSignOutAlt />
                  </NavLink>
                ) : (
                  <LoginModal
                    buttonClassName='login-btn'
                    buttonLabel={
                      <NavLink
                        className='nav-link mx-2'
                        to="/#">
                        Log in <FaUser />
                      </NavLink>
                    }
                  />
                )}
              </NavItem>
              {/* <NavItem>
                {user ? (
                  <span className='nav-link nav-logout'>
                    <UncontrolledButtonDropdown>
                      <DropdownToggle caret className='nav-login'>
                        <FaSignOutAlt />
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-logout-wrapper'>
                        <DropdownItem
                          onClick={logout}
                          className='dropdown-logout'
                        >
                          Logout
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </span>
                ) : (
                  <span className='nav-link'>
                    <LoginModal
                      buttonClassName='login-btn'
                      buttonLabel={
                        <UncontrolledButtonDropdown className='nav-login-wrapper'>
                          <DropdownToggle className='nav-login'>
                            <FaUser />
                          </DropdownToggle>
                        </UncontrolledButtonDropdown>
                      }
                    />
                </span>
                )}
              </NavItem> */}
            </Nav>
          </Collapse>
        </div>
      </BootstrapNavbar>
    </div>
  )
}

export default Navbar
