// helpers
import { statusRecipes } from "../../utils/helpers";

// components
import AdminStatusItem from "./AdminStatusItem";
import UserLoading from "../Loading";

const AdminApproved = ({ recipes, loading }) => {
  const approved = statusRecipes(recipes, "approved");

  return (
    <div className='admin-wrapper'>
      <h4 className='font-weight-bold'>Approved Recipes</h4>
      {loading ? (
        <div className='loading-wrapper'>
          <UserLoading />
        </div>
      ) : approved.length > 0 ? (
        approved.map((item, index) => {
          return <AdminStatusItem key={index} data={item} status='approved' />;
        })
      ) : (
        <div className='recipes-empty'>
          <h5>No recipes to show!</h5>
        </div>
      )}
    </div>
  );
};

export default AdminApproved;
