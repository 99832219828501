// libraries
import { useContext, useState } from "react"
import {
  Link,
  // useParams
} from "react-router-dom"

// style
import "../styles/Recipes.scss"

// context
import RecipesContext from "../context/recipes"

// assets
// import bg from "../assets/image_3.jpg";

// helpers
import {
  categoryRecipes,
  mealRecipes,
  getCategoryFromLocalStorage,
} from "../utils/helpers"

// components
import { useEffect } from "react"
import Hero from "../components/Hero"
import Loading from "../components/Loading"
import Newsletter from "../components/Newsletter"
import RecipesItem from "../components/Home/RecipesItem"
import { categories } from "../firebase/storage"
import classNames from "classnames"
import usePagination from "../hooks/usePagination"
// import { FaSearch } from 'react-icons/fa'
// import LoaderContext from '../context/loader';

const limit = 16

const Recipes = ({ fromHome }) => {
  // use context
  const { loading, recipes } = useContext(RecipesContext)
  const { currentPage, offset, pages, calculatePages, handlePage } = usePagination(limit)
  // const { setLoading } = useContext(LoaderContext);

  // use params
  // const { id } = useParams();

  // use state
  const [recipeByCategory, setRecipeByCategory] = useState([])
  const [category, setCategory] = useState(getCategoryFromLocalStorage())
  const [meal, setMeal] = useState("all")
  // const [showMealPlan, setShowMealPlan] = useState(id === "mp" ? true : false);

  useEffect(() => {
    let tmpRecipes = mealRecipes(categoryRecipes(recipes, category), meal)
    setRecipeByCategory(tmpRecipes)
    calculatePages(tmpRecipes.length)
    if (currentPage !== 1) {
      handlePage(1)
    }
    return () => {
      localStorage.removeItem("category")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, meal, recipes])

  // console.log("pages", pages)

  // const searchRecipe = (e) => {
  //   const search = e.target.value
  //   const pattern = new RegExp('^.*' + search + '.*', 'i')
  //   setRecipeByCategory(recipes.filter(recipe => pattern.test(recipe?.recipe) || pattern.test(recipe?.user) || pattern.test(recipe?.category)))
  //   // setLoading(true)
  //   // setTimeout(() => {
  //   //   setRecipeByCategory(recipes.filter(recipe => pattern.test(recipe.recipe)))
  //   //   setLoading(false)
  //   // }, 1000);
  // }

  return (
    <>
      <Hero
        image={category !== 'all' && categories[category]}
        title={category}
        breadName={"Recipes"}
        singleProduct={false}
      />
      <section className='py-5'>
        <div className='container'>
          {loading ? (
            <div className='loading-wrapper'>
              <Loading />
            </div>
          ) : (
            <>
              <div className='row justify-content-center'>
                <div className='col-md-10 text-center'>
                  <div className='d-flex align-items-center justify-content-center mb-5'>
                    <Link
                      to='/meal-plan'
                      className='btn btn-primary'
                    >
                      Generate Meal Plan
                    </Link>
                  </div>
                  <ul className='product-category'>
                    <li>
                      <span
                        onClick={() => setCategory("all")}
                        className={`${category === "all" && "active"}`}
                      >
                        All
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setCategory("vegetarian")}
                        className={`${category === "vegetarian" && "active"}`}
                      >
                        Vegetarian
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setCategory("vegan")}
                        className={`${category === "vegan" && "active"}`}
                      >
                        Vegan
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setCategory("pescatarian")}
                        className={`${category === "pescatarian" && "active"}`}
                      >
                        Pescatarian
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setCategory("keto")}
                        className={`${category === "keto" && "active"}`}
                      >
                        Keto
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setCategory("mediterranean")}
                        className={`${category === "mediterranean" && "active"
                          }`}
                      >
                        Mediterranean
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setCategory("kids")}
                        className={`${category === "kids" && "active"}`}
                      >
                        Kids
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setCategory("healthy")}
                        className={`${category === "healthy" && "active"}`}
                      >
                        Healthy
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='row justify-content-center mt-3'>
                <div className='col-md-10 text-center'>
                  <ul className='product-category'>
                    <li>
                      <span
                        onClick={() => setMeal("all")}
                        className={`${meal === "all" && "active"}`}
                      >
                        All
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setMeal("breakfast")}
                        className={`${meal === "breakfast" && "active"}`}
                      >
                        Breakfast
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setMeal("lunch")}
                        className={`${meal === "lunch" && "active"}`}
                      >
                        Lunch
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setMeal("dinner")}
                        className={`${meal === "dinner" && "active"}`}
                      >
                        Dinner
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className='row'>
                <div className='col-md-12 text-center mt-3'>
                  <div className="search-box">
                    <div className="input-group">
                      <input
                        className="form-control"
                        placeholder="Search Recipes"
                        onChange={searchRecipe}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2"><FaSearch /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className='row mt-5'>
                {recipeByCategory.length > 0 ? (
                  <>
                    {recipeByCategory.slice(offset, limit * currentPage).map(item => {
                      return (
                        <RecipesItem
                          key={item.uuid}
                          title={item.recipe}
                          id={item.uuid}
                          meal={item.meal}
                          category={item.category}
                          image={item.image}
                          user={item.user}
                          item={item}
                        />
                      )
                    })}

                    <div className="col-12">
                      <ul className="pagination justify-content-center">
                        {pages.length > 1 && pages.map(page => (
                          <li className={classNames("page-item", { "active": currentPage === page })}>
                            <button type="button" className="btn-link page-link" onClick={() => handlePage(page)}>
                              {page}
                            </button>
                          </li>
                        )
                        )}
                      </ul>
                    </div>
                  </>
                ) : (
                  <div className='recipes-empty'>
                    <h5>No recipes to show!</h5>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </section>
      <Newsletter />
    </>
  )
}

export default Recipes
