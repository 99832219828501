import firebase from "firebase/app"
import "firebase/storage"
import "firebase/firestore"
import "firebase/auth"
import "firebase/analytics"

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCdDFs_GC83oHf2F0U3d5epDI6kvQMl8kw",
  authDomain: "delightey-55cb2.firebaseapp.com",
  projectId: "delightey-55cb2",
  storageBucket: "delightey-55cb2.appspot.com",
  messagingSenderId: "367553860210",
  appId: "1:367553860210:web:7896ec792b84c85527e8eb",
  measurementId: "G-6DJH9RD10B"
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const analytics = firebase.analytics();

export default firebase

export {
  firebaseConfig,
  analytics
}
