// libraries
import { Link } from "react-router-dom"

// styles
import "../styles/Footer.scss"
import logo from '../assets/logo.png'

// icons
import {
  FaEnvelope,
  // FaMapMarkerAlt,
  // FaPhoneAlt,
  FaTwitter,
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa"
import { IoIosArrowUp } from "react-icons/io"

const Footer = () => {
  // handle scroll to top
  const scrollBackToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <footer className="ftco-footer ftco-section footer">
      <div className="container">
        <div className="row">
          <div className="mouse">
            <div onClick={scrollBackToTop} className="mouse-icon">
              <div className="mouse-wheel">
                <IoIosArrowUp />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-4">
            <div className="ftco-footer-widget mb-4">
              {/* <h2 className="ftco-heading-2 footer-icon">Delightye</h2> */}
              <img src={logo} className="footer-logo" alt="planshopecook" />
              {/* <img src={logo} className="footer-logo-red" alt="planshopecook" style={{width: "60%", height: "auto"}} /> */}
              <p>
                Meal planning just got easier.
              </p>
              <ul className="ftco-footer-social list-unstyled float-md-left mt-5">
                <li>
                  <a
                    href="https://twitter.com/PlanShopCook1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="social-icon icon-twitter">
                      <FaTwitter />
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/Plan-Shop-Cook-538552863732071"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="social-icon icon-facebook">
                      <FaFacebookF />
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/planshopcook/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="social-icon icon-instagram">
                      <FaInstagram />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6 col-md-4">
            <div className="ftco-footer-widget mb-4 ml-md-5">
              <h2 className="ftco-heading-2">Menu</h2>
              <ul className="list-unstyled">
                <li>
                  <Link to="/" className="py-2 d-block">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/recipes" className="py-2 d-block">
                    Recipes
                  </Link>
                </li>
                <li>
                  <Link to="/submit-recipe" className="py-2 d-block">
                    Submit Recipe
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="py-2 d-block">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className="py-2 d-block">
                    About Us
                  </Link>
                </li>
                {/* <li>
                  <Link to='/wishlist' className='py-2 d-block'>
                    Wishlist
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Questions/Feedback</h2>
              <div className="block-23 mb-3">
                <ul>
                  {/* <li>
                    <span className="icon icon-map-marker">
                      <FaMapMarkerAlt />
                    </span>
                    <span className="text">
                      203 Fake St. Mountain View, San Francisco, California, USA
                    </span>
                  </li>
                  <li>
                    <a href="tel:+92-333-1234567">
                      <span className="icon icon-phone">
                        <FaPhoneAlt />
                      </span>
                      <span className="text">+2 333 123 4567</span>
                    </a>
                  </li> */}
                  <li>
                    <a href="mailto:contact@planshopcook.com" className="d-flex align-items-center">
                      <span className="icon icon-envelope">
                        <FaEnvelope />
                      </span>
                      <span className="text">contact@planshopcook.com</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <p>
              Copyright &copy; {new Date().getFullYear()} All rights reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
