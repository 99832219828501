// libraries
import { useHistory } from "react-router-dom";

// icons
import { FaBan, FaCheckCircle, FaStopwatch } from "react-icons/fa";
import logo from '../../assets/logo.png'

const AdminSidebar = () => {
  // use history
  const history = useHistory();

  return (
    <div className='admin-side-bar'>
      {/* <h4 onClick={() => history.push("/admin")}>DELIGHYTE</h4> */}
      <h4 onClick={() => history.push("/admin")}>
        <img src={logo} style={{width: '4em'}} alt="logo" />
      </h4>
      <div className='divider' />
      <div className='row flex-column m-0 admin-side-bar-nav'>
        <p onClick={() => history.push("/admin")}>
          <FaCheckCircle /> Approved Recipes
        </p>
        <p onClick={() => history.push("/admin-pending")}>
          <FaStopwatch /> Pending Recipes
        </p>
        <p onClick={() => history.push("/admin-rejected")}>
          <FaBan /> Rejected Recipes
        </p>
      </div>
    </div>
  );
};

export default AdminSidebar;
