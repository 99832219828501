import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  FaEye,
  FaEyeSlash
  // FaHome, FaPhone
} from "react-icons/fa"
import "./style.scss"

const PasswordEye = ({ target }) => {
  const [show, setShow] = useState(false)

  const handleShowPassword = () => {
    if (show) {
      // setIcon(faEye)
      setShow(false)
    } else {
      // setIcon(faEyeSlash)
      setShow(true)
    }

    if (target) {
      const el = document.querySelector(`#${target}`)

      if (el) {
        if (el.type.toLowerCase() === 'password') {
          // setIcon(faEyeSlash)
          el.type = 'text'
        } else {
          el.type = 'password'
          // setIcon(faEye)
        }
      } else {
        alert(`${target} not found`)
      }
    }
  }

  return (
    <div
      className="password-control"
      onClick={handleShowPassword}
    >
      {show ? <FaEye /> : <FaEyeSlash />}
      {/* {React.cloneElement(show ? <FaEye /> : <FaEyeSlash />, {
        // className: "passwordInput",
        // onClick: handleShowPassword
        // onMouseDown={handleShowPassword}
        // onMouseUp={handleShowPassword}
      })} */}
    </div>
  )
}

PasswordEye.propTypes = {
  target: PropTypes.string.isRequired
}

export {
  PasswordEye
}
