// libraries
import { useState, useContext, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import { useToasts } from "react-toast-notifications";

// context
import UserContext from "../../context/user";
import MealPlanContext from "../../context/mealplan";

// icons
import { IoIosMenu } from "react-icons/io";
import UserLoading from "../Loading";
import {
  RiAddBoxFill,
  RiAddBoxLine,
  RiHeart3Fill,
  RiHeart3Line
} from 'react-icons/ri';

// firestore
const userCollection = firebase.firestore().collection("users");

function reducer(state, action) {
  return action
}

const RecommendedRecipesItem = ({
  title,
  id,
  category,
  image,
  meal,
  user: userDisplayName,
  item
}) => {

  // use context
  const { user, favorites } = useContext(UserContext);
  const { getConfirmation } = useContext(MealPlanContext);

  // use state
  const [loading, setLoading] = useState(false);
  // const [isFav, setFav] = useState(favorites.includes(id));

  const [isFav, dispatch] = useReducer(reducer, true);
  const isMeal = false;
  // const [isMeal, setMeal] = useState(false);

  useEffect(() => {
    dispatch(favorites.includes(id))
  }, [favorites, id]);

  // use toast
  const { addToast } = useToasts();

  // useEffect(() => {
  //   if (user) {
  //     setLoading(true);
  //     userCollection
  //       .doc(user.uid)
  //       .collection("favorites")
  //       .onSnapshot((snapshot) => {
  //         snapshot.docs.map((doc) => doc.data().id === id && setFav(true));
  //         setLoading(false);
  //         console.log("favorite retrived")
  //       });
  //   }
  //   console.log("revalidate favorite")
  // }, [user]);

  const addToFavorites = async () => {
    if (user) {
      // setFav(true);
      setLoading(true);
      await userCollection
        .doc(user.uid)
        .collection("favorites")
        .doc(id)
        .set({favorite: true})
        .then(() => {
          addToast("Recipe added to Favorites!", {
            appearance: "success",
          });
        })
        .catch((e) => {
          addToast("Couldnt't add recipe to favorites! Please try again.", {
            appearance: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      addToast("Please Log in First!", {
        appearance: "error",
      });
    }
  };

  const removeFavorite = async () => {
    if (user) {
      setLoading(true);
      await userCollection
        .doc(user.uid)
        .collection("favorites")
        .doc(id)
        .delete()
        .then(() => {
          addToast("Recipe removed from Favorites!", {
            appearance: "info",
          });
        })
        .catch((e) => {
          addToast("Couldnt't remove recipe from favorites! Please try again.", {
            appearance: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      addToast("Please Log in First!", {
        appearance: "error",
      });
    }
  };

  const handleMealPlan = (e, item) => {
    if (e.stopPropagation) e.stopPropagation();
    // console.log("item", item)
    getConfirmation({
      title: 'Add To Meal Plan?',
      type: 'info',
      message: ' ',
      description: 'Are you sure, You want add this recipe to meal plan?',
      defaultButton: 'primary',
      item: item,
      action: 'approve'
    })
  }

  return (
    <div className='col-md-6 col-lg-3'>
      <div className='product'>
        {loading && <UserLoading fixed={true} />}
        <Link to={`recipes/${category}/${id}`} className='img-prod'>
          <img className='img-fluid' src={image} alt={title} />
          <span className='status text-capitalize'>{meal}</span>
          <div className='overlay'></div>
        </Link>
        <div className='text py-3 pb-4 px-3 text-center'>
          <p>
            <Link style={{ color: "#888" }} to={`recipes/${category}/${id}`}>
              By {userDisplayName}
            </Link>
          </p>
          <h3>
            <Link to={`recipes/${category}/${id}`}>{title}</Link>
          </h3>
          <div className='d-flex'>
            <div className='pricing'>
              <p className='price'>
                <span className='mr-2 text-capitalize'>{category}</span>
              </p>
            </div>
          </div>
          <div className='bottom-area d-flex px-3'>
            <div className='m-auto d-flex'>
              <Link
                to={`recipes/${category}/${id}`}
                className='featured-bottom add-to-cart d-flex justify-content-center align-items-center text-center mx-3'
              >
                <IoIosMenu />
              </Link>
              <div
                className='featured-bottom heart d-flex justify-content-center align-items-center mx-3'
                onClick={(e) => handleMealPlan(e, item)}
                title={isMeal ? "remove from meal plan" : "add to meal plan"}
                style={{
                  // background: `${isFav ? "white" : "#d63230"}`,
                  background: "white",
                  boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
                }}
              >
                {isMeal ? (
                  <RiAddBoxFill color="#d63230" />
                ) : (
                  <RiAddBoxLine color="#d63230" />
                )}
                </div>
              <div
                className='featured-bottom heart d-flex justify-content-center align-items-center mx-3'
                onClick={isFav ? removeFavorite : addToFavorites}
                title={isFav ? "remove from favorite" : "add to favorite"}
                style={{
                  // background: `${!isFav ? "white" : "#d63230"}`,
                  background: "white",
                  boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
                }}
              >
                {isFav ? (
                  <RiHeart3Fill color='#d63230' size={20} />
                  ) : (
                  <RiHeart3Line color='#d63230' size={20} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendedRecipesItem;
