import { useState } from 'react';
import { useToasts } from "react-toast-notifications";
import Loading from "../components/Loading";

import { list } from "../utils/mailgun"
const Newsletter = () => {
  // use toast
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")

  const subscribe = (e) => {
    e.preventDefault();

    if (!email) {
      addToast("Please provide email", {
        appearance: "error",
      });
      return
    }
    setLoading(true);

    var subscriber = {
      subscribed: true,
      address: email,
      // name: 'Vikesh Agravat',
      // vars: { age: 35 }
    };

    list.members().create(subscriber)
      .then(msg => {
        console.log(msg);
        addToast("You are subscribed.", {
          appearance: "success",
        });
      }) // logs response data
      .catch(err => {
        console.log(err);
        let msg = err.message || "Something went wrong, Please try again later."
        addToast(msg, {
          appearance: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      }); // logs any error
  }

  return (
    <section className='ftco-section ftco-no-pt ftco-no-pb py-5 bg-light'>
      {loading && <Loading fixed={true} />}
      <div className='container py-4'>
        <div className='row d-flex justify-content-center py-5'>
          <div className='col-md-6'>
            <h2 style={{ fontSize: "22px" }} className='mb-0'>
              Subcribe to our Newsletter
            </h2>
            <span>
              Get e-mail updates about our latest shops and special offers
            </span>
          </div>
          <div className='col-md-6 d-flex align-items-center'>
            <form className='subscribe-form'>
              <div className='form-group d-flex'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter email address'
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type='submit'
                  value='Subscribe'
                  className='submit px-3'
                  onClick={subscribe}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
