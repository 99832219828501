// import { logEvent } from "@firebase/analytics"
import { analytics } from "../firebase"

// export const setTitle = (title) => {
//   document.title = title.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + ' - Plan Shop Cook'
// }

export const setPageView = (title = '', image = 'https://storage.googleapis.com/delightey-55cb2.appspot.com/assets/favicon.png') => {
  let page_title = 'Plan Shop Cook'
  if (title) {
    page_title = title.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) + ' - Plan Shop Cook'
  }

  document.querySelector("#og-title").setAttribute("content", page_title)
  document.getElementById("og-image").setAttribute("content", image)
  // document.querySelector("#meta-description").setAttribute(description)
  // document.querySelector("#og-description").setAttribute(description)
  document.title = page_title
  analytics.logEvent("page_view")
}
