// icons
// import { FaEnvelope, FaHome, FaPhone } from "react-icons/fa";

// components
// import Hero from "../components/Hero";

const AboutUs = () => {
  return (
    <>
      {/* <Hero
        title="About Us"
        breadName="About"
        // image={contactBg}
        singleProduct={false}
      /> */}
      <section className="py-5">
        <h1 className="display-1 text-center">About Us</h1>
        <div className="container">
          <p>Our platform provides a unique experience in planning, shopping, and cooking - a one-stop shop!  </p>

          <p>We want you to enjoy planning and cooking with your family - enjoying family time with enjoyable meals.</p>

          <p>We not only share the recipes and experiences of chefs, we provide a community-based platform, where you can submit your recipes to the world. </p>

          <p>Simply join our community to take your cooking experience to the next level.</p>

        </div>
      </section>
    </>
  )
}

export default AboutUs
