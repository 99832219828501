import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

//Import Firebase
import firebase from "../../firebase"
import UserContext from "../../context/user"
import ReviewBlock from './ReviewBlock'
import ReviewForm from './ReviewForm'

const reviewsRef = firebase.firestore().collection("reviews")

const Rattings = () => {
  // use params
  const { id } = useParams()
  const { user } = useContext(UserContext)

  const [review, setReview] = useState({})
  const [reviews, setReviews] = useState([])
  const [reviewAdded, setReviewAdded] = useState(false)

  useEffect(() => {
    reviewsRef.where("recipeid", "==", id)
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        let reviewsData = []
        snapshot.forEach(doc => {
          var reviewData = doc.data()
          reviewsData.push({ id: doc.id, ...reviewData })
          if (user && reviewData.uid === user.uid) {
            setReview({ id: doc.id, ...reviewData })
          }
        })
        setReviews(reviewsData)
      })
  }, [id, reviewAdded, user])

  const onSubmitted = (status) => {
    setReviewAdded(status)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <ReviewForm onSubmitted={onSubmitted} review={review} />
        </div>
        <div className="col-md-6">
          <h3>Reviews</h3>
          <div className="reviews">
            {reviews.length > 0 ? reviews.map((review, index) => (
              <ReviewBlock review={review} key={index} />
            )) : (
              <h6>No Reviews</h6>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Rattings
