// libraries
import { useState, useContext } from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import CKEditor from "ckeditor4-react"

import { TimePicker } from "antd"
import moment from 'moment'
import "antd/dist/antd.css"
import { Controller, useForm } from "react-hook-form"

// icons
import { IoIosArrowDown } from "react-icons/io"
import { IoArrowBackCircle } from 'react-icons/io5'

// context
import RecipesContext from "../../context/recipes"

// components
import IngredientField from "../../components/SubmitRecipe/IngredientField"
import Loading from "../../components/Loading"
import { categories, mealCategories } from '../../data/data'
import { getSingleRecipe } from '../../utils/helpers'
import { CustomInvalidFeedbackV7, InvalidFeedbackV7, preventSubmit } from '../../utils/validation'

import firebase from "../../firebase"
import { sanitizeTime } from "../../utils/date"

// firestore
const recipesCollection = firebase.firestore().collection("recipes")

const AdminRecipeDetailsEdit = () => {
  const { id } = useParams()

  // use context
  const { allRecipes, loading, setLoading } = useContext(RecipesContext)
  var singleRecipe = getSingleRecipe(allRecipes, id)

  // use history
  const history = useHistory()

  // use toast
  const { addToast } = useToasts()

  // uuid
  const uuid = id // singleRecipe;
  // const { uuid } = singleRecipe;
  // console.log("uuid", uuid);
  // use state
  let initialIngrediant = { name: "", qty: 1, qtySuffix: '' }
  let ingredientsNumber = 1
  if (singleRecipe.ingredients && singleRecipe.ingredients.length > 0) {
    initialIngrediant = singleRecipe.ingredients.map(ingrediant => {
      if (ingrediant.value) {
        return { name: ingrediant.value, qty: 1, qytSuffix: '' }
      }
      return { ...ingrediant }
    })
    ingredientsNumber = initialIngrediant.length
  }
  const [ingredientList, setIngredientList] = useState(initialIngrediant)

  const { register, handleSubmit, formState: { errors }, formState, control, setValue } = useForm({
    // resolver: yupResolver(schema)
    defaultValues: {
      recipe: singleRecipe.recipe,
      category: singleRecipe.category,
      meal: singleRecipe.meal,
      preptime: sanitizeTime(singleRecipe.preptime) || "00:00",
      cooktime: sanitizeTime(singleRecipe.cooktime) || "00:00",
      instructions: singleRecipe.instructions,
      ingredientsNumber: ingredientsNumber,
      peoples: singleRecipe.peoples || 1,
      nutrition: singleRecipe.nutrition,
      facebook: singleRecipe.facebook,
      instagram: singleRecipe.instagram,
    }
  })

  // console.log(singleRecipe)

  const onSubmit = data => {
    if (validateIngredient()) {
      addToast("Please provide ingrediant name.", {
        appearance: "error",
      })
      return
    } else {
      setLoading(true)
      postRecipeToDB(data)
      // uploadImageToFBStorage(data, postRecipeToDB)
    }
  }

  const validateIngredient = () => {
    ingredientList.forEach((item) => {
      if (item === "") return true
    })

    return false
  }

  const postRecipeToDB = async (data) => {
    let payload = {
      recipe: data.recipe,
      category: data.category,
      meal: data.meal,
      nutrition: data.nutrition,
      ingredientsNumber: data.ingredientsNumber,
      ingredients: ingredientList,
      instructions: data.instructions,
      // status: "pending",
      // userToken: user.uid,
      // user: data.username,
      // displayName: user?.displayName,
      // recommended: false,
      // youtube: youtube,
      instagram: data.instagram,
      facebook: data.facebook,
      // website: website,
      cooktime: data.cooktime,
      preptime: data.preptime,
      // image: imageUrl,
      peoples: data.peoples,
      // uuid,
      // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }

    // console.log(payload)
    // setLoading(false)

    await recipesCollection
      .doc(uuid)
      .update(payload)
      .then(() => {
        addToast("Recipe updated.", {
          appearance: "success",
        })
        setTimeout(() => {
          setLoading(false)
          if (singleRecipe.status === "approved") {
            history.push("/admin")
          } else {
            history.push(`/admin-${singleRecipe.status}`)
          }
        }, 1000)
      })
      .catch((e) => {
        setLoading(false)
        addToast("Couldnt't update recipe! Please try again.", {
          appearance: "error",
        })
      })
  }

  const handleChange = (e, index, key) => {
    const { value } = e.target
    const newList = [...ingredientList]
    newList[index][key] = value
    setIngredientList(newList)
  }

  const AddMoreIngredient = () => {
    setIngredientList([...ingredientList,
      initialIngrediant // { name: "", qty: 1, qtySuffix: 'kg' }
    ])
    setValue("ingredientsNumber", ingredientList.length + 1)
  }

  const handleDelete = (index) => {
    let newList = [...ingredientList]
    newList.splice(index, 1)
    setIngredientList(newList)
    setValue("ingredientsNumber", ingredientList.length - 1)
  }

  // const uploadImageToFBStorage = (callback) => {
  //   const uploadTask = firebase
  //     .storage()
  //     .ref(`/images/${imageFile.name}`)
  //     .put(imageFile);

  //   uploadTask.on("state_changed", () => console.log(),
  //     console.error,
  //     () => {
  //       firebase
  //         .storage()
  //         .ref("images")
  //         .child(imageFile.name)
  //         .getDownloadURL()
  //         .then((url) => {
  //           callback(url);
  //         });
  //     }
  //   );
  // };

  // const onChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);
  //   setImageFile(newFileList[0]?.originFileObj ?? null);
  // };

  // const onPreview = async (file) => {
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   const imgWindow = window.open(src);
  //   imgWindow.document.write(image.outerHTML);
  // };

  if (!singleRecipe) {
    addToast("Please do not refresh!", {
      appearance: "error",
    })
    return <Redirect to="/admin" />
  }

  const onError = err => console.log(err)

  return (
    <section
      className="ftco-section">
      {loading && <Loading fixed={true} />}
      <div className="container">
        <div className="row mb-3">
          <div className='col-lg-12'>
            <div className="d-flex align-items-center">
              <IoArrowBackCircle
                size={40}
                onClick={() => history.goBack()}
                style={{
                  color: "#d63230",
                  cursor: "pointer"
                }}
              />
              <h3
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                }}
                className='ml-3 pt-3'
              >
                Edit Recipe
              </h3>
            </div>
          </div>
          {/* {image && (
            <div className='col-lg-12' style={{
              height: "15em",
              backgroundImage: `linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url("${image}")`
            }} />
          )} */}
        </div>
        <div className="row justify-content-center">
          {/* {image && (
            <div className="col-md-12">
              <br />
              <div className="submit-recipe-img-upload pb-3">
                <label className="col-12 p-0">Recipe Image</label>
                <label htmlFor="submit-recipe-img-upload">
                  <img
                    src={image}
                    alt="submit-recipe"
                  />
                </label>
              </div>
            </div>
          )} */}
          <div className="col-xl-12">
            <form className='billing-form' onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="row align-items-end">
                {/* <div className="col-md-12">
                    <div className="form-group">
                      <label className="required" htmlFor="username">User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        {...register("username", { required: "Username is required." })}
                      />
                      <InvalidFeedbackV7 name="username" errors={errors} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="required" htmlFor="displayName">Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="displayName"
                        {...register("displayName", { required: "Full Name is required." })}
                      />
                      <InvalidFeedbackV7 name="displayName" errors={errors} />
                    </div>
                  </div> */}
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="required" htmlFor="recipe">Recipe Name</label>
                    <input
                      type="text"
                      name="recipe"
                      id="recipe"
                      className="form-control"
                      {...register("recipe", { required: "Recipe Name is required." })}
                    />
                    <InvalidFeedbackV7 name="recipe" errors={errors} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="required" htmlFor="category">Category</label>
                    <div className="select-wrap">
                      <div className="icon">
                        <span className="ion-ios-arrow-down">
                          <IoIosArrowDown />
                        </span>
                      </div>
                      <select
                        className="form-control"
                        name="category"
                        id="category"
                        {...register("category", { required: "Category is required." })}
                      >
                        <option value="">Select</option>
                        {categories.map((option, i) => (
                          <option value={option.value} key={i}>{option.label}</option>
                        ))}
                      </select>
                      <InvalidFeedbackV7 name="category" errors={errors} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="required" htmlFor="meal">Meal</label>
                    <div className="select-wrap">
                      <div className="icon">
                        <span className="ion-ios-arrow-down">
                          <IoIosArrowDown />
                        </span>
                      </div>
                      <select
                        className="form-control"
                        name="meal"
                        id="meal"
                        {...register("meal", { required: "Meal is required." })}
                      >
                        <option value="">Select</option>
                        {mealCategories.map((option, i) => (
                          <option value={option.value} key={i}>{option.label}</option>
                        ))}
                      </select>
                      <InvalidFeedbackV7 name="meal" errors={errors} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="required" htmlFor="preptime">Peparation Time</label>
                    <Controller
                      control={control}
                      name="preptime"
                      rules={{ required: "Peparation Time is required." }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        // <TimePicker
                        //   defaultValue={value}
                        //   onChange={onChange}
                        //   onBlur={onBlur}
                        // />
                        <TimePicker
                          id="preptime"
                          className="form-control"
                          defaultValue={moment(value, "HH:mm")}
                          onChange={(value, time) => onChange(time)}
                          onBlur={onBlur}
                          format={'HH:mm'}
                          // size={"large"}
                          placeholder="HH:MM"
                          showNow={false}
                        />

                      )}
                    />
                    <CustomInvalidFeedbackV7 name="preptime" errors={errors} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="required" htmlFor="cooktime">Cook Time</label>
                    <Controller
                      control={control}
                      name="cooktime"
                      rules={{ required: "Cook Time is required." }}
                      // defaultValue="00:00"
                      render={({ field: { onChange, onBlur, value } }) => (
                        // <TimePicker
                        //   defaultValue={value}
                        //   onChange={onChange}
                        //   onBlur={onBlur}
                        // />
                        <TimePicker
                          id="cooktime"
                          className="form-control"
                          defaultValue={moment(value, "HH:mm")}
                          onChange={(value, time) => onChange(time)}
                          onBlur={onBlur}
                          format={'HH:mm'}
                          size={"large"}
                          placeholder="HH:MM"
                          showNow={false}
                          renderExtraFooter={() => { }}
                        />
                      )}
                    />
                    <CustomInvalidFeedbackV7 name="cooktime" errors={errors} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="required" htmlFor="ingredientsNumber">
                      No. of Ingredients
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ingredientsNumber"
                      onChange={(e) => setValue(ingredientsNumber, e.target.value)}
                      {...register("ingredientsNumber", { required: "No. of Ingrrediants is required." })}
                    />
                    <InvalidFeedbackV7 name="ingredientsNumber" errors={errors} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="required" htmlFor="peoples">
                      How many people does it feed?
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      // name="people"
                      id="peoples"
                      {...register("peoples", { required: "This field is required." })}
                    />
                    <InvalidFeedbackV7 name="peoples" errors={errors} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="nutrition">Nurtition Facts</label>
                    <textarea
                      rows="3"
                      name="nutrition"
                      id="nutrition"
                      className="form-control"
                      {...register("nutrition")}
                    />
                    <InvalidFeedbackV7 name="nutrition" errors={errors} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group ingredients">
                    {ingredientList.map((item, i) => (
                      <div key={i}>
                        <IngredientField
                          index={i}
                          handleChange={handleChange}
                          handleDelete={handleDelete}
                          ingredientList={ingredientList}
                        />
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={AddMoreIngredient}
                      className="submit-recipe-add-more"
                    >
                      Add more
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="required" htmlFor="instructions">Cooking Instructions</label>
                    <Controller
                      control={control}
                      name="instructions"
                      id="instructions"
                      rules={{ required: "Cooking Instructions is required." }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CKEditor
                          data={value}
                          onChange={(evt) =>
                            onChange(evt.editor.getData())
                          }
                          onBlur={onBlur}
                        />
                      )}
                    />
                    <CustomInvalidFeedbackV7 name="instructions" errors={errors} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Facebook Url (Optional)"
                      className="form-control"
                      {...register("facebook")}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Instagram Url (Optional)"
                      className="form-control"
                      {...register("instagram")}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        {...register("youtube")}
                      />
                    </div>
                  </div> */}
                {/* <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        {...register("website")}
                      />
                    </div>
                  </div> */}
                <div className="col-md-12 row mt-4 justify-content-center">
                  <br />
                  <button
                    type="submit"
                    className="btn btn-primary py-3 px-4 submit-recipe-btn"
                    disabled={preventSubmit(formState)}
                  >
                    Update Recipe
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AdminRecipeDetailsEdit
