// libraries
import { useContext, useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import { useToasts } from "react-toast-notifications"

// context
import UserContext from "../context/user"

// components
import Hero from "../components/Hero"
import UserLoading from "../components/Loading"
import RecipesItem from "../components/Home/RecipesItem"
import Newsletter from "../components/Newsletter"

// import { banners } from "../firebase/storage";
import RecipesContext from '../context/recipes'
import { getFavoriteRecipe } from '../utils/helpers'
// import { assetsRef } from '../firebase/storage';

const Favorites = () => {
  const [banner, setBanner] = useState("")

  // use context
  const { user, favorites } = useContext(UserContext)
  const { recipes, loading } = useContext(RecipesContext)
  const favoriteRecipe = getFavoriteRecipe(recipes, favorites)

  useEffect(() => {
    if (favoriteRecipe.length > 0) {
      setBanner(favoriteRecipe[0].image)
    } else {
      setBanner("")
      // setBanner(`${assetsRef}/empty-dish.jpg`)
    }
  }, [favoriteRecipe])

  // use toast
  const { addToast } = useToasts()

  if (!user) {
    addToast("Please Log in First!", {
      appearance: "error",
    })
    return <Redirect to='/' />
  }

  return (
    <div>
      <Hero
        image={banner}
        title='My Favorites'
        breadName='Favorites'
        singleProduct={false}
      />
      {loading ? (
        <div className='loading-wrapper'>
          <UserLoading />
        </div>
      ) : (
        <div className='ftco-section'>
          <div className='container'>
            <div className='row'>
              {favoriteRecipe.length > 0 ? (
                favoriteRecipe.map((item, index) => {
                  return (
                    <RecipesItem
                      key={index}
                      title={item.recipe}
                      id={item.uuid}
                      meal={item.meal}
                      category={item.category}
                      image={item.image}
                      user={item.user}
                      isFav={true}
                      item={item}
                    />
                  )
                })
              ) : (
                <div className='recipes-empty'>
                  <h5>No favorite recipes to show!</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Newsletter />
    </div>
  )
}

export default Favorites
