// libraries
import {
  useContext,
  // useState, useEffect
} from "react"
import { useParams } from "react-router-dom"
import firebase from "../firebase"
import { useToasts } from "react-toast-notifications"

// context
import RecipesContext from "../context/recipes"
import UserContext from "../context/user"

// assets
// import bg from "../assets/image_3.jpg";

// helpers
import { getSingleRecipe } from "../utils/helpers"

// components
import Loading from "../components/Loading"
import Hero from "../components/Hero"
import RecipeDetailsComponent from "../components/RecipeDetails/RecipeDetailsComponent"
import Newsletter from "../components/Newsletter"
// import RelatedProducts from "../components/Product/RelatedProducts";

// firestore
const usersDocument = firebase.firestore().collection("users")
const flaggedDocument = firebase.firestore().collection("flagged")

const RecipeDetails = () => {
  // use params
  const { id } = useParams()

  // use context
  const { user, favorites, flagged } = useContext(UserContext)
  const { recipes, loading, setLoading } = useContext(RecipesContext)

  // const [fav, setFav] = useState(false);
  const isFav = favorites.includes(id)
  const isFlagged = flagged.includes(id)

  // use toast
  const { addToast } = useToasts()

  var singleRecipe = getSingleRecipe(recipes, id)

  // useEffect(() => {
  //   setFav(favorites.includes(id))
  // }, [favorites]);

  const addToFavorites = async () => {
    if (user) {
      setLoading(true)
      await usersDocument
        .doc(user.uid)
        .collection("favorites")
        .doc(id)
        .set({ favorite: true })
        .then(() => {
          addToast("Recipe added to Favorites!", {
            appearance: "success",
          })
          // setFav(true);
          // favorites.push(id);
          // setFavorite(favorites);
        })
        .catch((e) => {
          addToast("Couldnt't add recipe to favorites! Please try again.", {
            appearance: "error",
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      addToast("Please Log in First!", {
        appearance: "error",
      })
    }
  }

  const deleteFavorite = async () => {
    if (user) {
      setLoading(true)
      await usersDocument
        .doc(user.uid)
        .collection("favorites")
        .doc(id)
        .delete()
        .then(() => {
          addToast("Recipe removed from Favorites!", {
            appearance: "info",
          })
          // setFav(false);
          // setFavorite(favorites.filter(fav => fav !== id))
        })
        .catch((e) => {
          addToast(
            "Couldnt't remove recipe from favorites! Please try again.",
            {
              appearance: "error",
            },
          )
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      addToast("Please Log in First!", {
        appearance: "error",
      })
    }
  }

  const flagRecipe = async () => {
    if (user) {
      setLoading(true)
      flaggedDocument
        .add({
          uid: user.uid,
          recipeid: id,
          createdAt: new Date(),
          updatedAt: new Date()
        })
        .then(() => {
          addToast("Recipe reported/flagged!", {
            appearance: "success",
          })
        })
        .catch((e) => {
          addToast("Couldnt't report recipe! Please try again.", {
            appearance: "error",
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      addToast("Please Log in First!", {
        appearance: "error",
      })
    }
  }

  const unflagRecipe = () => {
    if (user) {
      setLoading(true)
      flaggedDocument.where("uid", "==", user.uid).where("recipeid", "==", id)
        .get()
        .then((snapshot) => {
          snapshot.forEach(doc => {
            doc.ref.delete()
          })
          addToast("Recipe unreported/unflagged!", {
            appearance: "info",
          })
        })
        .catch((e) => {
          addToast(
            "Couldnt't remove recipe from flagged! Please try again.", {
            appearance: "error",
          },
          )
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      addToast("Please Log in First!", {
        appearance: "error",
      })
    }
  }

  if (loading && recipes.length === 0) {
    return (
      <div className='loading-wrapper'>
        <Loading />
      </div>
    )
  } else {
    return (
      <>
        <Hero
          image={singleRecipe?.image}
          title={singleRecipe?.recipe || 'Recipes'}
          breadName={singleRecipe && singleRecipe.recipe}
          singleProduct={true}
        />
        {loading && <Loading fixed={true} />}
        <RecipeDetailsComponent
          product={singleRecipe && singleRecipe}
          isFav={isFav}
          addToFavorites={addToFavorites}
          deleteFavorite={deleteFavorite}
          isFlagged={isFlagged}
          flagRecipe={flagRecipe}
          unflagRecipe={unflagRecipe}
        />
        {/*   <RelatedProducts /> */}
        <Newsletter />
      </>
    )
  }
}

export default RecipeDetails
