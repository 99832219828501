import { useState } from 'react'
import { useForm } from "react-hook-form"
import {
  FaEnvelope,
  // FaHome, FaPhone
} from "react-icons/fa"
import { useToasts } from "react-toast-notifications"

// assets
// import contactBg from "../assets/contact.jpg";

// components
import Hero from "../components/Hero"
import Loading from "../components/Loading"

import { mailgun, domain, toMail } from "../utils/mailgun"
import { InvalidFeedbackV7, preventSubmit, validEmailRegx } from "../utils/validation"

const Contact = () => {
  // use toast
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, formState: { errors }, formState } = useForm({
    // resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    var payload = {
      from: `Contact From PSC <noreply@${domain}>`,
      to: [toMail],
      subject: "Contact Form - PSC",
      text: `Contact Inquiry from PSC
      Name:${data.name}
      Email:${data.email}
      Subject:${data.subject}
      Message:${data.message}
      `,
      html: `<h1>Contact Inquiry from PSC</h1>
      <p>Name:${data.name}</p>
      <p>Email:${data.email}</p>
      <p>Subject:${data.subject}</p>
      <p>Message:${data.message}</p>
      `
    }

    console.log(payload)
    // setLoading(true)

    mailgun.messages().send(payload)
      .then(msg => {
        console.log(msg)
        addToast("Thank you, we will reply soon.", {
          appearance: "success",
        })
      }) // logs response data
      .catch(err => {
        console.log(err)
        let msg = err.message || "Something went wrong, Please try again later."
        addToast(msg, {
          appearance: "error",
        })
      })
      .finally(() => {
        setLoading(false)
      }) // logs any error
  }

  const onError = err => console.log(err)

  return (
    <>
      <Hero
        title='Contact Us'
        breadName='Contact'
        // image={contactBg}
        singleProduct={false}
      />
      <section className='ftco-section contact-section bg-light'>
        {loading && <Loading fixed={true} />}
        <div className='container'>
          <div className='row d-flex mb-5 contact-info'>
            <div className='w-100'></div>
            {/* <a
              href='https:maps.google.com'
              target='_blank'
              rel='noopener noreferrer'
              className='col-md-4 d-flex justify-content-center align-items-center'
            >
              <div
                style={{ borderRadius: "10px" }}
                className='info bg-primary p-4 d-flex justify-content-center align-items-center flex-column'
              >
                <span className='contact-icon'>
                  <FaHome color='white' size={50} className='mb-2' />
                </span>
                <p className='text-white'>
                  21th Street, Suite 721 New York NY 10016
                </p>
              </div>
            </a> */}
            {/* <a
              href='tel://1234567920'
              className='col-md-4 d-flex justify-content-center align-items-center'
            >
              <div
                style={{ borderRadius: "10px" }}
                className='info bg-primary p-4 d-flex justify-content-center align-items-center flex-column'
              >
                <span className='contact-icon'>
                  <FaPhone color='white' size={50} className='mb-2' />
                </span>
                <p className='text-white'>+ 1235 2355 98</p>
              </div>
            </a> */}
          </div>
          <div className='row block-9'>
            <div className='col-md-6'>
              <form className='bg-white p-5 contact-form' onSubmit={handleSubmit(onSubmit, onError)}>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Your Name'
                    {...register("name", { required: "Name is required." })}
                  />
                  <InvalidFeedbackV7 name="name" errors={errors} />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Your Email'
                    {...register("email", {
                      required: "Email is required.",
                      validate: {
                        email: email => email.match(validEmailRegx) || "Please enter valid Email"
                      }
                    })}
                  />
                  <InvalidFeedbackV7 name="email" errors={errors} />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Subject'
                    {...register("subject", { required: "Subject is required." })}
                  />
                  <InvalidFeedbackV7 name="subject" errors={errors} />
                </div>
                <div className='form-group'>
                  <textarea
                    name=''
                    id=''
                    cols='30'
                    rows='7'
                    className='form-control'
                    placeholder='Message'
                    {...register("message", { required: "Message is required." })}
                  ></textarea>
                  <InvalidFeedbackV7 name="message" errors={errors} />
                </div>
                <div className='form-group'>
                  <button
                    type='submit'
                    className='btn btn-primary py-3 px-5'
                    disabled={preventSubmit(formState)}
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
            <div className='col-md-6 d-flex'>
              <a
                href='mailto:contact@planshopcook.com'
                className='col-md-12'
              >
                <div
                  style={{ borderRadius: "10px" }}
                  className='info bg-primary p-4 d-flex justify-content-center align-items-center flex-column'
                >
                  <span className='contact-icon'>
                    <FaEnvelope color='white' size={50} className='mb-2' />
                  </span>
                  <p className='text-white'>contact@planshopcook.com</p>
                </div>
              </a>
              {/* <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2913.9516259681927!2d-79.07842955677953!3d43.08451233788236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d343380bafe607%3A0xfa9fdc488a571008!2sNiagara%20Falls%20Fireworks!5e0!3m2!1sen!2s!4v1600887844610!5m2!1sen!2s'
                width='600'
                height='450'
                frameBorder='0'
                style={{ border: 0 }}
                allowFullScreen=''
                aria-hidden='false'
                tabIndex='0'
                title='niagrafalls'
                className='bg-white'
              ></iframe> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
