// libraries
import { useState, createContext, useEffect } from "react"
import firebase from "../firebase"

// helpers
import {
  approvedRecipes,
  // filterNewRecipes,
  recommendedRecipes
} from "../utils/helpers"

// create context
const RecipesContext = createContext()

export const useRecipe = () => RecipesContext

// firestore
const recipesDocument = firebase.firestore().collection("recipes")
const newRecipesDocument = firebase.firestore().collection("recipes").where("status", "==", "approved").orderBy("createdAt", "desc").limit(12)

export const RecipesProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [recipes, setRecipes] = useState([])
  const [allRecipes, setAllRecipes] = useState([])
  const [newRecipes, setNewRecipes] = useState([]);
  const [recommended, setRecommended] = useState([])

  // Initializing FireStore User Document
  useEffect(() => {
    setLoading(true)
    recipesDocument.onSnapshot((snapshot) => {
      let items = snapshot.docs.map((doc) => doc.data())
      setAllRecipes(items)
      const approved = approvedRecipes(items)
      const recommended = recommendedRecipes(approved)
      setRecipes(approved)
      setRecommended(recommended)
      // setNewRecipes(filterNewRecipes(items, 16))
      setLoading(false)
    })
    newRecipesDocument.onSnapshot((snapshot) => {
      let items = snapshot.docs.map((doc) => doc.data())
      setNewRecipes(items)
    })
  }, [])

  return (
    <RecipesContext.Provider
      value={{
        recipes,
        recommended,
        loading,
        setLoading,
        allRecipes,
        newRecipes
      }}
    >
      {children}
    </RecipesContext.Provider>
  )
}

export default RecipesContext
