import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
//Import Firebase
import firebase from "../../firebase"
import UserContext from "../../context/user"
import LoaderContext from "../../context/loader"
import RateElement from './RateElement'

// const recipesReviews = firebase.firestore().collection("recipes");
const reviewsRef = firebase.firestore().collection("reviews")

const ReviewForm = ({ onSubmitted, review }) => {
  const { id } = useParams()
  const { user } = useContext(UserContext)
  const { setLoading } = useContext(LoaderContext)
  const [title, setTitle] = useState(review?.title || "")
  const [content, setContent] = useState(review?.content || "")
  const [rating, setReting] = useState(review?.rating || 0)

  // use toast
  const { addToast } = useToasts()

  useEffect(() => {
    if (review) {
      setTitle(review.title)
      setReting(review.rating)
      setContent(review.content)
    }
  }, [review])

  const submitReview = (e) => {
    e.preventDefault()
    const isEmpty =
      !title ||
      !content ||
      !rating

    if (user) {
      if (isEmpty) {
        addToast("Please fill all the fields", {
          appearance: "error",
        })
        return
      }
      setLoading(true)
      reviewsRef.add({
        recipeid: id,
        uid: user.uid,
        title,
        content,
        rating,
        author: user.displayName,
        status: "pending",
        createdAt: new Date(),
        updatedAt: new Date()
      })
        .then(() => {
          addToast("Review Added!", {
            appearance: "success",
          })
          if (onSubmitted) {
            onSubmitted(new Date())
          }
        })
        .catch((e) => {
          addToast("Couldnt't add review.", {
            appearance: "error",
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      addToast("Please Log in First!", {
        appearance: "error",
      })
    }
  }

  const updateReview = (e) => {
    e.preventDefault()
    const isEmpty =
      !title ||
      !content ||
      !rating

    if (user) {
      if (isEmpty) {
        addToast("Please fill all the fields", {
          appearance: "error",
        })
        return
      }
      if (!review) {
        return
      }
      setLoading(true)
      reviewsRef
        .doc(review.id)
        .update({
          // recipeid: id,
          // uid: user.uid,
          title,
          content,
          rating,
          author: user.displayName,
          // createdAt: new Date(),
          updatedAt: new Date()
        })
        .then(() => {
          addToast("Review Updated!", {
            appearance: "success",
          })
          if (onSubmitted) {
            onSubmitted(new Date())
          }
        })
        .catch((e) => {
          addToast("Couldnt't update review.", {
            appearance: "error",
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      addToast("Please Log in First!", {
        appearance: "error",
      })
    }
  }

  return (
    <>
      {review?.rating ? (
        <div className="row">
          <div className="col-md-12">
            <h3>Your Rating</h3>
            <div className="form-group">
              <label htmlFor="rating">Rating</label>
              <RateElement
                id="rating"
                defaultValue={rating}
                onChange={(star) => setReting(star)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                id="title"
                name="title"
                defaultValue={title}
                onChange={(e) => setTitle(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="content">Review/Comment</label>
              <textarea
                id="content"
                name="content"
                rows="3"
                defaultValue={content}
                onChange={(e) => setContent(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12 text-right">
            <button
              className="btn btn-primary py-3 px-4 submit-recipe-btn"
              onClick={(e) => updateReview(e)}
            >
              Update Review
            </button>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <h3>Add Your Rating</h3>
            <div className="form-group">
              <label htmlFor="rating">Rating</label>
              <RateElement
                id="rating"
                defaultValue={rating}
                onChange={(star) => setReting(star)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                id="title"
                name="title"
                defaultValue={title}
                onChange={(e) => setTitle(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="content">Review/Comment</label>
              <textarea
                id="content"
                name="content"
                rows="3"
                defaultValue={content}
                onChange={(e) => setContent(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12 text-right">
            <button
              className="btn btn-primary py-3 px-4 submit-recipe-btn"
              onClick={(e) => submitReview(e)}
            >
              Submit Review
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default ReviewForm
