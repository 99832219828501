// libraries
import { useState, useEffect, useContext, useReducer } from "react"
import { useParams, Redirect } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Modal } from 'antd'
import cx from "classnames"
// import { useReactToPrint } from "react-to-print";
import firebase from "../firebase"

// context
import RecipesContext from "../context/recipes"

// context
import UserContext from "../context/user"

// style
import "../styles/MealPlan.scss"

// components
import Hero from "../components/Hero"
// import MealPlanItem from "../components/MealPlan/MealPlanItem";
import MealPlanItem from "../components/MealPlan/MealPlanItem-DnD"
import MealPlanDrawer from "../components/MealPlan/MealPlanDrawer"

// assests
import Loading from "../assets/hour_glass.gif"
import { days } from '../data/data'
import { getFromLocalStorage } from '../utils/helpers'
import { defaulMealPlan, reducer } from "../data/mealPlanTemplate"
import { weekLabel } from "../utils/labels"

const MealPlan = () => {
  // use context
  const { recipes } = useContext(RecipesContext)
  const { user } = useContext(UserContext)
  const { addToast } = useToasts()
  const [mealPlan, dispatch] = useReducer(reducer, defaulMealPlan)

  // state
  const [showDrawer, setShowDrawer] = useState(false)
  const [drawerRecipes, setDrawerRecipes] = useState({})
  const [weekTab, setWeekTab] = useState("weekOne")
  const [loading, setLoading] = useState(true)
  const [alertMsg, setAlertMsg] = useState("Generating Meal Plan For You")

  // reference
  const moveModal = Modal
  // const componentRef = useRef();

  // route params
  const { week } = useParams()

  useEffect(() => {
    if (recipes?.length > 0) {
      generateMealPlan("weekOne")
    }
    //  eslint-disable-next-line
  }, [recipes])

  // useEffect(() => {
  //   if (user !== null) {
  //     let weekTab = getFromLocalStorage("weekTab", "1")
  //     setWeekTab(weekTab)

  //     if (userData?.weekOne?.breakfast?.length > 0) {

  //       FirstWeek.breakfast = userData.weekOne.breakfast;
  //       FirstWeek.lunch = userData.weekOne.lunch;
  //       FirstWeek.dinner = userData.weekOne.dinner;

  //       SecondWeek.breakfast = userData.weekTwo.breakfast ?? [];
  //       SecondWeek.lunch = userData.weekTwo.lunch ?? [];
  //       SecondWeek.dinner = userData.weekTwo.dinner ?? [];

  //       // setBreakFastRecipes(FirstWeek.breakfast);
  //       // setLunchRecipes(FirstWeek.lunch);
  //       // setDinnerRecipes(FirstWeek.dinner);
  //       // setWeekTab(week)

  //       if (weekTab === "weekOne") {
  //         setBreakFastRecipes(FirstWeek.breakfast);
  //         setLunchRecipes(FirstWeek.lunch);
  //         setDinnerRecipes(FirstWeek.dinner);
  //       } else {
  //         setBreakFastRecipes(SecondWeek.breakfast);
  //         setLunchRecipes(SecondWeek.lunch);
  //         setDinnerRecipes(SecondWeek.dinner);
  //       }
  //     } else {
  //       setLoading(true)
  //       generateMealPlan("weekOne");
  //     }
  //   }
  //   // console.log("userData", userData)
  //   // eslint-disable-next-line
  // }, [userData]);

  // filter recipes
  const filterRecipes = (meal) => {
    const selectedDietPlan = getFromLocalStorage("dietPlan", 'all')
    // console.log("dietPlan", dietPlan)
    let filtered = recipes.filter((recipe) => recipe.meal === meal)
    if (selectedDietPlan !== 'all') {
      filtered = recipes.filter((recipe) => recipe.meal === meal && recipe.category === selectedDietPlan)
    }
    return filtered.map((recipe, i) => ({
      recipe: recipe.recipe,
      image: recipe.image || "",
      cooktime: recipe.cooktime || "00:00",
      preptime: recipe.preptime || "00:00",
      category: recipe.category || "",
      meal: recipe.meal || "",
      uuid: recipe.uuid || `${weekTab}-${i}-${meal}-0000-0000-0000`
    }))
  }

  const shuffleArray = (arr) => {
    for (let i = arr.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      let temp = arr[i]
      arr[i] = arr[j]
      arr[j] = temp
    }

    if (arr.length > 7) {
      arr = arr.slice(0, 7)
    }

    return arr
  }

  const fillWithEmpty = (array, meal) => {
    if (array?.length < 7) {
      for (let i = array?.length; i < 7; i++) {
        array[i] = {
          recipe: "Empty",
          image: 'empty',
          cooktime: "00:00",
          preptime: "00:00",
          category: "",
          meal: meal,
          uuid: `${weekTab}-${i}-${meal}-0000-0000-0000`,
          empty: true
        }
      }
    }
    return array
  }

  const generateMealPlan = (selectedWeekTab) => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)

    let payload = {
      breakfast: fillWithEmpty(shuffleArray(filterRecipes("breakfast")), "breakfast"),
      lunch: fillWithEmpty(shuffleArray(filterRecipes("lunch")), "lunch"),
      dinner: fillWithEmpty(shuffleArray(filterRecipes("dinner")), "dinner")
    }

    dispatch({
      type: selectedWeekTab,
      payload
    })
  }

  const setDrawerRecipesList = (meal, uuid, index, catg) => {
    let drawerContent = {}
    drawerContent.recipes = recipes
    drawerContent.type = meal
    drawerContent.uuid = uuid
    drawerContent.index = index
    drawerContent.category = catg
    setDrawerRecipes(drawerContent)
  }

  const getDrawerRecipe = (data) => {
    let type = drawerRecipes.type
    let payload = {
      ...mealPlan[weekTab],
      [type]: mealPlan[weekTab][type].map((item, i) =>
        drawerRecipes.uuid === item.uuid ? data : item
      ),
    }
    dispatch({
      type: weekTab,
      payload
    })
    setShowDrawer(false)
  }

  const deleteConfirm = (item, index) => {
    Modal.confirm({
      title: "Are you sure?",
      content: "You want to remove the item from meal plan?",
      centered: true,
      // onCancel: handleCancle,
      cancelText: "No",
      onOk: () => confirmDelete(item, index),
      okText: "Yes",
      cancelButtonProps: {
        danger: true
      },
      okButtonProps: {
        danger: true
      }
    })
  }

  const confirmDelete = (item, index) => {
    const newMeal = mealPlan[weekTab][item.meal].map((i, ind) => ind === index ? {
      recipe: "Empty",
      image: 'empty',
      cooktime: "00:00",
      preptime: "00:00",
      category: item.category,
      meal: item.meal,
      uuid: `${weekTab}-${index}-${item.meal}-${item.category}-0000-0000-0000`,
      empty: true
    } : i)
    // console.log("newMeal", newMeal)
    let payload = {
      ...mealPlan[weekTab],
      [item.meal]: newMeal
    }
    // console.log("payload", payload)
    dispatch({
      type: weekTab,
      payload
    })
    // console.log("handleOk", item)
  }

  const moveConfirm = (item, index) => {
    const totalMeals = mealPlan[weekTab][item.meal]?.length
    moveModal.confirm({
      title: "Select Day to move item",
      icon: null,
      // content: "You want to remove the item from meal plan?",
      content: <>
        <select
          className="custom-select"
          // onChange={(e) => setNewDay(parseInt(e.target.value)) }
          onChange={(e) => confirmMove(item, index, parseInt(e.target.value))}
        >
          <option value="">Select Day</option>
          {days.map((day, i) => (i < totalMeals &&
            <option value={day.value} key={day.value} disabled={day.value === index}>{day.label}</option>
          ))}
        </select>
      </>,
      centered: true,
      okButtonProps: {
        style: {
          display: "none",
        },
      },
      cancelButtonProps: {
        style: {
          display: "none",
        },
      }
    })
  }

  const confirmMove = (item, day, newDay) => {
    moveModal.destroyAll()
    // console.log(newDay)
    const swapedItem = mealPlan[weekTab][item.meal].filter((i, ind) => newDay === ind)[0]
    const updatedMeal = mealPlan[weekTab][item.meal].map((i, ind) => newDay === ind ? item : i)
    const newMeal = updatedMeal.map((i, ind) => day === ind ? swapedItem : i)
    // console.log("movedMeal", newMeal)
    let payload = {
      ...mealPlan[weekTab],
      [item.meal]: newMeal
    }
    // console.log("payload", payload)
    dispatch({
      type: weekTab,
      payload
    })
    // console.log("handleOk", item)
  }

  const dndMove = (destinationItem, day, newDay) => {
    console.log(destinationItem, day, newDay)
    const sourceItem = mealPlan[weekTab][destinationItem.meal].filter((item, i) => day === i)[0]
    const updatedMeal = mealPlan[weekTab][destinationItem.meal].map((item, i) => day === i ? destinationItem : item)
    const newMeal = updatedMeal.map((item, i) => newDay === i ? sourceItem : item)

    console.log("movedMeal", newMeal)
    let payload = {
      ...mealPlan[weekTab],
      [destinationItem.meal]: newMeal
    }
    // console.log("payload", payload)
    dispatch({
      type: weekTab,
      payload
    })
  }

  const uploadMealPlanToFS = (weekName) => {
    if (user !== null) {
      // let payload = mealPlan
      // let payload = { [weekName]: mealPlan[weekName] }
      // if (week === "1") {
      //   payload = {
      //     [weekName]: mealPlan[weekName],
      //     "weekTwo": [],
      //     "weekThree": [],
      //     "weekFour": []
      //   }
      // }
      // if (weekName === "oneMonth") {
      //   payload = {
      //     weekOne: mealPlan[weekOne],
      //     weekTwo: mealPlan[weekTwo],
      //     weekThree: mealPlan[weekThree],
      //     weekFour: mealPlan[weekFour]
      //   }
      // }

      setLoading(true)
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("mealPlan")
        .doc(weekName)
        .set(mealPlan[weekName], { merge: true })
        // .set(payload, { merge: true })
        .then(() => {
          console.log("meal plan saved")
          addToast("Meal plan saved", {
            appearance: "success",
          })
        })
        .catch((e) => {
          addToast(e.message, {
            appearance: "error",
          })
          console.log("meal plan error", e.message)
        }).finally(() => {
          setAlertMsg("Generating Meal Plan For You")
          setLoading(false)
        })
    } else {
      // console.log("Please Log in First");
      setAlertMsg("Generating Meal Plan For You")
      addToast("Please Log in First", {
        appearance: "warning",
      })
    }
  }

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  if (!user) {
    addToast("Please log in before generate meal plan!", {
      appearance: "error",
    })
    return <Redirect to="/" />
  }

  const heroTitle = (week) => {
    switch (parseInt(week)) {
      case 4:
        return "One Month Meal Plan"
      case 2:
        return "2 Week Meal Plan"
      default:
        return "1 Week Meal Plan"
    }
  }


  // console.log(mealPlan)

  return (
    <>
      <Hero
        // image={bg}
        title={heroTitle(week)}
        breadName="Meal Plan"
        singleProduct={false}
      />
      {loading ? (
        <div className="meal-plan-loading">
          <img src={Loading} alt="loading" />
          <h4>{alertMsg}......</h4>
        </div>
      ) : (
        <>
          {week === "2" && (
            <div className="meal-plan-tabs">
              <div
                className={cx("tab", { active: weekTab === "weekOne" })}
                onClick={() => {
                  setWeekTab("weekOne")
                }}
              >
                First Week
              </div>
              <hr />
              <div
                className={cx("tab", { active: weekTab === "weekTwo" })}

                onClick={() => {
                  setWeekTab("weekTwo")
                  if (mealPlan["weekTwo"]?.breakfast?.length === 0) {
                    setLoading(true)
                    generateMealPlan("weekTwo")
                    // setOneTimeClick(true);
                  }
                }}
              >
                Second Week
              </div>
            </div>
          )}
          {week === "4" && (
            <div className="meal-plan-tabs">
              <div
                className={cx("tab", { active: weekTab === "weekOne" })}
                onClick={() => {
                  setWeekTab("weekOne")
                }}
              >
                First Week
              </div>
              <hr />
              <div
                className={cx("tab", { active: weekTab === "weekTwo" })}
                onClick={() => {
                  setWeekTab("weekTwo")
                  if (mealPlan["weekTwo"]?.breakfast?.length === 0) {
                    setLoading(true)
                    generateMealPlan("weekTwo")
                    // setOneTimeClick(true);
                  }
                }}
              >
                Second Week
              </div>
              <hr />
              <div
                className={cx("tab", { active: weekTab === "weekThree" })}
                onClick={() => {
                  setWeekTab("weekThree")
                  if (mealPlan["weekThree"]?.breakfast?.length === 0) {
                    setLoading(true)
                    generateMealPlan("weekThree")
                    // setOneTimeClick(true);
                  }
                }}
              >
                Third Week
              </div>
              <hr />
              <div
                className={cx("tab", { active: weekTab === "weekFour" })}
                onClick={() => {
                  setWeekTab("weekFour")
                  if (mealPlan["weekFour"]?.breakfast?.length === 0) {
                    setLoading(true)
                    generateMealPlan("weekFour")
                    // setOneTimeClick(true);
                  }
                }}
              >
                Fourth Week
              </div>
            </div>
          )}
          <section className="meal-wrapper my-5">
            <div className="mx-3">
              <div className="mb-2">
                {week === "1" ? (
                  <button
                    className="btn btn-primary mr-1"
                    onClick={() => {
                      setAlertMsg("Saving Meal Plan")
                      uploadMealPlanToFS("weekOne")
                    }}
                  >
                    Save Meal Plan
                  </button>
                ) : (
                  <button
                    className="btn btn-primary mr-1"
                    onClick={() => {
                      setAlertMsg("Saving Meal Plan")
                      uploadMealPlanToFS(weekTab)
                    }}
                  >
                    {`Save ${weekLabel(weekTab)} Meal Plan`}
                  </button>
                )}
                {/* {week === "2" && (weekTab === "weekOne" ? (
                  <button
                    className="btn btn-primary mr-1"
                    onClick={() => {
                      setAlertMsg("Saving Meal Plan")
                      uploadMealPlanToFS("weekOne")
                    }}
                  >
                    Save First Week Meal Plan
                  </button>
                ) : (
                  <button
                    className="btn btn-primary mr-1"
                    onClick={() => {
                      setAlertMsg("Saving Meal Plan")
                      uploadMealPlanToFS("weekTwo")
                    }}
                  >
                    Save Both Week Meal Plan
                  </button>
                ))} */}
                {/* {week === "4" && (
                    <button
                      className="btn btn-primary mr-1"
                      onClick={() => {
                        setAlertMsg("Saving Meal Plan")
                        uploadMealPlanToFS(weekTab)
                      }}
                    >
                      Save This Week Meal Plan
                    </button>
                  )} */}
              </div>
              <div>
                <div className="meal-plan">
                  <div className="meal-plan-side">
                    <div className="meal-plan-item"></div>
                    <div className="meal-plan-item">BreakFast</div>
                    <div className="meal-plan-item">Lunch</div>
                    <div className="meal-plan-item">Dinner</div>
                  </div>
                  <div className="meal-plan-main">
                    <div className="meal-plan-main-row  meal-plan-days">
                      <div className="meal-row-heading">Sunday</div>
                      <div className="meal-row-heading">Monday</div>
                      <div className="meal-row-heading">Tuesday</div>
                      <div className="meal-row-heading">Wednesday</div>
                      <div className="meal-row-heading">Thursday</div>
                      <div className="meal-row-heading">Friday</div>
                      <div className="meal-row-heading">Saturday</div>
                    </div>
                    <div className="meal-plan-main-row">
                      {mealPlan[weekTab]?.breakfast?.map((recipe, i) => (
                        <div key={i} className="meal-row-item my-1">
                          <MealPlanItem
                            data={recipe}
                            setShowDrawer={setShowDrawer}
                            index={i}
                            setDrawer={setDrawerRecipesList}
                            moveConfirm={moveConfirm}
                            dndMove={dndMove}
                            deleteConfirm={deleteConfirm}
                            mealPlan="breakfast"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="meal-plan-main-row">
                      {mealPlan[weekTab]?.lunch?.map((recipe, i) => (
                        <div key={i} className="meal-row-item my-1">
                          <MealPlanItem
                            data={recipe}
                            setShowDrawer={setShowDrawer}
                            index={i}
                            setDrawer={setDrawerRecipesList}
                            moveConfirm={moveConfirm}
                            dndMove={dndMove}
                            deleteConfirm={deleteConfirm}
                            mealPlan="lunch"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="meal-plan-main-row">
                      {mealPlan[weekTab]?.dinner?.map((recipe, i) => (
                        <div key={i} className="meal-row-item my-1">
                          <MealPlanItem
                            data={recipe}
                            setShowDrawer={setShowDrawer}
                            index={i}
                            setDrawer={setDrawerRecipesList}
                            moveConfirm={moveConfirm}
                            dndMove={dndMove}
                            deleteConfirm={deleteConfirm}
                            mealPlan="dinner"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showDrawer && (
              <MealPlanDrawer
                drawerRecipes={drawerRecipes}
                setShowDrawer={setShowDrawer}
                getRecipe={getDrawerRecipe}
              />
            )}
          </section>
        </>
      )}
    </>
  )
}

export default MealPlan
